a,
a:hover {
    -webkit-transition: .3s;
    -moz-transition: .3s;
    -ms-transition: .3s;
    -o-transition: .3s;
}

h2,
h3,
h4,
h5 {
    line-height: 1.2
}

body,
h4,
h5,
p {
    /* font-weight: 400; */
    color: #472c35
}

p {
    font-size: 1em;
    line-height: 1.75
}

a {
    transition: .3s
}

.flat-slider .btn-silde .btn-action:hover span,
.section-faqs .main-faqs .main-content .intro-content .flat-question .accordion .accordion-toggle .toggle-title:hover,
.section-services .services-main .services-list-main .list-services-box .box-item:first-child:hover .icon-box span.icon,
a:hover {
    color: #77cad2;
    transition: .3s;
}

h1,
h2,
h3 {
    font-weight: 700;
}

h1 {
    line-height: 1;
}

h3 {
    font-size: 3em;
    text-transform: uppercase;
}

h4 {
    font-weight: 600;
}

h6 {
    line-height: 1.5;
    font-weight: 500;
}

.bussines-headings {
    font-style: normal;
    font-weight: 700;
    line-height: 110.3%;
    text-transform: capitalize
}

.how-it-works {
    font-size: 4.5em
}

.accordion-body h2,
.accordion-button h2 {
    color: #472c35;
    font-family: Teko;
    font-size: 25px;
    font-style: normal;
    font-weight: 600;
    ;
    line-height: normal;
    letter-spacing: -.3px
}

.header .main-header .main-nav-header .mobile-button.active span,
.page-loaded .preloader .icon {
    opacity: 0
}

#business_our_testimonial_section p,
#business_what_we_offer_section p,
#howItWorks p,
.flat-slider.style-2 .text,
.terms_policy p {
    font-family: 'Open Sans', sans-serif
}

.cst-testimnial-card,
.left-img-arrow,
.right-img-arrow,
.sPlay,
ul#menu-primary-menu li a.menu-main {
    cursor: pointer
}

@-webkit-keyframes rotateMe {
    from {
        transform: rotate(0) scale(1)
    }

    to {
        transform: rotate(360deg)
    }
}

@-moz-keyframes rotateMe {
    from {
        transform: rotate(0) scale(1)
    }

    to {
        transform: rotate(360deg)
    }
}

@-o-keyframes rotateMe {
    from {
        transform: rotate(0) scale(1)
    }

    to {
        transform: rotate(360deg)
    }
}

@keyframes rotateMe {
    from {
        transform: rotate(0) scale(1)
    }

    to {
        transform: rotate(360deg)
    }
}

.header {
    position: relative;
    z-index: 9999;
    width: 100%
}

.header .main-header {
    display: flex;
    padding: 12px 0 20px;
    position: relative
}

.header .main-header .main-header-logo {
    width: 22%;
    padding-top: 10px
}

.header .main-header .main-header-logo a img {
    width: 180PX
}

.header .main-header .main-nav-header {
    width: 56%;
    padding-left: 50px
}

.header .main-header .main-nav-header .header-top .slogan {
    display: inline-block;
    background: #77cad2;
    padding: 6px 19px;
}

.header .main-header .main-nav-header .header-top .slogan .text-slogan {
    color: #472c35;
    font-weight: 600;
}

.header .main-header .main-nav-header .header-top .list-social {
    float: right;
    padding-right: 60px;
    padding-top: 10px;
}

.header .main-header .main-nav-header .header-top .list-social li {
    display: inline-block;
    padding-left: 25px
}

.header .main-header .main-nav-header .header-top .list-social li a {
    color: rgb(255 255 255);
    font-size: 14px
}

.footer .main-footer .bottom-footer .content ul li a:hover,
.footer .main-footer .bottom-footer .info .list-social li a.active,
.footer .main-footer .bottom-footer .info .list-social li a:hover,
.header #main-nav-mobi ul li a:hover,
.header .main-header .main-nav-header .header-top .list-social li a.active,
.header .main-header .main-nav-header .header-top .list-social li a:hover,
.header .main-header .main-nav-header .main-nav .menu li a.menu-main:active,
.header .main-header .main-nav-header .main-nav .menu li.menu-item-has-children a.menu-main.active,
.header .main-header .main-nav-header .main-nav .menu li.menu-item-has-children a.menu-main:hover,
.header .main-header .main-nav-header .main-nav .sub-menu li a.active,
.header .main-header .main-nav-header .main-nav .sub-menu li a:hover,
.section-about .main-about .content-right .intro-content .title-parent .title span.color,
.section-blog .blog-main .blog-list-main .list-blog-box .blog-item .content .meta-blog .time a:hover,
.section-blog .blog-main .blog-list-main .list-blog-box .blog-item .content .meta-blog li a:hover,
.section-blog .blog-main .blog-list-main .list-blog-box .blog-item .content h4.title a:hover,
.section-contact .contact-main .map-contact .info-contact .social.padding-top-70 ul.list-social li a:hover,
.section-faqs .main-faqs .main-content .intro-content .flat-question .accordion .accordion-toggle .toggle-title.active,
.section-portfolio .main-portfolio .list-portfolio .box-item.s2 .box-item-content .content-box .info-box h4 a:hover,
.section-services .services-main .services-control .box-left-control .main-control .title-parent .title .word span:first-child,
.section-services .services-main .services-list-main .list-services-box .box-item .content-box .title a:hover,
.section-services .services-main .services-list-main .list-services-box .box-item:first-child .content-box .title a:hover,
.section-testimonials .themesflat-carousel-box .owl-nav .owl-next.disabled::before,
.section-testimonials .themesflat-carousel-box .owl-nav .owl-prev.disabled::before,
.section-testimonials.style-2 .themesflat-carousel-box .owl-next:hover,
.section-testimonials.style-2 .themesflat-carousel-box .owl-prev:hover {
    color: #77cad2
}

.header .main-header .main-nav-header .mobile-button {
    display: none;
    width: 26px;
    height: 26px;
    float: right;
    background-color: transparent;
    cursor: pointer;
    -webkit-transition: .3s;
    -moz-transition: .3s;
    -ms-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%)
}

.header .main-header .main-nav-header .mobile-button span,
.header .main-header .main-nav-header .mobile-button:after,
.header .main-header .main-nav-header .mobile-button:before {
    background-color: #77cad2;
    -webkit-transition: .3s;
    -moz-transition: .3s;
    transition: .3s
}

.header .main-header .main-nav-header .mobile-button:after,
.header .main-header .main-nav-header .mobile-button:before {
    content: '';
    position: absolute;
    height: 3px;
    width: 100%;
    left: 0;
    top: 50%;
    -webkit-transform-origin: 50% 50%;
    -ms-transform-origin: 50% 50%;
    transform-origin: 50% 50%
}

.header .main-header .main-nav-header .mobile-button span {
    position: absolute;
    width: 100%;
    height: 3px;
    left: 0;
    top: 50%;
    overflow: hidden;
    text-indent: 200%
}

.header .main-header .main-nav-header .mobile-button:before {
    -webkit-transform: translate3d(0, -7px, 0);
    -moz-transform: translate3d(0, -7px, 0);
    transform: translate3d(0, -7px, 0)
}

.header .main-header .main-nav-header .mobile-button:after {
    -webkit-transform: translate3d(0, 7px, 0);
    -moz-transform: translate3d(0, 7px, 0);
    transform: translate3d(0, 7px, 0)
}

.header .main-header .main-nav-header .mobile-button.active:before {
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    -moz-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg)
}

.header .main-header .main-nav-header .mobile-button.active:after {
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    -moz-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg)
}

.header .main-header .main-nav-header .main-nav,
.section-blog .blog-main .blog-list-main .list-blog-box .blog-item .btn-blog {
    padding-top: 25px
}

.header .main-header .main-nav-header .main-nav .menu li.menu-item-has-children {
    position: relative;
    display: inline-block;
    padding-right: 35px
}

.header .main-header .main-nav-header .main-nav .menu li.menu-item-has-children a.menu-main {
    position: relative;
    color: #fff;
    font-size: 1.125em;
    font-weight: 600;
    text-transform: uppercase
}

.header .main-header .main-nav-header .main-nav .menu li.menu-item-has-children a.menu-main.active:after {
    opacity: 1;
    visibility: visible;
    width: 100%
}

.header .main-header .main-nav-header .main-nav .menu li.menu-item-has-children:last-child,
.section-blog.style-2 .blog-main .blog-list-main .list-blog-box .blog-item .content {
    padding-right: 0
}

.header .main-header .main-nav-header .main-nav .sub-menu {
    position: absolute;
    top: 40px;
    left: 0;
    width: 250px;
    padding: 15px 0;
    z-index: 9999;
    background-color: #fff;
    opacity: 0;
    visibility: hidden;
    display: block !important;
    -webkit-transition: .3s;
    -moz-transition: .3s;
    -ms-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
    -webkit-transform: translateY(15px);
    -moz-transform: translateY(15px);
    -ms-transform: translateY(15px);
    -o-transform: translateY(15px);
    transform: translateY(15px)
}

.header .main-header .btn-header {
    width: 22%;
    text-align: end
}

.header .main-header .btn-header .btn-action {
    position: relative;
    padding: 22px 52px 16px 40px;
    background: #77cad2;
    display: inline-block;
    overflow: hidden;
    width: 220px;
    text-align: center
}

.header .main-header .btn-header .btn-action span.more {
    position: relative;
    z-index: 99;
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
    color: #000
}

.header .main-header .btn-header .btn-action span {
    position: relative;
    z-index: 99;
    color: #000
}

.header .main-header .btn-header .btn-action span i {
    font-size: 12px
}

.header .main-header .btn-header .btn-action:before {
    content: "";
    width: 38px;
    height: 38px;
    border-radius: 50%;
    position: absolute;
    left: -32px;
    bottom: -7px;
    background: #fff;
    -webkit-transition: .5s;
    -moz-transition: .5s;
    -ms-transition: .5s;
    -o-transition: .5s;
    transition: .5s
}

.header .main-header .btn-header .btn-action:after {
    content: "";
    width: 3px;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    background: #fff
}

.header .main-header .btn-header .btn-action:hover:before {
    width: 100%;
    height: 100%;
    border-radius: 0;
    left: 0;
    bottom: 0;
    -webkit-transition: .5s;
    -moz-transition: .5s;
    -ms-transition: .5s;
    -o-transition: .5s;
    transition: .5s
}

.header #main-nav-mobi {
    display: block;
    margin: 0 auto;
    width: 100%;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 9999;
    background-color: #f6f6f6
}

.header #main-nav-mobi ul {
    display: block;
    list-style: none;
    margin: 0;
    padding: 0
}

.header #main-nav-mobi ul li {
    margin: 0;
    text-align: left;
    cursor: pointer;
    padding-left: 16px;
    border-top: 1px solid #ebebeb;
    position: relative
}

.header #main-nav-mobi ul li a {
    color: #999;
    display: inline-block;
    font-size: 14px;
    text-transform: uppercase;
    line-height: 48px;
    font-weight: 600
}

.header #main-nav-mobi ul li a.active {
    color: #77cad2 !important
}

.header #main-nav-mobi ul li:first-child {
    border-top: 0
}

.header #main-nav-mobi .menu-item-has-children .arrow {
    cursor: pointer;
    display: inline-block;
    font-size: 20px;
    font-family: FontAwesome;
    line-height: 48px;
    position: absolute;
    right: 3px;
    text-align: center;
    top: 0;
    width: 48px
}

.header #main-nav-mobi .menu-item-has-children .arrow:before {
    content: "\f103";
    color: #b1b1b1
}

.header #main-nav-mobi .menu-item-has-children .arrow.active:before {
    content: "\f102"
}

.header #main-nav-mobi ul ul li {
    background-color: #f2f2f2;
    border-color: #ebebeb
}

.header.style-2 .container {
    max-width: 1550px;
    width: 100%
}

.header.style-2 .container .main-header {
    padding: 22px 0 28px 8px
}

.header.style-2 .container .main-header .main-header-logo {
    width: 17%
}

.header.style-2 .container .main-header .main-nav-header {
    width: 65%;
    padding-left: 16px
}

.header.style-2 .container .main-header .main-nav-header .header-top .list-social {
    float: right;
    padding-right: 0;
    padding-top: 0
}

.header.style-2 .container .main-header .main-nav-header .main-nav,
.section-portfolio .main-portfolio.s2 {
    padding-top: 30px
}

.header.style-2 .container .main-header .main-nav-header .main-nav ul#menu-primary-menu {
    float: left
}

.header.style-2 .container .main-header .btn-header {
    width: 18%
}

.flat-slider .btn-silde .btn-action {
    padding: 22px 49px 16px 45px;
    background: #77cad2;
    overflow: hidden;
    width: 220px
}

.flat-slider .btn-silde .btn-action span {
    color: #472c35;
    transition: .3s
}

.flat-slider .btn-silde .btn-action span::before {
    background: #472c35;
    transition: .3s
}

.flat-slider .btn-silde .btn-action span::after {
    background: #fff;
    transition: .3s
}

.flat-slider .btn-silde .btn-action:hover span::after,
.flat-slider .btn-silde .btn-action:hover span::before {
    background: #77cad2;
    transition: .3s
}

.flat-slider .btn-silde .btn-action:after,
.flat-slider .btn-silde .btn-action:before,
.section-portfolio .btn-view-all .btn-action:after,
.section-services .services-main .services-control .btn-view-all .btn-action:before {
    background: #fff
}

.flat-slider .img-slider {
    margin-top: 107px !important
}

.flat-slider .rev_slider_wrapper.fullwidthbanner-container,
.flat-slider .tp-fullwidth-forcer {
    height: 860px !important
}

.flat-slider div#rev-slider1 {
    height: 100% !important
}

.flat-slider .text-right {
    text-align: right !important
}

.footer .main-footer .list-footer,
.section-about .main-about,
.section-blog .blog-main,
.section-blog-details .main-blog-details,
.section-blog-details .main-blog-details .blog-content .soundcloud .right .top,
.section-choose-us .main-choose-us .main-content,
.section-faqs .main-faqs .main-content,
.section-portfolio-details .main-project-details,
.section-services .services-main {
    display: flex
}

.footer .main-footer .list-footer .widget-contact-form .fomr-contact .form-group,
.footer .main-footer .list-footer .widget-contact-form .heading,
.section-about .main-about .content-left .progress-about .couter .chart,
.section-about .main-about .content-right .intro-content .title-parent,
.section-blog .blog-main .blog-control .box-left-control .main-control .title-parent,
.section-blog .blog-main .blog-list-main .list-blog-box .blog-item .img-box,
.section-contact .contact-main .map-contact,
.section-portfolio .box-item .img-box,
.section-portfolio .main-portfolio .list-portfolio .box-item.s1,
.section-portfolio.style-2 .main-portfolio .list-portfolio .box-item .img-box,
.section-services .services-main .services-control .box-left-control .main-control .title-parent,
.section-team .main-team .main-content .team-box .img-team,
.section-testimonials.ab {
    position: relative
}

.section-faqs {
    padding-top: 80px
}

.section-choose-us {
    padding-bottom: 80px
}

.section-about .main-about .content-right .intro-content .team .info p,
.section-blog-details .main-blog-details .blog-content .form-review .list-star li a i,
.section-blog-details .main-blog-details .blog-content .form-review p,
.section-contact .contact-main .map-contact .info-contact .social.padding-top-70 ul.list-social li a,
.section-portfolio-details .main-project-details .project-content .form-review .list-star li a i,
.section-portfolio-details .main-project-details .project-content .form-review p,
.section-testimonials .themesflat-carousel-box .themesflat-testimonial .testimonials-item .author .name p {
    font-size: 14px
}

.section-portfolio .intro-heading p {
    padding-top: 6px
}

.section-portfolio .intro-heading hr.divider {
    background-color: #472c35;
    width: 48px;
    height: 5px;
    display: inline-block;
    margin-top: -4px;
    margin-left: -16px;
    opacity: 1
}

.section-portfolio .main-portfolio {
    padding-top: 45px;
    display: flex
}

.section-portfolio .main-portfolio .list-portfolio {
    width: 370px;
    margin-right: 30px
}

.section-portfolio .main-portfolio .list-portfolio .box-item.s1 .img-box,
.section-portfolio .main-portfolio .list-portfolio .box-item.s1:hover .img-box,
.section-portfolio .main-portfolio .list-portfolio .box-item.s2:hover .img-box {
    overflow: hidden
}

.section-portfolio .main-portfolio .list-portfolio .box-item.s1:hover .img-box img {
    transform: scale(1.2);
    -webkit-transition: .5s;
    -moz-transition: .5s;
    -ms-transition: .5s;
    -o-transition: .5s;
    transition: .5s
}

.section-portfolio .main-portfolio .list-portfolio .box-item.s1:hover .box-item-content .content-box {
    visibility: visible;
    opacity: 1;
    -webkit-transition: .5s;
    -moz-transition: .5s;
    -ms-transition: .5s;
    -o-transition: .5s;
    transition: .5s
}

.section-portfolio .main-portfolio .list-portfolio .box-item.s1:hover .box-item-content .content-box::before {
    left: -33px;
    transition: .5s .4s
}

.section-choose-us .main-choose-us .main-content .intro-content,
.section-faqs .main-faqs .main-content .intro-content {
    width: 50%
}

.section-faqs .main-faqs h1 {
    text-transform: uppercase
}

.section-faqs .main-faqs .main-content .intro-content h3 {
    text-transform: none;
    font-weight: 500;
    padding-top: 17px;
    margin-left: -2px
}

.section-faqs .main-faqs .main-content .intro-content h2 {
    font-size: 3em;
    text-transform: none;
    font-weight: 600;
    padding-top: 17px;
    margin-left: -2px
}

.section-faqs .main-faqs .main-content .intro-content h3 span {
    font-weight: 500
}

.section-faqs .main-faqs .main-content .intro-content .flat-question {
    padding-top: 49px
}

.section-faqs .main-faqs .main-content .intro-content .flat-question .accordion .accordion-toggle {
    padding-bottom: 36px;
    cursor: pointer
}

.section-faqs .main-faqs .main-content .intro-content .flat-question .accordion .accordion-toggle .toggle-title {
    font-size: 18px;
    font-weight: 700;
    transition: .3s;
}

.section-faqs .main-faqs .main-content .intro-content .flat-question .accordion .accordion-toggle .toggle-title span {
    font-size: 18px;
    font-weight: 700;
    padding-right: 21px;
}

.section-faqs .main-faqs .main-content .intro-content .flat-question .accordion .accordion-toggle .toggle-content {
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    padding: 13px 100px 1px 48px;
    line-height: 1.8;
}

.section-faqs .main-faqs .main-content .img-faq {
    margin-left: 16px;
    padding-top: 24px;
    padding-bottom: 17px;
}

.section-faqs.style-2 {
    padding-top: 40px;
    padding-bottom: 110px;
}

.section-faqs.ab {
    padding-top: 124px;
    padding-bottom: 103px;
}

section.tf-section.section-team.style-2.s1 {
    padding-bottom: 45px;
}

.main-counter .heading-title h2.title {
    color: #fff;
    padding-top: 23px;
    line-height: 1;
}

.section-testimonials .themesflat-carousel-box .themesflat-testimonial .testimonials-item .author .info li {
    list-style-type: none;
    background: #77cad2;
    width: 30px;
    height: 50px;
    padding-top: 16px;
    display: inline-block;
    text-align: center;
}

.tp-bullets {
    display: block !important;
}

.footer {
    background: #3a3839;
}

.footer .main-footer {
    padding-top: 120px;
    padding-left: 0;
    padding-bottom: 15px
}

.footer .main-footer .list-footer .widget-about {
    width: 38%
}

.footer .main-footer .list-footer .widget-about .logo a img {
    width: 180px
}

.footer .main-footer .list-footer .widget-about .text {
    color: #fff;
    padding-top: 26px;
    padding-left: 2px;
    letter-spacing: .6px;
}

.footer .main-footer .list-footer .widget-about .location {
    padding-top: 63px;
    margin-left: 2px;
}

.footer .main-footer .list-footer .widget-about .location .icon-location {
    float: left;
    padding-right: 16px;
}

.footer .main-footer .list-footer .widget-about .location p {
    font-size: 14px;
    margin-top: 0;
}

.footer .main-footer .list-footer .widget-link {
    width: 16%;
    margin-top: -4px
}

.footer .main-footer .list-footer .widget-link .widget-title {
    color: #fff;
    text-transform: uppercase;
}

.footer .main-footer .list-footer .widget-link .list-wrap {
    padding-top: 20px;
}

.footer .main-footer .list-footer .widget-link .list-wrap li {
    padding-bottom: 7px;
}

.footer .main-footer .list-footer .widget-link .list-wrap li a {
    cursor: pointer;
    color: rgba(255, 255, 255, .6);
    font-size: 16px;
    font-family: 'Open Sans', sans-serif;
    -webkit-transition: .5s;
    -moz-transition: .5s;
    -ms-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
    position: relative;
}

.footer .main-footer .list-footer .widget-link .list-wrap li a:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 1px;
    height: 2px;
    background: #77cad2;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: .5s;
    -moz-transition: .5s;
    -ms-transition: .5s;
    -o-transition: .5s;
    transition: .5s
}

.footer .main-footer .list-footer .widget-link .list-wrap li a:hover {
    color: #77cad2;
    -webkit-transition: .5s;
    -moz-transition: .5s;
    -ms-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
}

.footer .main-footer .list-footer .widget-link .list-wrap li a:hover:before {
    opacity: 1;
    visibility: visible;
    width: 100%;
    -webkit-transition: .5s;
    -moz-transition: .5s;
    -ms-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
}

.footer .main-footer .list-footer .widget-link.v2 {
    padding-left: 15px;
}

.footer .main-footer .list-footer .widget-link.v1 {
    padding-left: 60px
}

.footer .main-footer .list-footer .widget-link.v2 .list-wrap li {
    padding-bottom: 15px
}

.footer .main-footer .list-footer .widget-contact-form {
    width: 50%;
    padding-left: 160px;
}

.footer .main-footer .list-footer .widget-contact-form .heading img {
    position: absolute;
    z-index: 2;
    top: -25px;
    left: 0;
}

.footer .main-footer .list-footer .widget-contact-form .heading h3 {
    color: #fff;
    z-index: 3;
    text-transform: none;
    font-weight: 600;
    margin-top: -7px;
}

.footer .main-footer .list-footer .widget-contact-form .heading p {
    color: #fff;
    font-size: 14px;
    padding-top: 6px
}

.footer .main-footer .list-footer .widget-contact-form .fomr-contact {
    padding-top: 27px;
}

.footer .main-footer .list-footer .widget-contact-form .fomr-contact .form-group input {
    width: 100%;
    background: 0 0;
    border: 2px solid rgba(255, 255, 255, .1);
    padding: 22px 0 22px 18px;
    font-size: 14px;
    font-family: 'Open Sans', sans-serif;
    color: rgba(255, 255, 255, .6)
}

.footer .main-footer .list-footer .widget-contact-form .fomr-contact .form-group button.btn,
.get_started_btn {
    z-index: 999;
    background: #77cad2;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    position: absolute;
    padding: 21px 19px 15px 20px;
    border-radius: 3px;
}

.footer .main-footer .list-footer .widget-contact-form .fomr-contact .form-group input:focus {
    border-color: #77cad2
}

.footer .main-footer .list-footer .widget-contact-form .fomr-contact .form-group button.btn {
    color: #fff;
    top: 3px;
    right: 3px;
}

.footer .main-footer .list-footer .widget-contact-form .fomr-contact .form-group button.btn:hover {
    background: #edc92a;
    color: #472c35;
}

.footer .main-footer .list-footer .widget-contact-form h6.copyright {
    color: #fff;
    padding-top: 0;
    letter-spacing: .6px;
    margin-top: 140px
}

.footer .main-footer .list-footer .widget-contact-form h6.copyright span {
    color: #77cad2;
    font-weight: 600;
}

.footer .main-footer .bottom-footer {
    display: flex;
    padding-left: 2px;
    padding-bottom: 23px
}

.footer .main-footer .bottom-footer .content {
    width: 70%;
}

.footer .main-footer .bottom-footer .content h5 {
    display: inline-block;
    color: rgba(255, 255, 255, .6);
    text-transform: uppercase;
    letter-spacing: .4px;
    padding-left: 56px;
}

.footer .main-footer .bottom-footer .content h5 span {
    color: #77cad2;
    margin-left: -3px
}

.footer .main-footer .bottom-footer .content ul li {
    display: inline-block;
    padding-right: 29px
}

.footer .main-footer .bottom-footer .content ul li a {
    color: rgba(255, 255, 255, .6);
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: .4px;
    font-weight: 600;
}

.footer .main-footer .bottom-footer .info {
    width: 30%;
    text-align: right;
}

.footer .main-footer .bottom-footer .info .list-social li {
    display: inline-block;
    padding-left: 29px;
}

.footer .main-footer .bottom-footer .info .list-social li a {
    color: #fff;
    font-size: 14px;
}

.footer .main-footer hr {
    background: rgba(255, 255, 255, .6);
    margin-top: 60px;
    margin-bottom: 40px;
}

.contact_wrap_cls input,
.contact_wrap_cls textarea {
    margin-bottom: 15px;
    font-weight: 600;
}

.contact-wrap.contact_wrap_cls {
    padding: 30px;
    background: #77cad2;
    border-radius: 20px;
    margin: 40px 0;
}

.contact_wrap_cls button.btn.btn-primary {
    background: #3a3839;
    border-color: #3a3839;
    display: block;
    margin: 10px auto 0;
    font-weight: 600;
}

.get_started_btn {
    color: #fff;
    top: -28px;
}

@media only screen and (min-width:1366px) {

    .myvideo,
    .thecenter {
        width: 100%;
        height: 100%;
    }

    .section-services .services-main {
        display: flex;
        flex-wrap: wrap
    }

    .home_contant {
        position: absolute;
        top: 235px;
        left: 0;
        right: 0;
        bottom: 0;
    }
}

@media only screen and (max-width:1366px) {

    .myvideo,
    .thecenter {
        width: 100%;
        height: 100%;
    }

    .section-services .services-main {
        display: flex;
        flex-wrap: wrap;
    }

    .home_contant {
        position: absolute;
        top: 150px;
        left: 0;
        right: 0;
        bottom: 0
    }

    .header .main-header .main-header-logo a img {
        width: 80px;
    }

    .thecenter {
        background-color: #000
    }

    .header .main-header .main-header-logo {
        width: 22%
    }

    .header .main-header .main-nav-header {
        width: 56%
    }

    .header .main-header .main-nav-header .main-nav .menu li.menu-item-has-children {
        padding-right: 20px
    }

    .section-services .services-main .services-list-main .owl-nav {
        right: -268px
    }

    .header.style-2 .container .main-header .main-header-logo {
        width: 12%;
    }

    .header.style-2 .container .main-header .main-nav-header {
        width: 70%;
        padding-left: 16px;
    }

    .header.style-2 .container .main-header .main-nav-header .main-nav .menu li.menu-item-has-children {
        padding-right: 25px;
    }

    .header.style-2 .container .main-header .main-nav-header .header-top .list-social {
        padding-right: 15px;
    }

    .header.style-2 .container .main-header .btn-header {
        width: 19%;
    }
}

@media (max-width:1199px) and (min-width:992px) {
    .home_contant {
        position: absolute;
        top: 160px;
        left: 0;
        right: 0;
        margin-bottom: 40px;
    }

    .section-services .services-main .services-list-main .owl-nav {
        display: none
    }

    .myvideo,
    .thecenter {
        width: 100%;
        height: 100%;
    }

    .section-services .services-main {
        display: flex;
        flex-wrap: wrap;
    }

    .thecenter {
        position: relative;
        background-color: #000;
    }

    .header .main-header .main-nav-header .main-nav .menu li.menu-item-has-children {
        padding-right: 15px;
    }
}

@media only screen and (max-width:991px) {

    .modal,
    .section-about .main-about .content-left .progress-about {
        margin-left: 0;
    }

    .home_contant {
        position: absolute;
        top: 160px;
        left: 0;
        right: 0;
        margin-bottom: 40px
    }

    .header .main-header .main-header-logo a img {
        width: 80px
    }

    .thecenter {
        width: 100%;
        height: 100%;
        position: relative
    }

    .header .main-header {
        padding-left: 0 !important
    }

    .header .main-header .main-nav-header .header-top,
    .icofont-thin-down,
    .img-slider,
    .page-title:after {
        display: none;
    }

    .header .main-header .mobile-button {
        display: block !important
    }

    .header.style-2 .container .main-header .main-header-logo {
        width: 18%
    }

    .mobile-button {
        display: none;
        position: absolute;
        z-index: 99999;
        width: 28px !important;
        height: 28px !important;
        float: left;
        top: 53%;
        right: 16px;
        background-color: transparent;
        cursor: pointer;
    }

    .header .main-header .btn-header {
        width: 50% !important;
        text-align: end;
        padding-top: 5px;
        padding-right: 100px
    }

    .flat-slider .rev_slider_wrapper.fullwidthbanner-container,
    .flat-slider .tp-fullwidth-forcer {
        height: 650px !important;
    }

    .flat-slider.style-2 .text {
        font-family: 'Open Sans', sans-serif;
    }

    .tp-bullets.custom.horizontal.nav-pos-hor-center.nav-pos-ver-bottom.nav-dir-horizontal.noSwipe {
        top: 80% !important;
    }

    .section-services .services-main .services-control .box-left-control .main-control .title-parent .title,
    h2 {
        font-size: 3.5em;
    }

    .section-about .main-about .content-left img.img-about,
    .section-about .main-about .content-right,
    .section-blog-details .main-blog-details .slidebar,
    .section-choose-us .main-choose-us .main-content .intro-content,
    .section-faqs .main-faqs .main-content .img-faq img,
    .section-faqs .main-faqs .main-content .intro-content,
    .section-portfolio-details .main-project-details .slidebar {
        width: 100%;
    }

    .section-choose-us .main-choose-us .main-content,
    .section-faqs .main-faqs .main-content,
    .section-team .main-team .main-content,
    .section-team.style-2.s1 .main-team .main-content {
        flex-wrap: wrap;
    }

    .section-faqs.style-2 {
        padding-left: 0;
        padding-top: 20px;
    }

    .section-faqs .main-faqs .main-content .img-faq {
        width: 100%;
        padding-top: 30px;
        margin-left: 0;
    }

    .section-team .main-team .main-content .team-box,
    .section-team.style-2.s1 .main-team .main-content .team-box {
        width: 50%;
    }

    .section-team .main-team .main-content .team-box:nth-child(3),
    .section-team .main-team .main-content .team-box:nth-child(4) {
        margin-top: 20px;
    }

    .section-team.style-2.s1 .main-team .main-content .team-box:nth-child(3),
    .section-team.style-2.s1 .main-team .main-content .team-box:nth-child(4) {
        margin-top: 120px;
    }

    .section-team.style-2 .main-team .main-content .team-box:nth-child(3),
    .section-team.style-2 .main-team .main-content .team-box:nth-child(4) {
        margin-top: 70px;
    }

    .footer .main-footer .list-footer {
        display: flex;
        flex-wrap: wrap;
    }

    .footer .main-footer .list-footer .widget-about {
        width: 40%;
    }

    .footer .main-footer .list-footer .widget-link {
        width: 22%;
    }

    .footer .main-footer .list-footer .widget-contact-form {
        width: 100%;
        padding-top: 50px;
        padding-left: 0;
    }

    .footer .main-footer .bottom-footer .content ul {
        padding-left: 0;
        display: block;
    }
}

@media only screen and (max-width:767px) {
    .home_contant {
        position: absolute;
        top: 160px;
        left: 0;
        right: 0;
        margin-bottom: 40px;
    }

    .header .main-header .main-header-logo a img {
        width: 80px;
    }

    .thecenter {
        width: 100%;
        height: 120%;
        position: relative;
    }

    .header .main-header {
        padding: 10px 0 20px;
        margin-right: 0;
    }

    .header .main-header .main-header-logo,
    .header.style-2 .container .main-header .main-header-logo {
        width: 50%
    }

    .header .main-header .btn-header,
    .icofont-thin-down,
    .section-services .services-main .services-list-main .owl-nav {
        display: none;
    }

    .mobile-button {
        right: 0;
    }

    .tp-bullets.custom.horizontal.nav-pos-hor-center.nav-pos-ver-bottom.nav-dir-horizontal.noSwipe {
        top: 88% !important;
    }

    .footer .main-footer,
    .section-about .main-about .content-right .intro-content,
    .section-team.style-2 {
        padding-top: 100px;
    }

    .blog-sm .blog-item .img-box a.click-img,
    .footer .main-footer .bottom-footer .content,
    .footer .main-footer .bottom-footer .info,
    .footer .main-footer .list-footer .widget-about,
    .form-main-contact .themesflat-contact-form.style-2 .wpcf7-form-control-wrap,
    .section-blog .blog-main .blog-list-main,
    .section-blog .blog-main .blog-list-main .list-blog-box .blog-big,
    .section-blog.style-2 .blog-main .blog-list-main .list-blog-box .blog-item .img-box .click-img,
    .section-contact .contact-main .form-main-contact .form-contact,
    .section-contact .contact-main .form-main-contact .slidebar,
    .section-portfolio-details .main-project-details .project-content .video-detail .box-video,
    .section-team.style-2 .main-team .main-content .team-box,
    .section-team.style-2.s1 .main-team .main-content .team-box {
        width: 100%;
    }

    .footer .main-footer .bottom-footer .content ul,
    .footer .main-footer .list-footer .widget-link.v1,
    .footer .main-footer .list-footer .widget-link.v2,
    .section-choose-us .main-choose-us .main-content .intro-content .list-featured,
    .section-contact {
        padding-left: 0;
    }

    .section-contact .contact-main .map-contact .info-contact {
        width: 100%;
        padding: 76px 6px 0 0;
    }

    .section-contact .contact-main .form-main-contact .form-contact {
        margin-right: 0;
        margin-top: 50px;
    }

    .section-faqs .main-faqs h1 {
        font-size: 70px;
    }

    .section-faqs .main-faqs .main-content .intro-content h2,
    h3 {
        font-size: 2.5em
    }

    .footer .main-footer .list-footer {
        display: flex;
        flex-wrap: wrap;
    }

    .footer .main-footer .list-footer .widget-link {
        width: 50%;
        padding-top: 50px
    }

    .footer .main-footer .list-footer .widget-contact-form {
        width: 100%;
        padding-top: 50px;
        padding-left: 0;
    }

    .footer .main-footer .bottom-footer {
        display: flex;
        text-align: center;
        padding-left: 0;
        flex-wrap: wrap
    }

    .footer .main-footer .bottom-footer .content ul li {
        padding-right: 7px
    }

    .footer .main-footer .bottom-footer .info {
        text-align: center
    }
}

@media only screen and (max-width:479px) {

    .header .main-header .btn-header,
    .icofont-thin-down,
    .modal,
    .tf-nav {
        display: none
    }

    .modal {
        position: fixed;
        margin-left: 0;
        top: 0;
        left: 0;
        z-index: 1050;
        width: 100%;
        height: 100%;
        overflow: hidden;
        outline: 0
    }

    .home_contant {
        position: absolute;
        top: 160px;
        left: 0;
        right: 0;
        margin-bottom: 0
    }

    .thecenter {
        height: 100vh;
        background-color: #000
    }

    .header .main-header .main-header-logo a img {
        width: 80px
    }

    .header .main-header {
        padding: 10px 0 0;
        margin-right: 0;
    }

    .header .main-header .main-header-logo {
        width: 50%;
    }

    .mobile-button {
        right: 0;
    }
}

@media (max-width:736px) {
    .mobile-menu-open {
        display: block;
        padding-top: 16px;
        width: 100%;
        position: absolute;
        top: 100%;
        left: 0;
        z-index: 9999;
        background-color: #f6f6f6;
    }
}

@media (max-width:767px) {
    .header .main-header .main-nav-header .main-nav {
        padding: 0;
    }
}