.cst-image-m {
    /* max-width: 50px; */
    max-height: 50px
}

.hero_wrapper .multiple_inner {
    margin-bottom: 50px !important;
}

.total_payment {
    padding-bottom: 0 !important;
}

.our-testimonials {
    color: #77cad2;
    font-size: 80px;
}

.chart_main_cst {
    background-color: #5f6265;
    box-shadow: 4px 6px 4px #000;
    border-radius: 6px;
    text-align: center;
    padding-bottom: 60px;
    margin-bottom: 115px
}

.cst-bg-color-imp {
    background: #77cad2 !important;
    height: 110px;
    border-radius: 10px;
    border: 1px solid #231f20;
    color: #000 !important
}

.contact-wrap-cst {
    padding: 30px;
    background: #77cad2;
    margin: 0
}

.img-slide-box {
    display: flex;
    overflow: auto;
    scroll-behavior: smooth;
    overflow-x: hidden;
}

.learn-more-btn-for-bus-page {
    padding: 10px 22px;
    border-radius: 5px;
    background: #78cad2;
    font-size: 24px;
    font-weight: 600;
    line-height: normal;
    border: none;
    color: #000 !important
}

.bootam-cst-border {
    border-bottom: 8px solid #64c5ce;
    padding: 60px 12px 0 36px
}

.cst-disc-cls {
    list-style: disc;
    color: #472c35
}

.cst-height-card {
    height: 400px
}

.cst-margin-add-what {
    margin-top: 116px
}

.cst-disc-cls p {
    font-size: 14px
}

.cst-body-height {
    height: auto
}

.cst-border-remove {
    background-color: none !important;
    border: none !important
}

.accordion-button:focus {
    box-shadow: "#77CAD2";
}



textarea {
    resize: none
}

.discover-more-btn {
    box-shadow: #f5f5f5 2px 0 2px 0;
    padding: 16px 24px;
    color: #472c35 !important;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    background: #77cad2;
    border: none;
}

.every-project-para {
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 160.182%;
    letter-spacing: .2px
}

.cst-copyright,
.new-footer-cst-text {
    color: #fff;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal
}

.contactForm .invalid-feedback {
    display: none !important
}

@media (max-width:1536px) {
    .cst-css-for-laptop {
        width: 42%
    }
}

.book-call-now-button-bs {
    background: #78cad2 !important;
    border-radius: 5px;
    color: #472c35 !important;
    font-weight: 600;
}

.home_bnr_new {
    position: relative;
}

.flat-slider {
    margin-top: -144px;
}

.widget-about .logo img {
    max-width: 83% !important;
    margin-top: -76px !important;
    height: auto !important;
    width: 100% !important
}

.widget-link .list-wrap {
    list-style: none;
    padding-left: 0
}

@media (max-width:767px) {
    .cst-width-svg {
        width: 100%
    }

    .our-testimonials {
        font-size: 36px
    }

    .cst-height-card {
        height: auto;
        padding: 60px 12px 60px 36px
    }

    .cst-mobile-pad {
        margin-top: 16px
    }

    .cst-corosal-pad {
        padding: 13px 0 12px !important
    }

    .flat-slider {
        margin-top: -100px
    }

    .cst-handle-menu {
        display: block;
        margin: 0 auto;
        width: 100%;
        position: absolute;
        top: 100%;
        left: 0;
        z-index: 9999;
        background-color: #f6f6f6
    }

    #cst-none {
        display: none
    }

    .blacltick-svg {
        width: 8%;
        height: auto
    }
}

.selectDisable {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none
}

.selectEnable {
    -webkit-user-select: text;
    -khtml-user-select: text;
    -moz-user-select: text;
    -o-user-select: text;
    user-select: text
}

.cst-testimnial-card {
    border-radius: 8px;
    height: 100%
}

.check {
    width: 840px;
    height: 512px;
    left: 729px;
    top: 222px;
    background-color: #d9d9d9;
    border-radius: 10px
}

.sPlay {
    border-radius: 12px
}

.cst-img-icons {
    width: 18%
}

@media (max-width:425px) {
    .custom-button {
        width: 248px
    }

}

.modal.fade.show {
    margin: 0 auto 0 0;
    display: block !important;
    left: 0;
    z-index: 1050;
    height: 100%;
    overflow: scroll;
    outline: 0
}

.extra-bg-class {
    background: rgb(251 249 249)
}

.div-chalu-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 24px;
    background-repeat: no-repeat
}

.chnagecolor-star {
    color: #f6ca2e
}

.div-left {
    position: absolute;
    top: 240px
}

.hero_detail_cst {
    color: #78cad2;
    font-family: Teko;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase
}



.checkmark-cst {
    font-size: 26px
}


.progress-wrap {
    position: fixed;
    bottom: 30px;
    right: 30px;
    border-radius: 50%;
    z-index: 100;
    transition: .4s linear;
}

.progress-wrap.active-progress {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
}

.progress-wrap::after {
    position: absolute;
    text-align: center;
    line-height: 44px;
    font-weight: 900;
    left: 0;
    top: 0;
    z-index: 1;
    transition: .4s linear;
    color: #77cad2;
    border-radius: 50%;
    width: 44px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-color: white;
}

.progress-wrap svg.progress-circle path {
    stroke: #ffffff;
    stroke-width: 4;
    box-sizing: border-box;
    transition: .4s linear;
    stroke: #77cad2;
}