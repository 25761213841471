body,
h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
    font-family: Teko;
}

.price_wrap h2,
.service_wrap h1 {
    font-size: 66px;
    font-weight: 700;
    line-height: 66px
}

.total_balance {
    font-size: 1.75rem
}

.heading-title {
    font-size: 70px
}

.add_time p span {
    font-weight: 400
}

.add_time p {
    font-weight: 400 !important
}

.bg_body,
.defi_img,
.defi_img.defi_manage,
.footer_wrapper,
.footer_wrapper.footer_manage,
.index_hero,
.index_nav {
    background: linear-gradient(270deg, #000 2.15%, #484748 81.31%, #3a3839 99.27%)
}

.cstm-table-class td,
.nav_wrapper .nav_inner .nav_right .dropdown .dropdown-menu li .dropdown-item,
.sales_body .client_says .client_box .box_footer,
.service_wrap .service_inner .service_box,
.service_wrap h1 {
    text-align: center
}

.service_wrap h1 {
    margin-bottom: 50px;
    color: #fff;
    text-shadow: 0 4px 4px rgba(0, 0, 0, .25)
}

.wallet-heading-cst {
    font-size: calc(1.325rem + .9vw)
}

.custom_select,
.input_wrap .form-control {
    font-weight: 600
}

.dewq_btnz,
.index_hero .hero__main .hero_content,
.manage_wallet .active_days .active_inner,
.table_wrap .edit_tr span {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal
}

.modal.faded.show,
body,
html {
    overflow-x: hidden
}

.notification,
a {
    text-decoration: none
}

body {
    width: 100%;
    height: 100%;
    padding: 0 !important
}

.btn,
h1,
h2,
h3,
h4,
h5,
h6,
p {
    padding: 0
}

* {
    -webkit-box-sizing: border-box;
    box-sizing: border-box
}

.modal_monthly_report .modal-dialog .modal-content .modal-body .order_btn.payment_btn .confirm_btn,
.modal_order_365 .modal-dialog .modal-content .modal-body .order_btn.payment_btn .confirm_btn,
.modal_order_detail .modal-dialog .modal-content .modal-body .order_btn.payment_btn .confirm_btn,
img {
    max-width: 100%
}

a:focus-visible {
    outline: 0
}

.btn,
.hero_wrapper .need_review .accordion .accordion-item,
.nav-tabs .nav-link,
.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover,
.nav_wrapper .nav_inner .nav_right .nav-tabs .nav-link:hover,
.popover .popover-header,
.terms_policy .modal-header {
    border: none
}

.cstm-top-zero {
    top: 0 !important
}

.flex_align {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.contact_wrap .contact_inner,
.flex_bt,
.footer_wrapper .footer_inner,
.why_choose .why_choose_main {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.chart_content,
.max_width_100 {
    max-width: 100% !important
}

.container,
.index_hero .container {
    max-width: 1250px;
    width: 100%
}

.contact_box,
.live_wrap {
    max-width: 508px;
    width: 100%
}

.nav_left img {
    max-width: 300px;
    width: 100%
}

.index_nav {
    background-color: unset !important;
    position: fixed;
    width: 100%
}

.hero_wrapper .hero_inner .hero_left .input_wrap,
.index_hero,
.sales_body .sale_detail,
.tool_icon {
    position: relative
}

.index_nav .nav_inner {
    padding: 0 !important;
    height: 126px
}

.index_nav .index_tabs {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    max-width: 640px;
    width: 100%;
    margin: 18px auto 0
}

.index_nav .index_tabs li a {
    font-weight: 400;
    font-size: 30px;
    line-height: 30px;
    color: #77cad2;
    font-family: Teko;
    cursor: pointer
}

.index_hero {
    min-height: calc(100vh - 126px);
    margin-top: 126px
}

.index_hero .hero__main {
    display: flex;
    align-items: center;
    height: 100%
}

.index_hero .hero__main .hero_content {
    max-width: 494px;
    width: 100%;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.index_hero .hero__main .hero_content .hero_title {
    font-weight: 700;
    font-size: 70px;
    line-height: 70px;
    color: #fff;
    text-shadow: 0 4px 4px rgba(0, 0, 0, .25);
    margin-bottom: 25px
}

.index_hero .hero__main .hero_content .hero_detail {
    font-weight: 400;
    font-size: 35px;
    line-height: 35px;
    color: #77cad2;
    margin-bottom: 35px;
    font-family: Teko
}

.index_hero .hero__main .hero_content .register_btn {
    max-width: 179px;
    width: 100%;
    margin: 0 auto;
    height: 50px;
    background: #fff;
    border-radius: 5px;
    font-weight: 400;
    font-size: 30px;
    line-height: 30px;
    color: #000;
    font-family: Teko
}

.index_hero .hero_img {
    max-width: 620px;
    width: 100%;
    margin-left: auto
}

.index_hero .hero_img img {
    -webkit-transform: translate(10%, 0);
    transform: translate(10%, 0)
}

.why_choose {
    padding-top: 190px;
    padding-bottom: 200px;
    background: #5f6265
}

.why_choose .why_choose_main .img_wrap img,
.why_choose .why_choose_main .why_choose_content {
    max-width: 550px;
    width: 100%
}

.why_choose .why_choose_main .why_choose_content h1 {
    font-size: 50px;
    font-weight: 700;
    line-height: 50px;
    margin-bottom: 35px;
    text-shadow: 0 4px 4px rgba(0, 0, 0, .25)
}

.contractor_wrap .contractor_inner .contractor_content .contractor_title span,
.why_choose .why_choose_main .why_choose_content p {
    font-weight: 300;
    font-size: 35px;
    line-height: 35px;
    color: #fff;
    font-family: Teko
}

.service_wrap {
    background-color: #3a3839eb;
    padding: 120px 0
}

.service_wrap .service_inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    max-width: 930px;
    width: 100%;
    margin: 0 auto;
    gap: 60px 0;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}

.price_wrap .price_inner,
.team_wrap .team_inner {
    display: -webkit-box;
    display: -ms-flexbox;
    -webkit-box-pack: justify
}

.service_wrap .service_inner .service_box p {
    font-weight: 300;
    font-size: 35px;
    line-height: 35px;
    color: #77cad2;
    font-family: Teko
}

.service_wrap .service_inner .service_box .img_wrap img {
    max-width: 141px;
    width: 100%
}

.price_wrap {
    background: #f0f0f0;
    padding: 100px 0
}

.price_wrap h2 {
    text-align: center;
    color: #191a1d;
    text-shadow: 0 1px 4px rgba(0, 0, 0, .25);
    margin-bottom: 10px
}

.price_wrap .price_inner .price_box .account_btn,
.price_wrap p {
    font-size: 40px;
    line-height: 40px;
    font-weight: 400;
    text-align: center
}

.price_wrap p {
    font-family: Teko;
    color: #3a3839;
    margin-bottom: 40px
}

.price_wrap .price_inner {
    display: flex;
    max-width: 1120px;
    width: 100%;
    margin: 0 auto;
    -ms-flex-pack: justify;
    justify-content: space-between
}

.price_wrap .price_inner .price_box {
    background: -webkit-gradient(linear, left top, left bottom, from(rgba(119, 202, 210, .98)), to(rgba(119, 202, 210, .7644)));
    background: linear-gradient(180deg, rgba(119, 202, 210, .98) 0, rgba(119, 202, 210, .7644) 100%);
    -webkit-box-shadow: 4px 4px 21px rgba(0, 0, 0, .25);
    box-shadow: 4px 4px 21px rgba(0, 0, 0, .25);
    border-radius: 10px;
    padding: 60px 70px 90px;
    max-width: 432px;
    width: 100%
}

.price_wrap .price_inner .price_box .price__box {
    padding: 30px 70px 90px;
    background: #f0f0f0;
    -webkit-box-shadow: 4px 4px 21px rgba(0, 0, 0, .25);
    box-shadow: 4px 4px 21px rgba(0, 0, 0, .25);
    height: 100%;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px
}

.hero_wrapper .chart_main {
    margin: auto;
    align-items: center;
    align-content: center;
    text-align: center
}

select option:disabled {
    display: none
}

.hero_wrapper .chart_main h2 {
    color: #fff;
    font-size: 45px
}

.hero_wrapper .chart_main p {
    font-size: 30px;
    margin-top: 10px;
    font-family: Teko
}

.price_wrap .price_inner .price_box .account_btn {
    border-radius: 20px;
    max-width: 280px;
    width: 100%;
    height: 64px;
    margin: 0 auto;
    font-family: Teko;
    color: #f0f0f0;
    background: -webkit-gradient(linear, left top, left bottom, from(rgba(58, 56, 57, .62)), to(#282225));
    background: linear-gradient(180deg, rgba(58, 56, 57, .62) 0, #282225 100%);
    font-weight: 100
}

.price_wrap .price_inner .price_box .account_btn.account__btn {
    background: -webkit-gradient(linear, left top, left bottom, from(#77cad2), to(#d9d9d9));
    background: linear-gradient(180deg, #77cad2 0, #d9d9d9 100%);
    color: #000
}

.price_wrap .price_inner .price_box h1 {
    font-weight: 300;
    font-size: 70px;
    line-height: 80px;
    text-align: center;
    color: #3a3839;
    margin: 40px 0;
    font-family: Teko
}

.price_wrap .price_inner .price_box h5 {
    font-weight: 400;
    font-size: 35px;
    line-height: 35px;
    color: #3a3839;
    font-family: Teko
}

.modal_delete .modal-dialog .modal-content .modal-body p span,
.nav_wrapper .nav_inner .nav_right .dropdown .dropdown-menu li .wallet_detail p span,
.team_wrap h2 {
    font-weight: 700
}

.heading-for-finance-card {
    font-size: 35px !important;
    line-height: 35px !important;
    color: #3a3839 !important;
    font-family: Teko !important;
    margin: 0 !important;
    text-align: start !important;
    font-weight: 200
}

.price_wrap .price_inner .price_box .buynow_btn {
    max-width: 240px;
    width: 100%;
    height: 60px;
    margin: 40px auto 0;
    border-radius: 5px;
    font-weight: 300;
    font-size: 30px;
    line-height: 30px;
    font-family: Teko;
    color: #000;
    background: #fff
}

.contractor_wrap .contractor_wallet .nav-tabs .nav-item .nav-link.active,
.modal_delete .modal-dialog .modal-content .modal-body .btn_wrap .btn.confirm_btn,
.price_wrap .price_inner .price_box .buynow_btn.buynow__btn {
    background: #77cad2
}

.team_wrap {
    padding-top: 90px;
    padding-bottom: 200px;
    background: #3a3839
}

.team_wrap h2 {
    font-size: 70px;
    line-height: 70px;
    text-align: center;
    color: #fff;
    text-shadow: 0 4px 4px rgba(0, 0, 0, .25);
    margin-bottom: 50px
}

.team_wrap .team_inner {
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between
}

.team_wrap .team_inner .team_box .img_wrap img {
    border-radius: 50%;
    margin-bottom: 50px
}

.team_wrap .team_inner .team_box h3 {
    font-weight: 700;
    font-size: 25px;
    line-height: 25px;
    text-align: center;
    color: #fff;
    margin-bottom: 10px
}

.team_wrap .team_inner .team_box p {
    font-weight: 400;
    font-size: 25px;
    line-height: 25px;
    text-align: center;
    color: #fff;
    font-family: Teko
}

.contact_box {
    background: #fff;
    -webkit-box-shadow: 4px 4px 4px rgba(0, 0, 0, .25);
    box-shadow: 4px 4px 4px rgba(0, 0, 0, .25);
    border-radius: 10px;
    padding: 40px 100px 50px 50px
}

.contact_box h2 {
    font-weight: 600;
    font-size: 36px;
    line-height: 36px;
    margin-bottom: 25px;
    color: #1d1b19
}

.contact_box .input_wrap label {
    font-weight: 600;
    font-size: 24px;
    line-height: 24px;
    color: #3a3839;
    margin-bottom: 10px
}

.contact_box .input_wrap input {
    border: none;
    border-bottom: 1px solid #3a3839;
    border-radius: unset;
    margin-bottom: 0
}

.contact_box .input_wrap textarea {
    background: #f0f0f0;
    border-radius: 5px;
    height: 160px;
    margin-bottom: 40px
}

.contact_box .input_wrap .form-control:focus,
.hero_wrapper .need_review .accordion .accordion-button:focus,
.hero_wrapper .need_review .accordion .accordion-button:not(.collapsed) {
    border-color: unset;
    background-color: unset;
    -webkit-box-shadow: unset;
    box-shadow: unset
}

.contact_box .send_message {
    max-width: 247px;
    width: 100%;
    margin: 0 auto;
    height: 60px;
    background: #77cad2;
    border-radius: 5px;
    font-weight: 400;
    font-size: 30px;
    line-height: 30px;
    font-family: Teko;
    color: #3a3839
}

.popover .popover-body,
.popover .popover-header {
    font-size: 11px;
    line-height: 16px;
    color: #3a3839;
    font-weight: 400
}

.live_wrap .live_chat span,
.manage_wallet .active_heading {
    font-size: 30px;
    line-height: 30px;
    font-family: Teko;
    font-weight: 400
}

.contact_wrap {
    padding: 80px 0;
    background: #13263c;
    border: 1px solid white;
    border-radius: 20px;
    position: absolute;
}

.live_wrap {
    margin-top: 50px
}

.live_wrap .live_chat {
    max-width: 247px;
    width: 100%;
    margin: 0 auto;
    height: 60px;
    background: #fff;
    border-radius: 5px;
    gap: 5px
}

.live_wrap .live_chat span {
    color: #000
}

.nav_wrapper {
    background-color: #000
}

.nav_wrapper .nav_inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 126px
}

.nav_wrapper .nav_inner .nav_right {
    height: 64px
}

.nav_wrapper .nav_inner .nav_right .btn {
    font-weight: 600;
    font-size: 24px;
    line-height: 34px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #000;
    background: #fff;
    border-radius: 5px
}

.nav_wrapper .nav_inner .nav_right .btn.sign_btn {
    width: 143px;
    height: 65px
}

.nav_wrapper .nav_inner .nav_right .btn.create_btn {
    width: 225px;
    height: 65px
}

.nav_wrapper .nav_inner .nav_right .btn.logout_btn {
    max-width: 179px;
    width: 118px;
    height: 50px
}

.nav_wrapper .nav_inner .nav_right .btn.logout_btn.dash_btn {
    background-color: #77cad2;
    margin-right: 0;
    padding: 13px
}

.nav_wrapper .nav_inner .nav_right .right_inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 600px;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.nav_wrapper .nav_inner .nav_right .right_inner .nav_icons {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-right: 30px;
    gap: 28px
}

.nav_wrapper .nav_inner .nav_right .right_inner .nav_icons .img_wrap {
    max-width: 50px;
    width: 100%;
    height: 36px;
    cursor: pointer
}

.nav_wrapper .nav_inner .nav_right .right_inner .nav_icons .img_wrap img {
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content
}

.nav_wrapper .nav_inner .nav_right .nav-tabs {
    border: none;
    width: 487px;
    margin-left: auto;
    background: #282b30;
    -webkit-box-shadow: inset 0 3px 20px rgba(0, 0, 0, .15);
    box-shadow: inset 0 3px 20px rgba(0, 0, 0, .15);
    border-radius: 100px;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.nav_wrapper .nav_inner .nav_right .nav-tabs .nav-link {
    width: 160px;
    padding: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 33px;
    font-weight: 600;
    font-size: 20px;
    line-height: 18px;
    text-align: center;
    color: #aaaaab
}

.ul_open,
.ul_open_close {
    background: #2a2a2c;
    border-radius: 10px;
    text-align: center;
    list-style: none
}

.nav_wrapper .nav_inner .nav_right .dropdown .dropdown-menu li,
.popover,
.popover .popover-header {
    background-color: #77cad2
}

.nav_wrapper .nav_inner .nav_right .nav-tabs .nav-link.active {
    background: #fff;
    -webkit-box-shadow: 0 0 4px rgba(0, 0, 0, .35);
    box-shadow: 0 0 4px rgba(0, 0, 0, .35);
    color: #191a1d;
    border-radius: 100px
}

.nav_wrapper .nav_inner .nav_right .dropdown .dropdown-menu {
    left: -302px !important;
    top: 48px !important;
    width: 431px;
    padding: 0
}

.nav_wrapper .nav_inner .nav_right .dropdown .dropdown-menu li .dropdown-item.header_sec {
    background: linear-gradient(270deg, #000 2.15%, #484748 81.31%, #3a3839 99.27%);
    padding: 20px 0
}

.nav_wrapper .nav_inner .nav_right .dropdown .dropdown-menu li .dropdown-item.header_sec h4 {
    font-weight: 400;
    font-size: 27px;
    line-height: 27px;
    color: #fff;
    font-family: Teko
}

.nav_wrapper .nav_inner .nav_right .dropdown .dropdown-menu li .wallet_detail {
    padding-top: 24px;
    padding-bottom: 20px
}

.nav_wrapper .nav_inner .nav_right .dropdown .dropdown-menu li .wallet_detail p {
    font-weight: 500;
    font-size: 25px;
    line-height: 36px;
    font-family: Teko;
    color: #191a1d;
    margin: 0 auto;
    max-width: 380px;
    width: 100%;
    text-align: center;
    padding-bottom: 10px
}

.nav_wrapper .nav_inner .nav_right .dropdown .dropdown-menu li .wallet_detail .wallet_btn {
    max-width: 179px;
    width: 100%;
    margin: 0 auto;
    height: 50px;
    background: #fff;
    border-radius: 5px;
    font-family: Teko;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 34px;
    color: #000
}

.nav_wrapper .nav_inner .nav_right .dropdown .notify_arrow {
    position: absolute;
    top: -38px;
    right: 0
}

.ul_open {
    width: 138px;
    padding: 8px 12px;
    margin: 0 0 0 -45px;
    position: absolute
}

.ul_open_close {
    display: none !important;
    width: 255px;
    padding: 5px 10px;
    margin: 0 0 0 -68px;
    position: absolute
}

.ul_open li {
    display: inline;
    margin: 0 8px
}

.ul_open img {
    width: 25px !important
}

.ul_open .popover__menu {
    -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, .25);
    box-shadow: 0 4px 4px rgba(0, 0, 0, .25);
    list-style-type: none;
    min-width: 80px;
    position: absolute;
    padding: 0;
    top: 43px;
    right: 7px;
    opacity: 0
}

.ul_open .popover__menu.whale_poper {
    right: -36px
}

.ul_open .popover__menu:after {
    bottom: 100%;
    border: 14px solid rgba(255, 255, 255, 0);
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-bottom-color: #fff;
    left: 50%;
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0)
}

.ul_open .popover__menu-item {
    white-space: nowrap
}

.ul_open .popover__menu-item button {
    width: 100%;
    text-align: center;
    padding: 10px 13px;
    background-color: #fff;
    border-radius: 10px;
    border: none
}

.hero_wrapper .multiple_inner .account_main .account_inner .input_wrap .form-control:disabled,
.ul_open .popover__menu-item button:focus,
.ul_open .popover__menu-item button:hover {
    background-color: #fff
}

.ul_open .popover--active .popover__menu {
    opacity: 1
}

.ul_open button {
    border-color: #ccc;
    border-radius: 4px;
    cursor: pointer;
    padding: .6rem 1rem
}

.popover {
    z-index: 99999999999999999999999;
    left: -115px !important
}

.alert_danger,
.alert_success {
    position: absolute !important;
    padding: 10px 0 0 !important;
    left: 35px !important;
    font-size: 20px;
    line-height: 29px;
    font-weight: 400
}

.popover .popover-body {
    padding-top: 0
}

.popover .popover-arrow {
    left: 115px !important
}

.popover .popover-arrow::after,
.popover .popover-arrow::before {
    border-top-color: #77cad2 !important
}

.alert_success {
    color: #20a320 !important
}

.alert_danger {
    color: #dd1f1f !important
}

.manage_wallet .container {
    max-width: 1385px !important;
    width: 100% !important
}

.manage_wallet .active_days {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    max-width: 706px;
    width: 100%;
    margin: 0 auto 30px;
    gap: 30px
}

.manage_wallet .active_days .active_inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 8px;
    max-width: 130px;
    width: 100%
}

.manage_wallet .active_days .active_btnz {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 15px;
    width: 100%
}

.manage_wallet .active_days .active_btnz .active_btn {
    background: #77cad2;
    -webkit-box-shadow: 0 2px 4px #3a3839;
    box-shadow: 0 2px 4px #3a3839;
    border-radius: 5px;
    font-weight: 400;
    font-size: 24px;
    line-height: 34px;
    font-family: Teko;
    color: #000;
    max-width: 117px;
    width: 100%;
    height: 55px
}

.hero_wrapper .crypto_account .crypto_left h3,
.hero_wrapper .need_review .checkbox_wrapper .checkbox_inner .form-group label,
.manage_wallet .active_heading,
.notification :hover,
.terms_policy .modal-body .terms_details p {
    color: #fff
}

.manage_wallet .wallet_title {
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    gap: 10px;
    margin-bottom: 40px
}

.manage_wallet .wallet_title.wallet__title {
    max-width: 706px;
    width: 100%;
    margin: 0 auto;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.manage_wallet .wallet_title.wallet__title img {
    width: 24px !important
}

.manage_wallet .wallet_title h1 {
    font-weight: 700;
    font-size: 65px;
    line-height: 65px;
    color: #fff;
    text-shadow: 0 4px 4px rgba(0, 0, 0, .25)
}

.manage_wallet .wallet_title img {
    max-width: 75px;
    width: 100%
}

.manage_wallet h3 {
    font-weight: 700;
    font-size: 60px;
    line-height: 60px;
    color: #fff;
    margin-bottom: 50px;
    padding-left: 35px
}

.table_wrap td,
.table_wrap th {
    font-weight: 300;
    font-family: Teko
}

.manage_wallet .wallet_label {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 20px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 60px;
    padding-left: 35px
}

.table_wrap .edit_delete_btnz,
.table_wrap .edit_tr {
    display: none !important;
    position: absolute;
    right: 0
}

.manage_wallet .wallet_label .add_wallet {
    max-width: 201px;
    width: 100%;
    height: 52px;
    background: #77cad2;
    -webkit-box-shadow: 0 2px 4px #3a3839;
    box-shadow: 0 2px 4px #3a3839;
    border-radius: 5px;
    gap: 10px
}

.manage_wallet .wallet_label .add_wallet span {
    font-weight: 400;
    font-size: 24px;
    line-height: 24px;
    font-family: Teko;
    color: #000
}

.manage_wallet .wallet_label .wallet_dropdown {
    max-width: 255px;
    width: 100%
}

.manage_wallet .wallet_label .wallet_dropdown .dropdown {
    background: #fff;
    border: 1px solid #000;
    border-radius: 5px;
    height: 52px
}

.manage_wallet .wallet_label .wallet_dropdown .dropdown a {
    padding: 0 15px;
    font-weight: 300;
    font-size: 25px;
    line-height: 25px;
    color: #191a1d;
    font-family: Teko
}

.manage_wallet .wallet_label .wallet_dropdown .dropdown a img {
    width: 24px
}

.manage_wallet .wallet_label .input_wrap {
    position: relative;
    max-width: 314px;
    width: 100%;
    height: 52px
}

.manage_wallet .wallet_label .input_wrap input {
    padding: 6px 0 6px 45px;
    font-weight: 400;
    font-size: 30px;
    line-height: 28px;
    color: #aaa0a0;
    border: none;
    -webkit-box-shadow: 0 2px 4px #3a3839;
    box-shadow: 0 2px 4px #3a3839;
    border-radius: 6px;
    font-family: Teko;
    height: 100%
}

.manage_wallet .wallet_label .input_wrap .img_wrap,
.modal_add_user .modal-dialog .modal-content .modal-body .input_wrap .user_img,
.modal_setting .modal-dialog .modal-content .modal-body .input_wrap .user_img {
    position: absolute;
    left: 15px;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%)
}

.table_wrap.transaction_tbl table {
    border-collapse: separate;
    border-spacing: 0 15px;
    margin-bottom: 35px
}

.table_wrap.transaction_tbl table th,
.wallet-pagiantion {
    text-align: center !important
}

.table_wrap td:first-child,
.table_wrap.transaction_tbl table td:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px
}

.table_wrap td:last-child,
.table_wrap.transaction_tbl table td:last-child {
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px
}

.table_wrap th,
.table_wrap tr {
    border-style: unset
}

.table_wrap tr {
    height: 81px;
    position: relative
}

.table_wrap tr:hover .edit_delete_btnz,
.table_wrap tr:hover .edit_tr {
    display: block !important
}

.table_wrap .edit_tr {
    top: 0;
    bottom: 0;
    max-width: 33px;
    width: 100%;
    background: #77cad2;
    border-radius: 10px 0 0;
    height: 81px
}

.table_wrap .edit_tr span {
    font-weight: 500;
    font-size: 20px;
    line-height: 20px;
    text-transform: uppercase;
    color: #000;
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    font-family: Teko;
    vertical-align: middle;
    min-height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-direction: column;
    flex-direction: column
}

.table_wrap .edit_delete_btnz .edit_delete {
    background: #77cad2;
    border-radius: 10px 0 0 10px;
    padding: 3px;
    width: 33px;
    height: 37px
}

.table_wrap .edit_delete_btnz .edit_delete.bg_clr {
    background: #b71c1c;
    margin-top: 5px
}

.table_wrap th {
    font-size: 40px;
    line-height: 40px;
    color: #d9d9d9;
    padding: 5px;
    text-align: center
}

.table_wrap .bg_white {
    background-color: #fff;
    -webkit-box-shadow: 0 4px 6px rgba(0, 0, 0, .09);
    box-shadow: 0 4px 6px rgba(0, 0, 0, .09)
}

.table_wrap td {
    font-size: 30px;
    line-height: 30px;
    color: #3a3839;
    text-align: center
}

.table_wrap .table {
    border-collapse: separate;
    border-spacing: 0 0.3em;
    margin-bottom: 60px
}

.hero_wrapper .container {
    max-width: 1350px;
    width: 100%
}

.hero_wrapper .hero_inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    padding-left: 130px;
    padding-bottom: 250px;
    padding-top: 100px;
    gap: 40px;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start
}

.hero_wrapper .hero_inner .hero_left {
    max-width: 485px;
    width: 100%
}

.hero_wrapper .hero_inner .hero_left .hero_title {
    font-weight: 700;
    font-size: 48px;
    line-height: 69px;
    color: #fff;
    margin-bottom: 10px
}

.hero_wrapper .hero_inner .hero_left .hero_detail {
    font-weight: 600;
    font-size: 19px;
    line-height: 34px;
    color: #fff;
    margin-bottom: 15px
}

.hero_wrapper .hero_inner .hero_left .hero__detail {
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: #fff;
    margin-bottom: 5px;
    font-family: Teko
}

.hero_wrapper .hero_inner .hero_left .hero__detail a {
    cursor: pointer;
    text-decoration: underline
}

.hero_wrapper .hero_inner .hero_left .input_wrap input {
    font-weight: 600;
    font-size: 20px;
    line-height: 29px;
    color: #5f6265;
    padding: 10px 0 10px 35px;
    background: #fff;
    border-radius: 5px;
    margin-bottom: 0
}

.hero_wrapper .hero_inner .hero_left .input_wrap .img_wrap {
    position: absolute;
    z-index: 0;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
    right: 35px
}

.hero_wrapper .hero_inner .hero_left .input_wrap .question_mark {
    position: absolute;
    z-index: 0;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
    right: -40px
}

.hero_wrapper .hero_inner .hero_left .navtab_wrap {
    margin-bottom: 24px;
    margin-top: 45px
}

.hero_wrapper .hero_inner .hero_left .navtab_wrap .nav-tabs {
    border: none;
    width: 325px !important;
    background: #282b30;
    -webkit-box-shadow: inset 0 3px 20px rgba(0, 0, 0, .5);
    box-shadow: inset 0 3px 20px rgba(0, 0, 0, .5);
    border-radius: 100px
}

.hero_wrapper .hero_inner .hero_left .navtab_wrap .nav-tabs .nav-link {
    width: 162px !important;
    color: #aaaaab;
    padding: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 33px;
    -webkit-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    border: none;
    font-weight: 600
}

.hero_wrapper .hero_inner .hero_left .navtab_wrap .nav-tabs .nav-link.active {
    background: #77cad2;
    -webkit-box-shadow: 0 0 4px rgba(0, 0, 0, .35);
    box-shadow: 0 0 4px rgba(0, 0, 0, .35);
    border-radius: 100px;
    width: 162px !important;
    color: #191a1d;
    border: 1px solid #191a1d
}

.hero_wrapper .hero_inner .hero_left .create_btn {
    width: 100%;
    background: #77cad2;
    -webkit-box-shadow: 0 2px 4px #3a3839;
    box-shadow: 0 2px 4px #3a3839;
    border-radius: 5px;
    height: 56px;
    font-weight: 600;
    font-size: 24px;
    line-height: 34px;
    color: #000;
    margin-bottom: 7px
}

.hero_wrapper .hero_inner .hero_left .already_login {
    font-weight: 600;
    font-size: 24px;
    line-height: 34px;
    color: #fff;
    text-align: center
}

.hero_wrapper .hero_inner .hero_left .already_login a,
.hero_wrapper .wallet_balance .wallet_main .expired_sec .expired_content p a {
    color: #77cad2
}

.hero_wrapper .hero_inner .hero_right {
    max-width: 700px;
    width: 100%
}

.hero_wrapper .multiple_inner {
    background-color: #5f6265;
    -webkit-box-shadow: 4px 6px 4px #000;
    box-shadow: 4px 6px 4px #000;
    border-radius: 5px;
    padding: 50px 160px 110px;
    margin-bottom: 115px
}

.hero_wrapper .multiple_inner h2 {
    font-weight: 600;
    font-size: 45px;
    line-height: 64px;
    color: #77cad2;
    margin-bottom: 25px
}

.hero_wrapper .multiple_inner .your_account,
.sales_body .sale_detail.get_wallet,
.sales_body .sale_detail.manage_acct {
    padding-bottom: 50px
}

.hero_wrapper .multiple_inner .your_account h3 {
    font-weight: 600;
    font-size: 40px;
    line-height: 57px;
    color: #fff;
    text-align: center
}

.hero_wrapper .multiple_inner .your_account p {
    font-weight: 500;
    font-size: 36px;
    line-height: 52px;
    color: #fff;
    margin-bottom: 25px;
    text-align: center
}

.hero_wrapper .multiple_inner .your_account .input_wrap {
    position: relative;
    max-width: 339px;
    width: 100%;
    margin: 0 auto
}

.hero_wrapper .multiple_inner .your_account .input_wrap input {
    padding: 6px 0 6px 65px;
    font-weight: 400;
    font-size: 30px;
    line-height: 43px;
    color: #aaa0a0;
    border: none;
    -webkit-box-shadow: 0 2px 4px #3a3839;
    box-shadow: 0 2px 4px #3a3839;
    border-radius: 6px
}

.hero_wrapper .multiple_inner .your_account .input_wrap .img_wrap {
    position: absolute;
    left: 25px;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%)
}

.hero_wrapper .multiple_inner .account_main {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-bottom: 60px
}

.hero_wrapper .multiple_inner .account_main .account_inner {
    max-width: 359px;
    width: 100%
}

.hero_wrapper .multiple_inner .account_main .account_inner h3 {
    font-weight: 600;
    font-size: 40px;
    line-height: 57px;
    color: #fff;
    margin-bottom: 25px
}

.hero_wrapper .multiple_inner .account_main .account_inner .input_wrap {
    position: relative;
    margin-bottom: 20px
}

.hero_wrapper .multiple_inner .account_main .account_inner .input_wrap label {
    position: absolute;
    left: 25px;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
    font-weight: 600;
    font-size: 24px;
    line-height: 25px;
    color: #191a1d;
    cursor: pointer
}

.hero_wrapper .multiple_inner .account_main .account_inner .input_wrap input {
    -webkit-box-shadow: 0 0 0 2px #77cad2;
    box-shadow: 0 0 0 2px #77cad2;
    border-radius: 10px;
    height: 54px;
    cursor: pointer
}

.hero_wrapper .multiple_inner .account_main .account_inner .input_wrap .img_wrap {
    position: absolute;
    right: 25px;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%)
}

.hero_wrapper .crypto_account {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background: #5f6265;
    border-radius: 5px;
    padding: 30px 50px 30px 70px;
    margin-bottom: 30px
}

.hero_wrapper .crypto_account .crypto_left {
    max-width: 450px;
    width: 100%
}

.hero_wrapper .crypto_account .crypto_left h2 {
    font-family: Teko;
    font-weight: 300;
    font-size: 45px;
    line-height: 64px;
    color: #77cad2
}

.hero_wrapper .crypto_account .crypto_left p {
    font-family: Teko;
    font-weight: 400;
    font-size: 40px;
    line-height: 57px;
    color: #fff
}

.hero_wrapper .crypto_account .crypto_left h1 {
    font-weight: 700;
    font-size: 60px;
    line-height: 86px;
    color: #fff
}

.hero_wrapper .crypto_account .crypto_left .active_sub h4 {
    font-weight: 400;
    font-size: 28px;
    line-height: 28px;
    font-family: Teko;
    color: #fff
}

.hero_wrapper .crypto_account .crypto_left .active_sub h4 span,
.modal_monthly_report .modal-dialog .modal-content .modal-body .order_detail.order__detail span,
.modal_order_365 .modal-dialog .modal-content .modal-body .order_detail.order__detail span,
.modal_order_detail .modal-dialog .modal-content .modal-body .order_detail.order__detail span {
    text-decoration: underline
}

.hero_wrapper .crypto_account .crypto_left .add_time {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 10px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 20px
}

.hero_wrapper .crypto_account .crypto_left .add_time .time_btn {
    max-width: 148px;
    width: 100%;
    height: 32px;
    background: #77cad2;
    -webkit-box-shadow: 0 2px 4px #3a3839;
    box-shadow: 0 2px 4px #3a3839;
    border-radius: 5px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 5px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-weight: 500;
    font-size: 17px;
    line-height: 17px;
    font-family: Teko;
    color: #000
}

.hero_wrapper .crypto_account .crypto_left .add_time p {
    font-weight: 300;
    font-size: 28px;
    line-height: 28px;
    font-family: Teko;
    color: #fff
}

.hero_wrapper .crypto_account .crypto_right {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    max-width: 444px;
    width: 100%;
    gap: 25px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.hero_wrapper .crypto_account .crypto_right .crypto_btn {
    height: 56px;
    background: #77cad2;
    -webkit-box-shadow: 0 2px 4px #3a3839;
    box-shadow: 0 2px 4px #3a3839;
    border-radius: 5px;
    cursor: pointer
}

.hero_wrapper .need_review,
.hero_wrapper .need_review .accordion .accordion-body {
    background: linear-gradient(270.21deg, #737576 .29%, rgba(78, 81, 83, .6566) 96.33%)
}

.hero_wrapper .crypto_account .crypto_right .crypto_btn a {
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
    color: #000;
    height: 100%;
    gap: 9px
}

.hero_wrapper .crypto_account .crypto_right .crypto_btn.monthly {
    max-width: 188px;
    width: 100%
}

.hero_wrapper .crypto_account .crypto_right .crypto_btn.wallet {
    max-width: 222px;
    width: 100%
}

.hero_wrapper .crypto_account .crypto_right .crypto_btn.personal {
    max-width: 216px;
    width: 100%
}

.hero_wrapper .crypto_account .payment2_inner {
    padding-top: 60px;
    padding-bottom: 14px
}

.hero_wrapper .crypto_account .payment2_inner h3 {
    font-weight: 400;
    font-size: 45px;
    line-height: 45px;
    position: absolute;
    left: 72px;
    top: 33px;
    color: #77cad2
}

.hero_wrapper .crypto_account .payment2_inner h1 {
    font-weight: 700;
    font-size: 60px;
    line-height: 60px;
    color: #fff;
    text-align: center;
    padding-bottom: 10px
}

.hero_wrapper .crypto_account .payment2_inner p {
    font-weight: 400;
    font-size: 45px;
    line-height: 44px;
    color: #fff;
    text-align: center;
    font-family: Teko
}

.hero_wrapper .need_review .review_head h5,
.hero_wrapper .need_review .review_head h6 {
    font-weight: 400;
    font-size: 45px;
    line-height: 45px;
    font-family: Teko;
    color: #191a1d;
    width: 100%
}

.hero_wrapper .crypto_account .payment2_inner .btnz_wrap .payment_order {
    max-width: 575px;
    width: 100%;
    font-weight: 400;
    font-size: 40px;
    line-height: 40px;
    color: #000;
    border-radius: 5px;
    font-family: Teko;
    height: 82px
}

.hero_wrapper .crypto_account .payment2_inner .btnz_wrap .payment_order.bewq_order {
    height: 60px;
    font-size: 30px
}

.hero_wrapper .crypto_account .payment2_inner .btnz_wrap .payment_order.bg_blue {
    background: #77cad2;
    -webkit-box-shadow: 0 2px 4px #3a3839;
    box-shadow: 0 2px 4px #3a3839
}

.hero_wrapper .crypto_account .payment2_inner .btnz_wrap .payment_order.bg_white {
    background: #fff;
    -webkit-box-shadow: 0 2px 4px #3a3839;
    box-shadow: 0 2px 4px #3a3839
}

.hero_wrapper.payment2_main {
    margin-bottom: 120px
}

.hero_wrapper .need_review {
    -webkit-box-shadow: 8px 10px 4px rgba(0, 0, 0, .25);
    box-shadow: 8px 10px 4px rgba(0, 0, 0, .25);
    border-radius: 5px;
    padding: 35px 47px 200px
}

.bg_body_for_page,
.bg_body_for_register_page {
    background: #3a3839 !important
}

.hero_wrapper .need_review .head_bg {
    background-color: #fff;
    -webkit-box-shadow: 0 4px 6px rgba(0, 0, 0, .09);
    box-shadow: 0 4px 6px rgba(0, 0, 0, .09);
    margin-bottom: 5px
}

.hero_wrapper .need_review .review_head {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 5px;
    padding: 10px 0 10px 33px;
    max-width: 815px;
    width: 100%;
    margin-bottom: 15px
}

.hero_wrapper .need_review .review_head h5 {
    max-width: 100px;
    text-align: start
}

.hero_wrapper .need_review .review_head h6 {
    max-width: 255px;
    text-align: center
}

.hero_wrapper .need_review .accordion .accordion-button::after {
    background-image: url("../img/drop_left.svg");
    background-size: contain;
    height: 30px;
    width: 30px
}

.hero_wrapper .need_review .accordion .accordion-button:not(.collapsed)::after,
.hero_wrapper .wallet_balance .wallet_main .wallet_content .accordion-button:not(.collapsed)::after {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg)
}

.hero_wrapper .need_review .accordion .accordion-header {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    margin-bottom: 5px
}

.hero_wrapper .need_review .accordion .accordion-button {
    padding: 0;
    width: 115px;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: end
}

.hero_wrapper .need_review .review_content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 5px;
    padding: 5px 33px;
    max-width: 860px;
    width: 100%;
    position: relative;
    background-color: #fff
}

.hero_wrapper .need_review .review_content.sub_wrap {
    max-width: 625px;
    width: 100%;
    margin: 0 auto;
    padding-right: 44px;
    padding-left: 30px
}

.hero_wrapper .need_review .review_content h4 {
    font-weight: 500;
    font-size: 50px;
    line-height: 50px;
    color: #191a1d;
    font-family: Teko;
    max-width: 100px;
    width: 100%;
    text-align: center
}

.hero_wrapper .need_review .review_content .btn {
    font-weight: 300;
    font-size: 40px;
    line-height: 57px;
    font-family: Teko;
    max-width: 249px;
    width: 100%;
    border-radius: 34px;
    height: 65px
}

.hero_wrapper .need_review .review_content .btn.partial {
    color: #bb8300;
    background: rgba(250, 186, 35, .6)
}

.hero_wrapper .need_review .review_content .btn.subscribe {
    color: #209f00;
    background: rgba(132, 228, 164, .62)
}

.hero_wrapper .need_review .review_content .btn.paid {
    color: #77cad2;
    background: rgba(119, 202, 210, .19)
}

.hero_wrapper .need_review .review_content .btn.n_subscribe {
    color: #b71c1c;
    background: rgba(183, 28, 28, .31)
}

.hero_wrapper .need_review .form-group input,
.modal_monthly_calender .modal-dialog .modal-content .modal-header .checkbox_wrap .form-group input,
.modal_monthly_report .modal-dialog .modal-content .modal-body .report_inner .checkbox_wrap .form-group input,
.modal_order_365 .modal-dialog .modal-content .modal-body .report_inner .checkbox_wrap .form-group input,
.modal_order_detail .modal-dialog .modal-content .modal-body .report_inner .checkbox_wrap .form-group input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer
}

.hero_wrapper .need_review .form-group label {
    position: relative;
    cursor: pointer;
    font-weight: 400;
    font-size: 40px;
    line-height: 38px;
    color: red;
    font-family: Teko;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start
}

.hero_wrapper .need_review .form-group label::before {
    content: '';
    -webkit-appearance: none;
    background: rgba(119, 202, 210, .69);
    border-radius: 5px;
    padding: 0;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 5px;
    width: 30px;
    height: 30px
}

.hero_wrapper .need_review .form-group input:checked+label:after {
    content: '';
    display: block;
    position: absolute;
    background-image: url("../img/green_tick.svg");
    background-size: contain;
    background-repeat: no-repeat;
    top: 0;
    left: 2px;
    width: 30px;
    height: 30px
}

.hero_wrapper .need_review .checkbox_wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    max-width: 425px;
    width: 100%;
    margin: 0 auto
}

.chart_content .dropdown,
.hero_wrapper .need_review .checkbox_wrapper .checkbox_inner .form-group {
    margin-bottom: 15px
}

.hero_wrapper .need_review .checkbox_wrapper .checkbox_inner .form-group.paid_input label::before {
    background: #5f6265
}

.hero_wrapper .need_review .checkbox_wrapper .checkbox_inner .form-group p {
    font-weight: 400;
    font-size: 40px;
    line-height: 40px;
    font-family: Teko;
    color: #fff;
    margin-left: 10px
}

.hero_wrapper .need_review .checkbox_wrapper .checkbox_inner .form-group input:checked+label:after {
    background-image: url("../img/blue_tick.svg");
    background-size: contain;
    background-repeat: no-repeat
}

.hero_wrapper .need_review .checkbox_wrapper .checkbox_inner .form-group label::before {
    background: #fff
}

.hero_wrapper .wallet_balance .wallet_main {
    background: linear-gradient(269.77deg, #585d62 .1%, rgba(95, 98, 101, .656647) 98.07%, rgba(95, 98, 101, .656647) 98.07%, rgba(95, 98, 101, .656647) 98.07%);
    -webkit-box-shadow: 8px 10px 4px rgba(0, 0, 0, .25);
    box-shadow: 8px 10px 4px rgba(0, 0, 0, .25);
    border-radius: 5px;
    padding-top: 75px;
    padding-bottom: 40px;
    margin-bottom: 115px
}

.hero_wrapper .wallet_balance .wallet_main .wallet_header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 0 auto;
    padding-bottom: 20px;
    position: relative
}

.hero_wrapper .wallet_balance .wallet_main .wallet_header::before {
    content: "";
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 1px;
    background-color: #f5f5f5
}

.hero_wrapper .wallet_balance .wallet_main .wallet_header .wallet_title {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 15px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.hero_wrapper .wallet_balance .wallet_main .wallet_header .wallet_title h3 {
    font-weight: 400;
    font-size: 40px;
    line-height: 38px;
    color: #fff;
    font-family: Teko
}

.hero_wrapper .wallet_balance .wallet_main .wallet_content {
    padding-top: 20px;
    max-width: 1150px;
    width: 100%;
    margin: 0 auto
}

.hero_wrapper .wallet_balance .wallet_main .wallet_content .accordion-button::after {
    background-image: url("../img/drop_left.svg");
    background-size: contain
}

.hero_wrapper .wallet_balance .wallet_main .wallet_content.prototype_1 .accordion-button {
    background: #77cad2;
    -webkit-box-shadow: 0 4px 6px rgba(0, 0, 0, .09);
    box-shadow: 0 4px 6px rgba(0, 0, 0, .09)
}

.hero_wrapper .wallet_balance .wallet_main .wallet_content .accordion-button {
    padding: 0 20px 0 0;
    background-color: #fff;
    border-radius: 10px
}

.hero_wrapper .wallet_balance .wallet_main .wallet_content .accordion-item {
    margin-bottom: 5px;
    background: #585d6200;
    border: none
}

.hero_wrapper .wallet_balance .wallet_main .wallet_content .accordion-button:focus,
.hero_wrapper .wallet_balance .wallet_main .wallet_content .accordion-button:not(.collapsed) {
    -webkit-box-shadow: none;
    box-shadow: none
}

.hero_wrapper .wallet_balance .wallet_main .wallet_content .accordion_head {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    max-width: 930px;
    width: 100%;
    margin: 0 auto;
    padding: 3px 0
}

.hero_wrapper .wallet_balance .wallet_main .wallet_content .accordion_head p {
    font-family: Teko;
    font-weight: 500;
    font-size: 30px;
    line-height: 43px;
    color: #191a1d
}

.hero_wrapper .wallet_balance .wallet_main .wallet_content .accordion_head h3 {
    font-weight: 700;
    font-size: 30px;
    line-height: 43px;
    color: #191a1d
}

.hero_wrapper .wallet_balance .wallet_main .wallet_content .table_wrap {
    max-width: 1000px;
    width: 100%;
    margin-left: auto
}

.hero_wrapper .wallet_balance .wallet_main .wallet_content .table_wrap tr {
    border: transparent
}

.hero_wrapper .wallet_balance .wallet_main .wallet_content .table_wrap .crypto_img {
    gap: 10px;
    padding-top: 10px;
    max-width: 250px;
    width: 100%
}

.hero_wrapper .wallet_balance .wallet_main .wallet_content .table_wrap .crypto_img img {
    max-width: 50px;
    width: 100%
}

.hero_wrapper .wallet_balance .wallet_main .wallet_content .table_wrap h3 {
    font-weight: 700;
    font-size: 30px;
    line-height: 43px;
    color: #fff;
    max-width: 200px;
    width: 100%;
    word-wrap: break-word
}

.hero_wrapper .wallet_balance .wallet_main .wallet_content .table_wrap p {
    font-weight: 700;
    font-size: 24px;
    line-height: 24px;
    color: #fff;
    text-align: center;
    word-wrap: break-word;
    max-width: 200px;
    width: 100%
}

.hero_wrapper .wallet_balance .wallet_main .expired_sec {
    padding-top: 97px
}

.hero_wrapper .wallet_balance .wallet_main .expired_sec .expired_content {
    max-width: 810px;
    width: 100%;
    margin: 0 auto;
    padding-top: 45px;
    padding-bottom: 200px
}

.hero_wrapper .wallet_balance .wallet_main .expired_sec .expired_content h3 {
    font-weight: 700;
    font-size: 50px;
    line-height: 50px;
    text-align: center;
    color: #fff;
    font-family: Teko
}

.hero_wrapper .wallet_balance .wallet_main .expired_sec .expired_content p {
    font-weight: 400;
    font-size: 50px;
    line-height: 72px;
    text-align: center;
    color: #d9d9d9;
    font-family: Teko
}

.hero_wrapper .wallet_balance .wallet_main .expired_sec .expired_content.expired__content {
    padding-bottom: 100px
}

.total_payment {
    padding-bottom: 200px
}

.total_payment .payment_inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 30px
}

.total_payment .payment_inner h1 {
    font-weight: 700;
    font-size: 60px;
    line-height: 60px;
    color: #fff;
    margin-bottom: 10px;
    max-width: 380px;
    width: 100%
}

.total_payment .payment_inner .send_payment {
    max-width: 450px;
    width: 100%;
    height: 65px;
    background: #77cad2;
    -webkit-box-shadow: 0 2px 4px #3a3839;
    box-shadow: 0 2px 4px #3a3839;
    border-radius: 5px;
    font-weight: 400;
    font-size: 40px;
    line-height: 40px;
    color: #000;
    font-family: Teko;
    margin-top: 25px
}

.custom_input,
.total_payment .payment_inner .payment_middle_wrap .input_wrap {
    margin-bottom: 20px
}

.modal_monthly_report .modal-dialog .modal-content .modal-body .drop_wrap label,
.modal_monthly_report .modal-dialog .modal-content .modal-body .report_inner .input_wrap label,
.modal_order_365 .modal-dialog .modal-content .modal-body .drop_wrap label,
.modal_order_365 .modal-dialog .modal-content .modal-body .report_inner .input_wrap label,
.modal_order_detail .modal-dialog .modal-content .modal-body .drop_wrap label,
.modal_order_detail .modal-dialog .modal-content .modal-body .report_inner .input_wrap label,
.total_payment .payment_inner .payment_middle_wrap label {
    font-weight: 400;
    font-size: 40px;
    line-height: 40px;
    color: #fff;
    font-family: Teko
}

.total_payment .payment_inner .payment_middle_wrap input {
    width: 330px
}

.total_payment .payment_inner .payment_left h3 {
    font-weight: 400;
    font-size: 45px;
    line-height: 45px;
    color: #fff;
    font-family: Teko;
    margin-bottom: 20px
}

.total_payment .payment_inner .payment_left .drop_wrap select {
    position: relative;
    width: 350px;
    height: 52px;
    border: 1px solid #000;
    border-radius: 5px;
    font-weight: 400;
    font-size: 40px;
    line-height: 37px;
    padding-left: 25px;
    font-family: Teko;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url("../img/drop_down.svg");
    background-repeat: no-repeat;
    background-size: 30px;
    padding-right: 1.5rem
}

.total_payment .payment_inner .payment_left .drop_wrap select option {
    background-color: #d9d9d9
}

.total_payment .payment_inner .payment_left .drop_wrap select::-ms-expand {
    display: none
}

.total_payment .payment_inner .payment_left .drop_wrap img {
    position: absolute;
    bottom: 50%;
    right: 15px;
    -webkit-transform: translate(0, 50%);
    transform: translate(0, 50%)
}

.total_payment .payment_inner .payment_left .drop_wrap .select-styled {
    position: absolute
}

.dropdown .dropdown-toggle::after,
.dropdown-toggle::after,
.modal_monthly_report .modal-dialog .modal-content .modal-body .report_inner .input_wrap .dropdown .dropdown-toggle::after,
.modal_order_365 .modal-dialog .modal-content .modal-body .report_inner .input_wrap .dropdown .dropdown-toggle::after,
.modal_order_detail .modal-dialog .modal-content .modal-body .report_inner .input_wrap .dropdown .dropdown-toggle::after,
.total_payment .payment_inner .payment_left .drop_wrap .dropdown-toggle::after {
    content: none
}

.total_payment .payment_inner .payment_left .drop_wrap .dropdown {
    width: 350px;
    background: #fff;
    border: 1px solid #000;
    border-radius: 5px
}

.modal_monthly_report .modal-dialog .modal-content .modal-body .drop_wrap .dropdown a,
.modal_order_365 .modal-dialog .modal-content .modal-body .drop_wrap .dropdown a,
.modal_order_detail .modal-dialog .modal-content .modal-body .drop_wrap .dropdown a,
.total_payment .payment_inner .payment_left .drop_wrap .dropdown a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-left: 20px;
    padding-right: 15px;
    font-weight: 400;
    font-size: 40px;
    line-height: 37px;
    color: #191a1d;
    height: 52px;
    font-family: Teko
}

.modal_monthly_report .modal-dialog .modal-content .modal-body .drop_wrap .dropdown ul,
.modal_order_365 .modal-dialog .modal-content .modal-body .drop_wrap .dropdown ul,
.modal_order_detail .modal-dialog .modal-content .modal-body .drop_wrap .dropdown ul,
.total_payment .payment_inner .payment_left .drop_wrap .dropdown ul {
    width: 100%;
    padding: 0
}

.modal_monthly_report .modal-dialog .modal-content .modal-body .drop_wrap .dropdown ul li,
.modal_order_365 .modal-dialog .modal-content .modal-body .drop_wrap .dropdown ul li,
.modal_order_detail .modal-dialog .modal-content .modal-body .drop_wrap .dropdown ul li,
.total_payment .payment_inner .payment_left .drop_wrap .dropdown ul li {
    background: #d9d9d9;
    margin-bottom: 5px
}

.defi_img.defi_dewq .img_wrap img {
    max-width: 135px
}

.defi_img .container {
    border-bottom: 1px solid #808080d4;
    padding-bottom: 26px
}

.defi_img .container img {
    max-width: 247px;
    width: 100%
}

.defi_img .container .person_img {
    position: absolute;
    bottom: 10px;
    right: 100px
}

.defi_img .container .person_img img {
    max-width: 296px;
    width: 100%
}

.footer_wrapper {
    padding: 40px 0
}

.footer_wrapper .footer_inner ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    max-width: 950px;
    width: 100%;
    gap: 20px;
    padding-left: 0;
    margin-bottom: 0
}

.footer_wrapper .footer_inner ul li {
    list-style: none;
    font-size: 24px;
    line-height: 34px;
    color: #fff;
    cursor: pointer;
    font-weight: 600
}

.footer_wrapper .footer_inner .social_icons {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 13px
}

.bg_body.bg_multipleAccount {
    background-color: #3a3839;
    padding-top: 45px
}

.bg_body.bg_multipleAccount .container {
    max-width: 1231px;
    width: 100%
}

.modal_add_user .modal-dialog,
.modal_add_user2 .modal-dialog,
.modal_setting .modal-dialog,
.update_name .modal-dialog {
    min-width: 611px !important
}

.modal_add_user .modal-dialog .modal-content,
.modal_add_user2 .modal-dialog .modal-content,
.modal_setting .modal-dialog .modal-content,
.modal_view_user .modal-dialog .modal-content,
.update_name .modal-dialog .modal-content {
    background: linear-gradient(90.07deg, #191a1d 1.75%, #3a3839 22.89%);
    border-radius: 5px
}

.modal_add_user .modal-dialog .modal-content .modal-header,
.modal_add_user2 .modal-dialog .modal-content .modal-header,
.modal_delete .modal-dialog .modal-content .modal-header,
.modal_setting .modal-dialog .modal-content .modal-header,
.update_name .modal-dialog .modal-content .modal-header {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: end;
    border: none;
    padding-bottom: 20px
}

.modal_add_user .modal-dialog .modal-content .modal-body,
.modal_add_user2 .modal-dialog .modal-content .modal-body,
.modal_setting .modal-dialog .modal-content .modal-body,
.modal_view_user .modal-dialog .modal-content .modal-body {
    padding: 0 0 75px
}

.modal_add_user .modal-dialog .modal-content .modal-body h2,
.modal_setting .modal-dialog .modal-content .modal-body h2,
.modal_view_user .modal-dialog .modal-content .modal-body h2 {
    font-weight: 700;
    font-size: 40px;
    line-height: 57px;
    color: #77cad2;
    text-align: center;
    padding-bottom: 23px
}

.modal_add_user .modal-dialog .modal-content .modal-body .input_wrap,
.modal_setting .modal-dialog .modal-content .modal-body .input_wrap {
    position: relative;
    max-width: 353px;
    width: 100%;
    margin: 0 auto 7px
}

.modal_add_user .modal-dialog .modal-content .modal-body .input_wrap label,
.modal_setting .modal-dialog .modal-content .modal-body .input_wrap label {
    position: absolute;
    left: 70px;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
    font-weight: 600;
    font-size: 25px;
    line-height: 36px;
    color: #fff;
    cursor: pointer
}

.modal_add_user .modal-dialog .modal-content .modal-body .input_wrap .right_arrow,
.modal_setting .modal-dialog .modal-content .modal-body .input_wrap .right_arrow {
    position: absolute;
    right: 15px;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
    cursor: pointer
}

.modal_add_user .modal-dialog .modal-content .modal-body .input_wrap input {
    background: #191a1d;
    -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, .25);
    box-shadow: 0 4px 4px rgba(0, 0, 0, .25);
    height: 68px;
    border: none;
    cursor: pointer;
    padding: 0
}

.modal_view_user .modal-dialog {
    min-width: 760px !important
}

.modal_view_user .modal-dialog .modal-content .modal-header {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    border: none;
    padding-bottom: 20px
}

.modal_view_user .modal-dialog .modal-content .modal-body .user_main {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: 46px 65px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    max-width: 680px;
    width: 100%;
    margin: 0 auto
}

.modal_view_user .modal-dialog .modal-content .modal-body .user_main .user_inner {
    max-width: 175px;
    width: 100%
}

.modal_view_user .modal-dialog .modal-content .modal-body .user_main .user_inner h4 {
    font-weight: 600;
    font-size: 22px;
    line-height: 22px;
    margin-bottom: 10px;
    color: #fff
}

.modal_view_user .modal-dialog .modal-content .modal-body .user_main .user_inner .user_name {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 30px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    max-width: 100%;
    width: 100%;
    position: relative
}

.modal_view_user .modal-dialog .modal-content .modal-body .user_main .user_inner .user_name h5 {
    font-size: 20px;
    line-height: 20px;
    color: #fff;
    word-wrap: break-word;
    word-wrap: break-word;
    max-width: 130px;
    width: 100%
}

.modal_view_user .modal-dialog .modal-content .modal-body .user_main .user_inner .user_name.border_bg {
    border-bottom: 1px solid #626060;
    padding-bottom: 5px;
    margin-bottom: 5px
}

.modal_view_user .modal-dialog .modal-content .modal-body .user_main .user_inner .user_name .img_wrap img {
    max-width: 30px;
    width: 100%;
    position: absolute;
    top: -5px;
    right: 0
}

.modal_delete .modal-dialog {
    min-width: 469px !important
}

.modal_delete .modal-dialog .modal-content {
    background: #f4f4f4;
    border-radius: 5px
}

.modal_delete .modal-dialog .modal-content .modal-body {
    padding: 0 0 35px
}

.modal_delete .modal-dialog .modal-content .modal-body h2 {
    font-weight: 600;
    font-size: 40px;
    line-height: 57px;
    color: #77cad2;
    text-align: center;
    padding-bottom: 15px
}

.modal_delete .modal-dialog .modal-content .modal-body p {
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    color: #5f6265;
    max-width: 405px;
    width: 100%;
    margin: 0 auto;
    padding-bottom: 50px;
    font-family: Teko
}

.modal_delete .modal-dialog .modal-content .modal-body .btn_wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 20px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.modal_delete .modal-dialog .modal-content .modal-body .btn_wrap .btn {
    font-weight: 300;
    font-size: 25px;
    line-height: 36px;
    color: #fff;
    max-width: 175px;
    width: 100%;
    -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, .25);
    box-shadow: 0 4px 4px rgba(0, 0, 0, .25);
    border-radius: 10px;
    height: 59px
}

.modal_delete .modal-dialog .modal-content .modal-body .btn_wrap .btn.cancel_btn {
    background: #c71a1a
}

.modal_add_user2 .modal-dialog .modal-content .modal-body h2 {
    font-weight: 700;
    font-size: 40px;
    line-height: 57px;
    color: #77cad2;
    text-align: center
}

.modal_add_user2 .modal-dialog .modal-content .modal-body .input_wrap {
    max-width: 353px;
    width: 100%;
    margin: 0 auto 10px
}

.modal_add_user2 .modal-dialog .modal-content .modal-body .input_wrap label {
    font-weight: 600;
    font-size: 30px;
    line-height: 43px;
    color: #fff
}

.modal_add_user2 .modal-dialog .modal-content .modal-body .input_wrap input {
    background: #f5f5f5;
    -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, .25);
    box-shadow: 0 4px 4px rgba(0, 0, 0, .25);
    border-radius: 10px;
    height: 52px;
    border: none;
    padding-left: 10px;
    font-size: 20px;
    line-height: 22px;
    color: #5f6265
}

.modal_add_user2 .modal-dialog .modal-content .modal-body .input_wrap input::-webkit-input-placeholder {
    color: #5f6265
}

.modal_add_user2 .modal-dialog .modal-content .modal-body .input_wrap input:-ms-input-placeholder {
    color: #5f6265
}

.modal_add_user2 .modal-dialog .modal-content .modal-body .input_wrap input::-ms-input-placeholder {
    color: #5f6265
}

.modal_add_user2 .modal-dialog .modal-content .modal-body .input_wrap input::placeholder {
    color: #5f6265
}

.dropdown .btn,
.modal_add_user2 .modal-dialog .modal-content .modal-body .input_wrap .dropdown .btn {
    max-width: 353px;
    width: 100%;
    height: 53px;
    background: #fff;
    border: 1px solid #000;
    border-radius: 5px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-left: 20px;
    padding-right: 15px;
    font-weight: 400;
    font-size: 24px;
    line-height: 24px;
    color: #191a1d
}

.dropdown ul,
.modal_add_user2 .modal-dialog .modal-content .modal-body .input_wrap .dropdown ul,
.modal_monthly_report .modal-dialog .modal-content .modal-body .report_inner .input_wrap .dropdown ul,
.modal_order_365 .modal-dialog .modal-content .modal-body .report_inner .input_wrap .dropdown ul,
.modal_order_detail .modal-dialog .modal-content .modal-body .report_inner .input_wrap .dropdown ul {
    max-width: 353px;
    width: 100%
}

.dropdown ul li a,
.modal_add_user2 .modal-dialog .modal-content .modal-body .input_wrap .dropdown ul li a,
.modal_monthly_report .modal-dialog .modal-content .modal-body .report_inner .input_wrap .dropdown ul li a,
.modal_order_365 .modal-dialog .modal-content .modal-body .report_inner .input_wrap .dropdown ul li a,
.modal_order_detail .modal-dialog .modal-content .modal-body .report_inner .input_wrap .dropdown ul li a {
    font-weight: 400;
    font-size: 20px;
    line-height: 20px;
    padding-top: 10px;
    padding-bottom: 10px
}

.modal_add_user2 .modal-dialog .modal-content .modal-body .confirm_btn,
.update_name .modal-dialog .modal-content .modal-body .confirm_btn {
    background: #77cad2;
    -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, .25);
    box-shadow: 0 4px 4px rgba(0, 0, 0, .25);
    border-radius: 10px;
    max-width: 175px;
    width: 100%;
    margin: 35px auto 0;
    height: 59px;
    font-weight: 600;
    font-size: 25px;
    line-height: 36px;
    color: #191a1d
}

.dropdown .rotate,
.modal_monthly_report .modal-dialog .modal-content .modal-body .report_inner .input_wrap .dropdown .rotate,
.modal_order_365 .modal-dialog .modal-content .modal-body .report_inner .input_wrap .dropdown .rotate,
.modal_order_detail .modal-dialog .modal-content .modal-body .report_inner .input_wrap .dropdown .rotate,
.rotate {
    -webkit-transition: .2s linear;
    transition: .2s linear
}

.dropdown-toggle.show .rotate,
.modal_monthly_report .modal-dialog .modal-content .modal-body .report_inner .input_wrap .dropdown .rotate.down,
.modal_order_365 .modal-dialog .modal-content .modal-body .report_inner .input_wrap .dropdown .rotate.down,
.modal_order_detail .modal-dialog .modal-content .modal-body .report_inner .input_wrap .dropdown .rotate.down,
.rotate.down {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg)
}

.modal_monthly_report .modal-dialog,
.modal_order_365 .modal-dialog,
.modal_order_detail .modal-dialog {
    min-width: 900px !important
}

.modal_Checkout_inbox .modal-dialog .modal-content,
.modal_monthly_report .modal-dialog .modal-content,
.modal_order_365 .modal-dialog .modal-content,
.modal_order_detail .modal-dialog .modal-content,
.modal_payment_sent .modal-dialog .modal-content,
.modal_personal_wallet .modal-dialog .modal-content {
    background: -webkit-gradient(linear, left top, right top, color-stop(3.57%, #191a1d), color-stop(100%, #052f33), to(#052f33));
    background: linear-gradient(90deg, #191a1d 3.57%, #052f33 100%, #052f33 100%);
    border-radius: 10px
}

.modal_Checkout_inbox .modal-dialog .modal-content .modal-header,
.modal_monthly_report .modal-dialog .modal-content .modal-header,
.modal_order_365 .modal-dialog .modal-content .modal-header,
.modal_order_detail .modal-dialog .modal-content .modal-header,
.modal_payment_sent .modal-dialog .modal-content .modal-header,
.modal_personal_wallet .modal-dialog .modal-content .modal-header {
    border: none;
    padding-bottom: 20px
}

.modal_monthly_report .modal-dialog .modal-content .modal-header .img_wrap img,
.modal_order_365 .modal-dialog .modal-content .modal-header .img_wrap img,
.modal_order_detail .modal-dialog .modal-content .modal-header .img_wrap img,
.modal_personal_wallet .modal-dialog .modal-content .modal-header .img_wrap img {
    max-width: 247px;
    width: 100%;
    padding-top: 30px;
    padding-left: 25px
}

.modal_Checkout_inbox .modal-dialog .modal-content .modal-body,
.modal_monthly_report .modal-dialog .modal-content .modal-body,
.modal_order_365 .modal-dialog .modal-content .modal-body,
.modal_order_detail .modal-dialog .modal-content .modal-body,
.modal_payment_sent .modal-dialog .modal-content .modal-body {
    padding: 0 0 100px
}

.modal_monthly_report .modal-dialog .modal-content .modal-body h2,
.modal_order_365 .modal-dialog .modal-content .modal-body h2,
.modal_order_detail .modal-dialog .modal-content .modal-body h2 {
    font-weight: 700;
    font-size: 60px;
    line-height: 86px;
    color: #77cad2;
    text-align: center;
    padding-bottom: 60px
}

.modal_monthly_report .modal-dialog .modal-content .modal-body .order_detail,
.modal_order_365 .modal-dialog .modal-content .modal-body .order_detail,
.modal_order_detail .modal-dialog .modal-content .modal-body .order_detail {
    font-weight: 300;
    font-size: 35px;
    line-height: 35px;
    text-align: center;
    max-width: 350px;
    width: 100%;
    margin: 0 auto 30px;
    color: #fff;
    font-family: Teko
}

.modal_monthly_report .modal-dialog .modal-content .modal-body .order_detail.order__detail,
.modal_order_365 .modal-dialog .modal-content .modal-body .order_detail.order__detail,
.modal_order_detail .modal-dialog .modal-content .modal-body .order_detail.order__detail {
    font-size: 30px;
    line-height: 30px;
    max-width: 100%;
    width: 100%;
    margin-top: 50px
}

.modal_monthly_report .modal-dialog .modal-content .modal-body .payment2_titl,
.modal_order_365 .modal-dialog .modal-content .modal-body .payment2_titl,
.modal_order_detail .modal-dialog .modal-content .modal-body .payment2_titl {
    font-weight: 400;
    font-size: 30px;
    line-height: 30px;
    text-align: center;
    color: #77cad2;
    font-family: Teko
}

.modal_monthly_report .modal-dialog .modal-content .modal-body .report_inner,
.modal_order_365 .modal-dialog .modal-content .modal-body .report_inner,
.modal_order_detail .modal-dialog .modal-content .modal-body .report_inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    max-width: 760px;
    width: 100%;
    margin: 0 auto;
    gap: 20px 0;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}

.modal_monthly_report .modal-dialog .modal-content .modal-body .report_inner.order_inner,
.modal_order_365 .modal-dialog .modal-content .modal-body .report_inner.order_inner,
.modal_order_detail .modal-dialog .modal-content .modal-body .report_inner.order_inner {
    display: unset
}

.modal_monthly_report .modal-dialog .modal-content .modal-body .report_inner .checkbox_wrap,
.modal_order_365 .modal-dialog .modal-content .modal-body .report_inner .checkbox_wrap,
.modal_order_detail .modal-dialog .modal-content .modal-body .report_inner .checkbox_wrap {
    max-width: 353px;
    width: 100%;
    margin-left: 30px
}

.modal_monthly_calender .modal-dialog .modal-content .modal-header .checkbox_wrap .form-group,
.modal_monthly_report .modal-dialog .modal-content .modal-body .report_inner .checkbox_wrap .form-group,
.modal_order_365 .modal-dialog .modal-content .modal-body .report_inner .checkbox_wrap .form-group,
.modal_order_detail .modal-dialog .modal-content .modal-body .report_inner .checkbox_wrap .form-group {
    display: block;
    margin-bottom: 15px
}

.modal_monthly_report .modal-dialog .modal-content .modal-body .report_inner .checkbox_wrap .form-group label,
.modal_order_365 .modal-dialog .modal-content .modal-body .report_inner .checkbox_wrap .form-group label,
.modal_order_detail .modal-dialog .modal-content .modal-body .report_inner .checkbox_wrap .form-group label {
    position: relative;
    cursor: pointer;
    font-weight: 400;
    font-size: 40px;
    line-height: 38px;
    color: #fff;
    font-family: Teko;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start
}

.modal_monthly_report .modal-dialog .modal-content .modal-body .report_inner .checkbox_wrap .form-group label:before,
.modal_order_365 .modal-dialog .modal-content .modal-body .report_inner .checkbox_wrap .form-group label:before,
.modal_order_detail .modal-dialog .modal-content .modal-body .report_inner .checkbox_wrap .form-group label:before {
    content: '';
    -webkit-appearance: none;
    background-color: gray;
    border-radius: 5px;
    -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, .05), inset 0 -15px 10px -12px rgba(0, 0, 0, .05);
    box-shadow: 0 1px 2px rgba(0, 0, 0, .05), inset 0 -15px 10px -12px rgba(0, 0, 0, .05);
    padding: 0;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 5px;
    width: 30px;
    height: 30px
}

.modal_monthly_report .modal-dialog .modal-content .modal-body .report_inner .checkbox_wrap .form-group input:checked+label:after,
.modal_order_365 .modal-dialog .modal-content .modal-body .report_inner .checkbox_wrap .form-group input:checked+label:after,
.modal_order_detail .modal-dialog .modal-content .modal-body .report_inner .checkbox_wrap .form-group input:checked+label:after {
    content: '';
    display: block;
    position: absolute;
    background-image: url("../img/blue_tick.svg");
    background-size: auto;
    background-repeat: no-repeat;
    top: -2px;
    left: 2px;
    width: 30px;
    height: 30px
}

.modal_monthly_report .modal-dialog .modal-content .modal-body .report_inner .input_wrap,
.modal_order_365 .modal-dialog .modal-content .modal-body .report_inner .input_wrap,
.modal_order_detail .modal-dialog .modal-content .modal-body .report_inner .input_wrap {
    max-width: 353px;
    width: 100%;
    margin: 0 auto 20px
}

.modal_monthly_report .modal-dialog .modal-content .modal-body .report_inner .input_wrap .input_calender,
.modal_order_365 .modal-dialog .modal-content .modal-body .report_inner .input_wrap .input_calender,
.modal_order_detail .modal-dialog .modal-content .modal-body .report_inner .input_wrap .input_calender {
    position: relative;
    background: #f5f5f5;
    -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, .25);
    box-shadow: 0 4px 4px rgba(0, 0, 0, .25);
    border-radius: 5px;
    height: 52px;
    border: none;
    padding-left: 10px;
    cursor: pointer
}

.modal_monthly_report .modal-dialog .modal-content .modal-body .report_inner .input_wrap .input_calender.bg_calender,
.modal_order_365 .modal-dialog .modal-content .modal-body .report_inner .input_wrap .input_calender.bg_calender,
.modal_order_detail .modal-dialog .modal-content .modal-body .report_inner .input_wrap .input_calender.bg_calender {
    background-color: #aaa0a0
}

.modal_monthly_report .modal-dialog .modal-content .modal-body .report_inner .input_wrap .input_calender img,
.modal_order_365 .modal-dialog .modal-content .modal-body .report_inner .input_wrap .input_calender img,
.modal_order_detail .modal-dialog .modal-content .modal-body .report_inner .input_wrap .input_calender img {
    top: 50%;
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
    right: 10px;
    position: absolute
}

.modal_monthly_report .modal-dialog .modal-content .modal-body .report_inner .input_wrap .input_calender p,
.modal_order_365 .modal-dialog .modal-content .modal-body .report_inner .input_wrap .input_calender p,
.modal_order_detail .modal-dialog .modal-content .modal-body .report_inner .input_wrap .input_calender p {
    font-weight: 500;
    font-size: 40px;
    line-height: 33px;
    font-family: Teko;
    color: #191a1d;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
    left: 10px;
    position: absolute
}

.modal_monthly_report .modal-dialog .modal-content .modal-body .report_inner .input_wrap .dropdown .btn,
.modal_order_365 .modal-dialog .modal-content .modal-body .report_inner .input_wrap .dropdown .btn,
.modal_order_detail .modal-dialog .modal-content .modal-body .report_inner .input_wrap .dropdown .btn {
    max-width: 353px;
    width: 100%;
    height: 53px;
    background: #fff;
    border: 1px solid #000;
    border-radius: 5px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-left: 20px;
    padding-right: 15px;
    font-weight: 500;
    font-size: 40px;
    line-height: 33px;
    font-family: Teko;
    color: #191a1d
}

.modal_Checkout_inbox .modal-dialog .modal-content .modal-body .confirm_btn,
.modal_monthly_report .modal-dialog .modal-content .modal-body .confirm_btn,
.modal_order_365 .modal-dialog .modal-content .modal-body .confirm_btn,
.modal_order_detail .modal-dialog .modal-content .modal-body .confirm_btn,
.modal_payment_sent .modal-dialog .modal-content .modal-body .confirm_btn,
.modal_personal_wallet .modal-dialog .modal-content .modal-body .confirm_btn {
    background: #77cad2;
    -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, .25);
    box-shadow: 0 4px 4px rgba(0, 0, 0, .25);
    border-radius: 10px;
    max-width: 277px;
    width: 100%;
    margin: 100px auto 0;
    height: 64px;
    font-weight: 600;
    font-size: 30px;
    line-height: 30px;
    color: #191a1d
}

.modal_monthly_report .modal-dialog .modal-content .modal-body .order_btn,
.modal_order_365 .modal-dialog .modal-content .modal-body .order_btn,
.modal_order_detail .modal-dialog .modal-content .modal-body .order_btn {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    max-width: 640px;
    width: 100%;
    margin: 25px auto
}

.modal_monthly_report .modal-dialog .modal-content .modal-body .order_btn .cancel_btn,
.modal_order_365 .modal-dialog .modal-content .modal-body .order_btn .cancel_btn,
.modal_order_detail .modal-dialog .modal-content .modal-body .order_btn .cancel_btn {
    background-color: #dd1f1f
}

.modal_monthly_report .modal-dialog .modal-content .modal-body .order_btn.payment_btn,
.modal_order_365 .modal-dialog .modal-content .modal-body .order_btn.payment_btn,
.modal_order_detail .modal-dialog .modal-content .modal-body .order_btn.payment_btn {
    max-width: 470px;
    width: 100%;
    margin: 0 auto;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: 10px
}

.modal_monthly_report .modal-dialog .modal-content .modal-body .drop_wrap,
.modal_order_365 .modal-dialog .modal-content .modal-body .drop_wrap,
.modal_order_detail .modal-dialog .modal-content .modal-body .drop_wrap {
    max-width: 350px;
    width: 100%;
    margin: 0 auto 20px
}

.modal_monthly_report .modal-dialog .modal-content .modal-body .drop_wrap .dropdown,
.modal_order_365 .modal-dialog .modal-content .modal-body .drop_wrap .dropdown,
.modal_order_detail .modal-dialog .modal-content .modal-body .drop_wrap .dropdown {
    width: 100%;
    background: #fff;
    border: 1px solid #000;
    border-radius: 5px
}

.modal_monthly_report .modal-dialog .modal-content .modal-body .drop_wrap h4,
.modal_order_365 .modal-dialog .modal-content .modal-body .drop_wrap h4,
.modal_order_detail .modal-dialog .modal-content .modal-body .drop_wrap h4 {
    font-weight: 400;
    font-size: 40px;
    line-height: 40px;
    margin: 50px 0;
    color: #fff;
    font-family: Teko
}

.modal_personal_wallet.modal_manage_wallet .modal-dialog {
    min-width: 1050px !important
}

.modal_personal_wallet.modal_manage_wallet .modal-dialog .modal-content .modal-body .wallet_inner {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
    max-width: 510px !important;
    width: 100% !important;
    margin: a auto
}

.modal_personal_wallet.modal_manage_wallet .modal-dialog .modal-content .modal-body .wallet_inner .input_wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    max-width: 100%;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 10px
}

.modal_personal_wallet.modal_manage_wallet .modal-dialog .modal-content .modal-body .wallet_inner .input_wrap label {
    padding-bottom: 0
}

.modal_personal_wallet.modal_manage_wallet .modal-dialog .modal-content .modal-body .wallet_inner .input_wrap input {
    width: 370px;
    color: #b2b6bb;
    font-family: Teko
}

.modal_personal_wallet.modal_manage_wallet .modal-dialog .modal-content .modal-body .wallet_inner .input_wrap input::-webkit-input-placeholder {
    color: #b2b6bb
}

.modal_personal_wallet.modal_manage_wallet .modal-dialog .modal-content .modal-body .wallet_inner .input_wrap input:-ms-input-placeholder {
    color: #b2b6bb
}

.modal_personal_wallet.modal_manage_wallet .modal-dialog .modal-content .modal-body .wallet_inner .input_wrap input::-ms-input-placeholder {
    color: #b2b6bb
}

.modal_personal_wallet.modal_manage_wallet .modal-dialog .modal-content .modal-body .wallet_inner .input_wrap input::placeholder {
    color: #b2b6bb
}

.modal_personal_wallet.modal_manage_wallet .modal-dialog .modal-content .modal-body .wallet_inner .input_wrap .dropdown .btn {
    max-width: 370px !important;
    width: 370px;
    color: #b2b6bb;
    font-family: Teko
}

.modal_personal_wallet.modal_manage_wallet .modal-dialog .modal-content .modal-body .wallet_inner .input_wrap .dropdown ul li a {
    color: #b2b6bb
}

.modal_personal_wallet.modal_manage_wallet .modal-dialog .modal-content .modal-body .text_area {
    max-width: 510px !important;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.modal_personal_wallet.modal_manage_wallet .modal-dialog .modal-content .modal-body .text_area textarea {
    height: 160px;
    max-width: 370px;
    width: 100%;
    font-weight: 400;
    font-size: 26px;
    line-height: 25px;
    color: #b2b6bb;
    font-family: Teko
}

.modal_personal_wallet.modal_manage_wallet .modal-dialog .modal-content .modal-body .text_area textarea::-webkit-input-placeholder {
    color: #b2b6bb
}

.modal_personal_wallet.modal_manage_wallet .modal-dialog .modal-content .modal-body .text_area textarea:-ms-input-placeholder {
    color: #b2b6bb
}

.modal_personal_wallet.modal_manage_wallet .modal-dialog .modal-content .modal-body .text_area textarea::-ms-input-placeholder {
    color: #b2b6bb
}

.modal_personal_wallet.modal_manage_wallet .modal-dialog .modal-content .modal-body .text_area textarea::placeholder {
    color: #b2b6bb
}

.modal_personal_wallet .modal-dialog {
    min-width: 1189px !important
}

.modal_personal_wallet .modal-dialog .modal-content .modal-body {
    padding: 0 0 70px
}

.modal_personal_wallet .modal-dialog .modal-content .modal-body h2 {
    font-weight: 700;
    font-size: 60px;
    line-height: 86px;
    color: #77cad2;
    text-align: center
}

.modal_personal_wallet .modal-dialog .modal-content .modal-body h6 {
    font-weight: 300;
    font-size: 40px;
    line-height: 57px;
    color: #fff;
    padding-bottom: 60px;
    text-align: center;
    font-family: Teko
}

.modal_Checkout_inbox .modal-dialog .modal-content .modal-body label,
.modal_payment_sent .modal-dialog .modal-content .modal-body label,
.modal_personal_wallet .modal-dialog .modal-content .modal-body label {
    font-weight: 400;
    font-size: 30px;
    line-height: 30px;
    color: #fff;
    font-family: Teko;
    padding-bottom: 5px
}

.modal_personal_wallet .modal-dialog .modal-content .modal-body .text_area {
    max-width: 870px;
    width: 100%;
    margin: 0 auto;
    height: 100%
}

.modal_personal_wallet .modal-dialog .modal-content .modal-body .text_area textarea {
    height: 165px;
    font-weight: 400;
    font-size: 26px;
    line-height: 25px;
    color: #b2b6bb;
    font-family: Teko
}

.modal_personal_wallet .modal-dialog .modal-content .modal-body .text_area textarea::-webkit-input-placeholder {
    color: #b2b6bb
}

.modal_personal_wallet .modal-dialog .modal-content .modal-body .text_area textarea:-ms-input-placeholder {
    color: #b2b6bb
}

.modal_personal_wallet .modal-dialog .modal-content .modal-body .text_area textarea::-ms-input-placeholder {
    color: #b2b6bb
}

.modal_personal_wallet .modal-dialog .modal-content .modal-body .text_area textarea::placeholder {
    color: #b2b6bb
}

.modal_Checkout_inbox .modal-dialog .modal-content .modal-body .wallet_inner,
.modal_payment_sent .modal-dialog .modal-content .modal-body .wallet_inner,
.modal_personal_wallet .modal-dialog .modal-content .modal-body .wallet_inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    max-width: 870px;
    width: 100%;
    margin: 0 auto 15px
}

.modal_Checkout_inbox .modal-dialog .modal-content .modal-body .wallet_inner .input_wrap,
.modal_payment_sent .modal-dialog .modal-content .modal-body .wallet_inner .input_wrap,
.modal_personal_wallet .modal-dialog .modal-content .modal-body .wallet_inner .input_wrap {
    max-width: 350px;
    width: 100%
}

.modal_personal_wallet .modal-dialog .modal-content .modal-body .wallet_inner .input_wrap input {
    background: #fff;
    border: 1px solid #000;
    border-radius: 5px;
    padding: 0 0 0 14px;
    height: 52px;
    position: relative;
    font-weight: 400;
    font-size: 26px;
    line-height: 20px;
    color: #b2b6bb;
    font-family: Teko
}

.modal_personal_wallet .modal-dialog .modal-content .modal-body .wallet_inner .input_wrap input::-webkit-input-placeholder {
    color: #b2b6bb;
    position: absolute;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%)
}

.modal_personal_wallet .modal-dialog .modal-content .modal-body .wallet_inner .input_wrap input:-ms-input-placeholder {
    color: #b2b6bb;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%)
}

.modal_personal_wallet .modal-dialog .modal-content .modal-body .wallet_inner .input_wrap input::-ms-input-placeholder {
    color: #b2b6bb;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%)
}

.modal_personal_wallet .modal-dialog .modal-content .modal-body .wallet_inner .input_wrap input::placeholder {
    color: #b2b6bb;
    position: absolute;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%)
}

.modal_Checkout_inbox .modal-dialog,
.modal_payment_sent .modal-dialog {
    min-width: 996px !important
}

.modal_Checkout_inbox .modal-dialog .modal-content .modal-header .img_wrap img,
.modal_payment_sent .modal-dialog .modal-content .modal-header .img_wrap img {
    max-width: 148px;
    width: 100%;
    padding-top: 30px;
    padding-left: 25px
}

.modal_Checkout_inbox .modal-dialog .modal-content .modal-header.payment_head .img_wrap img,
.modal_payment_sent .modal-dialog .modal-content .modal-header.payment_head .img_wrap img {
    max-width: 250px;
    width: 100%;
    padding-top: 30px;
    padding-left: 25px
}

.modal_Checkout_inbox .modal-dialog .modal-content .modal-body h2,
.modal_payment_sent .modal-dialog .modal-content .modal-body h2 {
    font-weight: 700;
    font-size: 40px;
    line-height: 40px;
    color: #77cad2;
    text-align: center;
    margin-bottom: 20px
}

.modal_Checkout_inbox .modal-dialog .modal-content .modal-body h6,
.modal_payment_sent .modal-dialog .modal-content .modal-body h6 {
    font-weight: 300;
    font-size: 30px;
    line-height: 30px;
    color: #fff;
    padding-bottom: 10px;
    text-align: center;
    font-family: Teko
}

.modal_Checkout_inbox .modal-dialog .modal-content .modal-body .text_area,
.modal_payment_sent .modal-dialog .modal-content .modal-body .text_area {
    max-width: 870px;
    width: 100%;
    margin: 0 auto;
    height: 168px
}

.modal_Checkout_inbox .modal-dialog .modal-content .modal-body .text_area textarea,
.modal_payment_sent .modal-dialog .modal-content .modal-body .text_area textarea {
    height: 100%
}

.modal_Checkout_inbox .modal-dialog .modal-content .modal-body .wallet_inner .input_wrap input,
.modal_payment_sent .modal-dialog .modal-content .modal-body .wallet_inner .input_wrap input {
    background: #fff;
    border: 1px solid #000;
    border-radius: 5px;
    padding: 0 0 0 14px;
    height: 52px;
    position: relative;
    font-weight: 400;
    font-size: 26px;
    line-height: 20px
}

.modal_Checkout_inbox .modal-dialog .modal-content .modal-body .wallet_inner .input_wrap input::-webkit-input-placeholder,
.modal_payment_sent .modal-dialog .modal-content .modal-body .wallet_inner .input_wrap input::-webkit-input-placeholder {
    font-weight: 400;
    font-size: 26px;
    line-height: 20px;
    color: #b2b6bb;
    position: absolute;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%)
}

.modal_Checkout_inbox .modal-dialog .modal-content .modal-body .wallet_inner .input_wrap input:-ms-input-placeholder,
.modal_payment_sent .modal-dialog .modal-content .modal-body .wallet_inner .input_wrap input:-ms-input-placeholder {
    font-weight: 400;
    font-size: 26px;
    line-height: 20px;
    color: #b2b6bb;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%)
}

.modal_Checkout_inbox .modal-dialog .modal-content .modal-body .wallet_inner .input_wrap input::-ms-input-placeholder,
.modal_payment_sent .modal-dialog .modal-content .modal-body .wallet_inner .input_wrap input::-ms-input-placeholder {
    font-weight: 400;
    font-size: 26px;
    line-height: 20px;
    color: #b2b6bb;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%)
}

.modal_Checkout_inbox .modal-dialog .modal-content .modal-body .wallet_inner .input_wrap input::placeholder,
.modal_payment_sent .modal-dialog .modal-content .modal-body .wallet_inner .input_wrap input::placeholder {
    font-weight: 400;
    font-size: 26px;
    line-height: 20px;
    color: #b2b6bb;
    position: absolute;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%)
}

.modal_Checkout_inbox .modal-dialog .modal-content .modal-body .go_dash,
.modal_payment_sent .modal-dialog .modal-content .modal-body .go_dash {
    max-width: 470px;
    width: 100%;
    margin: 60px auto 0;
    height: 65px;
    background: #77cad2;
    -webkit-box-shadow: 0 2px 4px #3a3839;
    box-shadow: 0 2px 4px #3a3839;
    border-radius: 5px;
    font-weight: 400;
    font-size: 40px;
    line-height: 57px;
    color: #000;
    font-family: Teko
}

.modal_monthly_calender .modal-dialog,
.modal_year_calender .modal-dialog {
    min-width: 263px !important;
    max-width: 263px !important;
    margin: 0 auto
}

.modal_monthly_calender .modal-dialog .modal-content,
.modal_year_calender .modal-dialog .modal-content {
    background: #fff;
    border-radius: 5px
}

.modal_monthly_calender .modal-dialog .modal-content .modal-header,
.modal_year_calender .modal-dialog .modal-content .modal-header {
    border: none;
    padding-bottom: 12px
}

.modal_monthly_calender .modal-dialog .modal-content .modal-header h5,
.modal_year_calender .modal-dialog .modal-content .modal-header h5 {
    font-weight: 400;
    font-size: 20px;
    line-height: 16px;
    color: #1b1b1b
}

.modal_monthly_calender .modal-dialog .modal-content .modal-header .checkbox_wrap .form-group label {
    position: relative;
    cursor: pointer;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    color: #000;
    font-family: Teko;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start
}

.modal_monthly_calender .modal-dialog .modal-content .modal-header .checkbox_wrap .form-group label:before {
    content: '';
    -webkit-appearance: none;
    background: #77cad2;
    border-radius: 3px;
    padding: 0;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 5px;
    width: 15px;
    height: 15px
}

.modal_monthly_calender .modal-dialog .modal-content .modal-header .checkbox_wrap .form-group input:checked+label:after {
    content: '';
    display: block;
    position: absolute;
    background-image: url("../img/tick.svg");
    background-size: cover;
    background-repeat: no-repeat;
    top: -3px;
    left: -1px;
    width: 18px;
    height: 18px
}

.modal_monthly_calender .modal-dialog .modal-content .modal-body {
    padding: 0 0 10px
}

.modal_monthly_calender .modal-dialog .modal-content .modal-body .months_wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    max-width: 250px;
    gap: 16px 0;
    width: 100%;
    margin: 0 auto
}

.modal_monthly_calender .modal-dialog .modal-content .modal-body .months_wrap h3,
.modal_year_calender .modal-dialog .modal-content .modal-body .months_wrap h3 {
    font-weight: 500;
    font-size: 25px;
    line-height: 16px;
    text-align: center;
    color: #1b1b1b;
    max-width: 70px;
    width: 100%;
    cursor: pointer
}

.modal_monthly_calender .modal-dialog .modal-content .modal-body .months_wrap .highlighted,
.modal_year_calender .modal-dialog .modal-content .modal-body .months_wrap .highlighted {
    background: #77cad2;
    border-radius: 16px;
    padding: 6px 0
}

.modal_monthly_calender .modal-dialog .modal-content .modal-body .confirm_btn,
.modal_year_calender .modal-dialog .modal-content .modal-body .confirm_btn {
    background: #77cad2;
    border-radius: 5px;
    max-width: 92px;
    width: 100%;
    margin: 30px auto 0;
    height: 26px;
    font-weight: 400;
    font-size: 20px;
    line-height: 20px;
    color: #000
}

.modal_year_calender .modal-dialog .modal-content .modal-body {
    padding: 0 0 40px
}

.modal_year_calender .modal-dialog .modal-content .modal-body .months_wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    max-width: 250px;
    gap: 16px;
    width: 100%;
    margin: 0 auto
}

.modal_setting .modal-dialog .modal-content .modal-body .input_wrap .coming_soon {
    position: absolute;
    right: -28%;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
    cursor: pointer
}

.modal_setting .modal-dialog .modal-content .modal-body .input_wrap .coming_soon p {
    font-family: Teko;
    font-weight: 300;
    font-size: 20px;
    line-height: 20px;
    color: #d9d9d9;
    text-shadow: 0 4px 4px rgba(0, 0, 0, .25)
}

.modal_setting .modal-dialog .modal-content .modal-body .input_wrap input {
    background: #191a1d;
    -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, .25);
    box-shadow: 0 4px 4px rgba(0, 0, 0, .25);
    height: 68px;
    border: none;
    cursor: pointer;
    padding: 0;
    color: #fff
}

.update_name .modal-dialog .modal-content .modal-body {
    padding: 0 0 20px
}

.update_name .modal-dialog .modal-content .modal-body h2 {
    font-weight: 700;
    font-size: 40px;
    line-height: 57px;
    color: #77cad2;
    text-align: center;
    margin-bottom: 24px
}

.update_name .modal-dialog .modal-content .modal-body .input_wrap {
    max-width: 353px;
    width: 100%;
    margin: 0 auto 10px;
    text-align: center
}

.update_name .modal-dialog .modal-content .modal-body .input_wrap label {
    font-weight: 600;
    font-size: 24px;
    line-height: 24px;
    color: #fff;
    margin-bottom: 10px
}

.update_name .modal-dialog .modal-content .modal-body .input_wrap input {
    background: #f5f5f5;
    -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, .25);
    box-shadow: 0 4px 4px rgba(0, 0, 0, .25);
    border-radius: 10px;
    height: 52px;
    border: none;
    padding-left: 10px;
    font-size: 20px;
    line-height: 22px;
    color: #aaa0a0
}

.update_name .modal-dialog .modal-content .modal-body .input_wrap input::-webkit-input-placeholder {
    color: #aaa0a0
}

.update_name .modal-dialog .modal-content .modal-body .input_wrap input:-ms-input-placeholder {
    color: #aaa0a0
}

.update_name .modal-dialog .modal-content .modal-body .input_wrap input::-ms-input-placeholder {
    color: #aaa0a0
}

.update_name .modal-dialog .modal-content .modal-body .input_wrap input::placeholder {
    color: #aaa0a0
}

.total_payment .payment_inner .payment_left .drop_wrap select.customselect__open {
    background-image: url("../img/drop_left.svg");
    background-repeat: no-repeat;
    background-size: 20px
}

.contractor_wrap .contractor_inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    background: #5f6265;
    border-radius: 5px;
    padding: 35px 0;
    margin-bottom: 25px
}

.dewq_btnz,
.sales_body .crypto_wrap,
.sales_body .sale_detail .account_procedure {
    display: -webkit-box;
    display: -ms-flexbox
}

.contractor_wrap .contractor_inner .contractor_content .contractor_title {
    font-weight: 400;
    font-size: 40px;
    line-height: 40px;
    color: #fff;
    font-family: Teko;
    margin-bottom: 30px
}

.contractor_wrap .contractor_inner .contractor_content .contractor_title a {
    font-weight: 400;
    font-size: 20px;
    line-height: 20px;
    color: #77cad2;
    font-family: Teko;
    padding-left: 10px
}

.contractor_wrap .contractor_wallet {
    background: linear-gradient(269.77deg, #585d62 .1%, rgba(95, 98, 101, .656647) 98.07%, rgba(95, 98, 101, .656647) 98.07%, rgba(95, 98, 101, .656647) 98.07%);
    -webkit-box-shadow: 8px 10px 4px rgba(0, 0, 0, .25);
    box-shadow: 8px 10px 4px rgba(0, 0, 0, .25);
    border-radius: 5px;
    padding-top: 60px;
    padding-bottom: 100px;
    margin-bottom: 100px
}

.contractor_wrap .contractor_wallet .nav-tabs {
    gap: 50px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border: none
}

.contractor_wrap .contractor_wallet .nav-tabs .nav-item {
    max-width: 548px;
    width: 100%;
    height: 80px
}

.contractor_wrap .contractor_wallet .nav-tabs .nav-item .nav-link {
    background: grey;
    border-radius: 10px;
    width: 100%;
    height: 100%;
    padding: 0;
    font-weight: 400;
    font-size: 40px;
    line-height: 40px;
    font-family: Teko;
    color: #3a3839;
    border: none
}

.contractor_wrap .contractor_wallet .tab-content {
    padding-top: 30px
}

.contractor_wrap .contractor_wallet .tab-content .add_btn {
    max-width: 160px;
    width: 100%;
    height: 50px;
    background: #fff;
    -webkit-box-shadow: 0 2px 2px #f5f5f5;
    box-shadow: 0 2px 2px #f5f5f5;
    border-radius: 5px;
    gap: 3px;
    font-family: Teko;
    font-weight: 400;
    font-size: 28px;
    line-height: 20px;
    color: #000;
    margin: 0 auto 40px
}

.chart_content .drop_wraps {
    margin-top: 30px
}

.chart_content .chart_title {
    font-weight: 700;
    font-size: 60px;
    line-height: 60px;
    color: #fff;
    margin-bottom: 20px
}

.chart_content .dropdown .btn {
    max-width: 325px;
    margin: 0 auto;
    height: 40px;
    font-family: Teko
}

.chart_content .dropdown .btn img {
    width: 17px
}

.chart_content .dropdown ul {
    max-width: 325px;
    inset: unset !important;
    position: unset !important;
    -webkit-transform: unset !important;
    transform: unset !important;
    margin: 5px auto 0 !important
}

.chart_content .dropdown ul a {
    font-family: Teko
}

.graph_chat {
    padding: 90px 60px;
    background: rgba(25, 26, 29, .93);
    border-radius: 5px;
    margin-top: 60px;
    margin-bottom: 100px
}

.dewq_btnz {
    margin-left: 50px;
    height: 100%;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: space-evenly;
    -ms-flex-pack: space-evenly;
    justify-content: space-evenly
}

.dewq_left img {
    max-width: 180px
}

.dewq_left .nav_inner {
    height: 190px !important
}

.sales_body .create_btn {
    max-width: 220px;
    width: 100%;
    height: 35px;
    background: #77cad2;
    -webkit-box-shadow: 0 8px 16px rgba(255, 151, 29, .15);
    box-shadow: 0 8px 16px rgba(255, 151, 29, .15);
    border-radius: 4px;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    text-transform: uppercase;
    color: #262627
}

.sales_body .create_btn.learn_btn {
    height: 50px
}

.sales_body .hero_wrap .hero_detail {
    padding-bottom: 40px
}

.sales_body .hero_wrap .hero_detail h1 {
    font-weight: 700;
    font-size: 70px;
    line-height: 100px;
    text-align: center;
    text-transform: uppercase;
    color: #77cad2
}

.sales_body .crypto_wrap {
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    gap: 40px;
    -webkit-transform: translate(0, 35px);
    transform: translate(0, 35px)
}

.sales_body .crypto_wrap .crypto_img {
    max-width: 57px;
    width: 100%
}

.sales_body .client_says .client_box .quote_img img,
.sales_body .client_says .left_img img,
.sales_body .client_says .right_img img,
.sales_body .crypto_wrap .crypto_img img,
.sales_body .sale_detail .sale_img img,
.svgBellIcon {
    width: 100%;
    height: 100%
}

.sales_body .crypto_wrap .crypto_btn {
    max-width: 240px;
    width: 100%;
    height: 70px;
    background: #202022;
    -webkit-box-shadow: 0 3.06542px 3.06542px rgba(0, 0, 0, .1);
    box-shadow: 0 3.06542px 3.06542px rgba(0, 0, 0, .1);
    border-radius: 6px;
    gap: 12px
}

.sales_body .crypto_wrap .crypto_btn span {
    font-weight: 500;
    font-size: 17px;
    line-height: 16px;
    color: #fff
}

.sales_body .sale_detail.why_wrap {
    background-image: url("../img/sales_page/why_us_bg.png");
    padding-top: 150px;
    padding-bottom: 80px
}

.sales_body .sale_detail::before {
    content: "";
    background: #19191a;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    z-index: -1
}

.sales_body .sale_detail .sale_img {
    max-width: 510px;
    width: 100%;
    margin-left: auto
}

.sales_body .sale_detail .sale_title {
    font-weight: 700;
    font-size: 38px;
    line-height: 54px;
    text-transform: uppercase;
    color: #77cad2;
    margin-bottom: 20px
}

.sales_body .sale_detail .sale_text {
    font-weight: 300;
    font-size: 16px;
    line-height: 16px;
    text-transform: capitalize;
    color: #fff;
    font-family: 'Open Sans';
    max-width: 615px;
    width: 100%
}

.sales_body .sale_detail .why_us .crypto_btn {
    height: auto;
    background-color: unset;
    max-width: 380px;
    width: 100%
}

.sales_body .sale_detail .why_us .crypto_img {
    max-width: 80px
}

.sales_body .sale_detail .why_us .why_inner p {
    font-weight: 700;
    font-size: 40px;
    line-height: 40px;
    color: #fff;
    font-family: Teko
}

.sales_body .sale_detail .why_us .why_inner span {
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: .01em;
    color: #e0e0e0;
    font-family: Teko
}

.sales_body .sale_detail .account_procedure {
    padding-top: 60px;
    max-width: 650px;
    width: 100%;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    display: flex;
    gap: 30px 50px
}

.sales_body .sale_detail .account_procedure .procedure_inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 20px
}

.sales_body .sale_detail .account_procedure .num {
    width: 80px;
    height: 80px;
    background: rgba(255, 255, 255, .1);
    font-weight: 900;
    font-size: 40px;
    line-height: 40px;
    color: #77cad2;
    border-radius: 50%
}

.sales_body .sale_detail .account_procedure p {
    font-weight: 500;
    font-size: 18px;
    line-height: 18px;
    color: #fff;
    font-family: Teko
}

.sales_body .client_says {
    padding-top: 40px;
    padding-bottom: 80px
}

.sales_body .client_says .client_inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 40px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding-top: 40px;
    position: relative
}

.sales_body .client_says .left_img {
    max-width: 65px;
    width: 100%;
    position: absolute;
    left: -40px;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%)
}

.sales_body .client_says .right_img {
    max-width: 65px;
    width: 100%;
    position: absolute;
    right: -40px;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%)
}

.sales_body .client_says .client_box {
    background: #202022;
    border: 2px solid #3f3e3d;
    border-radius: 12px;
    padding: 65px;
    max-width: 350px;
    width: 100%
}

.sales_body .client_says .client_box .quote_img {
    max-width: 80px;
    width: 100%;
    margin-bottom: 20px
}

.sales_body .client_says .client_box .box_detail {
    font-family: Teko;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    text-align: center;
    color: #fff;
    padding-bottom: 70px
}

.sales_body .client_says .client_box .box_footer img {
    max-width: 48px;
    width: 100%;
    margin-bottom: 10px
}

.sales_body .client_says .client_box .box_footer .box_title {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #fff
}

.sales_body .client_says .client_box .box_footer .box_text {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #c4c4c4
}

.sales_body .contact_us {
    padding-top: 70px;
    background-image: url("../img/sales_page/contact_us.png");
    background-size: contain;
    background-repeat: no-repeat;
    padding-bottom: 150px
}

.sales_body .defi_img,
.sales_body .footer_wrapper {
    background-color: #19191a
}

.terms_policy .modal-content {
    background: -webkit-gradient(linear, left top, right top, color-stop(3.57%, #191a1d), color-stop(100%, #052f33), to(#052f33));
    background: linear-gradient(90deg, #191a1d 3.57%, #052f33 100%, #052f33 100%);
    border-radius: 5px
}

.terms_policy .modal-body {
    color: #f5f5f5;
    font-family: Teko;
    background: rgba(217, 217, 217, .64);
    height: 350px
}

.terms_policy .modal-body .terms_details {
    color: #fff;
    font-size: 16px;
    line-height: 20px;
    text-transform: capitalize
}

.terms_policy .modal-body .terms_details a {
    color: #fff;
    text-decoration: underline
}

.terms_policy .modal-body .terms_details .fw_500 {
    font-weight: 500
}

.terms_policy .modal-body .terms_details .fw_700 {
    font-weight: 700;
    font-family: Teko
}

.terms_policy .modal-body .terms_details .fs_it {
    font-style: italic
}

.terms_policy .modal-body::-webkit-scrollbar {
    width: 5px
}

.terms_policy .modal-body::-webkit-scrollbar-track {
    background: unset
}

.terms_policy .modal-body::-webkit-scrollbar-thumb {
    background: #d9d9d9;
    border-radius: 8px
}

.terms_policy .modal-body::-webkit-scrollbar-thumb:hover {
    background: #812d68
}

.terms_policy .modal-title {
    color: #f5f5f5;
    text-align: center;
    width: 100%;
    font-weight: 600
}

.terms_policy .modal-header img {
    width: 10px;
    cursor: pointer
}

@media (max-width:1199.98px) {

    .manage_wallet .wallet_title,
    .manage_wallet h3,
    .service_wrap h1 {
        margin-bottom: 30px
    }

    .dewq_left img {
        max-width: 150px !important
    }

    .custom-pagi-class {
        margin-top: -22px;
        margin-left: 46px
    }

    .svgBellIcon {
        height: 36px;
        width: 36px
    }

    .manage_wallet .active_days,
    .manage_wallet .wallet_title.wallet__title {
        max-width: 540px
    }

    .manage_wallet .table_wrap.transaction_tbl td {
        line-height: 21px;
        vertical-align: middle
    }

    .modal_personal_wallet.modal_manage_wallet .modal-dialog {
        min-width: 920px !important
    }

    .nav_wrapper .nav_inner .nav_right .btn.logout_btn.dash_btn {
        margin-left: 20px
    }

    .nav_wrapper .nav_inner .nav_right .btn.logout_btn {
        width: 130px;
        height: 45px
    }

    .manage_wallet .wallet_title h1 {
        font-weight: 700;
        font-size: 50px;
        line-height: 50px
    }

    .manage_wallet .wallet_title img {
        max-width: 50px
    }

    .manage_wallet h3 {
        font-size: 40px;
        line-height: 40px
    }

    .manage_wallet .table_wrap th {
        font-size: 32px;
        line-height: 30px
    }

    .manage_wallet .table_wrap td {
        font-size: 26px;
        line-height: 26px;
        padding: 20px 0
    }

    .manage_wallet .table_wrap .table {
        margin-bottom: 40px
    }

    .manage_wallet .wallet_label .add_wallet {
        max-width: 170px;
        height: 45px;
        gap: 5px
    }

    .manage_wallet {
        height: 100vh
    }

    .manage_wallet .table_wrap .table.second_tbl {
        padding-bottom: 0
    }

    .index_hero {
        height: calc(100vh - 126px)
    }

    .index_hero .container {
        height: 100%
    }

    .index_hero .hero_img img,
    .sales_body .sale_detail.why_wrap .why_us {
        -webkit-transform: unset;
        transform: unset
    }

    .index_hero .hero__main {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center
    }

    .index_hero .hero_img {
        position: unset;
        margin: 0 auto;
        -webkit-transform: unset;
        transform: unset;
        max-width: 700px
    }

    .why_choose .why_choose_main {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        gap: 50px
    }

    .why_choose {
        padding-top: 100px;
        padding-bottom: 100px
    }

    .contact_wrap,
    .service_wrap {
        padding: 90px 0
    }

    .service_wrap .service_inner {
        max-width: 660px
    }

    .price_wrap {
        padding: 80px 0
    }

    .service_wrap h1,
    .team_wrap h2,
    .total_payment .payment_inner h1 {
        font-size: 50px;
        line-height: 50px
    }

    .contractor_wrap .contractor_inner .contractor_content .contractor_title span,
    .contractor_wrap .contractor_wallet .nav-tabs .nav-item .nav-link,
    .hero_wrapper .crypto_account .crypto_left h2,
    .price_wrap p {
        font-size: 30px;
        line-height: 30px
    }

    .price_wrap .price_inner .price_box,
    .price_wrap .price_inner .price_box .price__box {
        padding: 30px
    }

    .price_wrap .price_inner .price_box .account_btn {
        max-width: 240px;
        height: 50px;
        font-size: 30px;
        line-height: 30px
    }

    .price_wrap .price_inner .price_box h1 {
        font-size: 60px;
        line-height: 60px;
        margin: 30px 0
    }

    .index_nav .index_tabs li a,
    .service_wrap .service_inner .service_box p {
        font-size: 25px;
        line-height: 25px
    }

    .team_wrap {
        padding-bottom: 90px
    }

    .contact_wrap .contact_inner {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-orient: vertical;
        -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
        flex-direction: column-reverse
    }

    .contact_wrap .live_wrap {
        max-width: inherit
    }

    .index_nav .index_tabs li a {
        font-weight: 400
    }

    .team_wrap .team_inner .team_box .img_wrap img {
        margin-bottom: 30px;
        max-width: 280px;
        width: 100%
    }

    .price_wrap .price_inner {
        max-width: 880px
    }

    .price_wrap .price_inner .price_box .buynow_btn {
        max-width: 180px;
        height: 45px;
        font-size: 25px;
        line-height: 25px;
        margin-top: 25px
    }

    .service_wrap .service_inner .service_box .img_wrap img {
        max-width: 100px
    }

    .index_hero .hero__main .hero_content {
        height: auto;
        margin: 0 auto
    }

    .hero_wrapper .wallet_balance .dash_main .dash_content .accordion_head {
        max-width: 830px
    }

    .hero_wrapper .wallet_balance .wallet_main .dash_content {
        max-width: 970px
    }

    .hero_wrapper .wallet_balance .wallet_main .dash_content .accordion-button::after {
        margin-left: 5px
    }

    .hero_wrapper .wallet_balance .wallet_main .dash_content .accordion-button {
        padding-right: 5px
    }

    .modal_order_365 .modal-dialog {
        min-width: 690px !important;
        overflow: scroll
    }

    .modal_order_detail .modal-dialog,
    .modal_payment_sent .modal-dialog {
        min-width: 900px !important
    }

    .hero_wrapper .crypto_account .payment2_inner h1 {
        font-size: 40px;
        line-height: 40px;
        padding-top: 15px !important
    }

    .hero_wrapper .need_review {
        padding: 20px 20px 150px
    }

    .hero_wrapper .need_review .review_content.sub_wrap {
        max-width: 670px;
        padding-right: 0;
        padding-left: 117px
    }

    .hero_wrapper .need_review .review_head {
        max-width: 790px
    }

    .total_payment {
        padding-bottom: 120px
    }

    .total_payment .payment_inner .payment_left h3 {
        font-size: 40px;
        line-height: 40px;
        margin-bottom: 15px
    }

    .total_payment .payment_inner .send_payment {
        max-width: 410px;
        height: 55px;
        font-size: 40px;
        line-height: 40px;
        margin-top: 30px;
        margin-left: auto
    }

    .total_payment .payment_inner {
        -webkit-box-align: end;
        -ms-flex-align: end;
        align-items: end
    }

    .hero_wrapper .need_review .review_content {
        padding: 5px 20px;
        max-width: 840px
    }

    .hero_wrapper .crypto_account .payment2_inner h3 {
        font-size: 35px;
        line-height: 35px;
        left: 10px;
        top: 10px
    }

    .hero_wrapper .crypto_account .payment2_inner p {
        font-size: 35px;
        line-height: 35px
    }

    .hero_wrapper.payment2_main {
        margin-bottom: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center
    }

    .hero_wrapper .crypto_account .payment2_inner {
        padding-top: 0;
        padding-bottom: 0
    }

    .wallet_balance .wallet_main .expired_sec .expired_content {
        padding-top: 25px;
        padding-bottom: 50px
    }

    .modal_Checkout_inbox .modal-dialog {
        min-width: 910px !important
    }

    .modal_monthly_report .modal-dialog,
    .modal_personal_wallet .modal-dialog {
        min-width: 930px !important
    }

    .hero_wrapper .crypto_account .crypto_left p {
        font-size: 30px;
        line-height: 40px
    }

    .hero_wrapper .crypto_account .crypto_left h1 {
        font-size: 40px;
        line-height: 50px
    }

    .hero_wrapper .crypto_account .crypto_right .crypto_btn a {
        font-size: 16px;
        line-height: 16px;
        gap: 5px
    }

    .hero_wrapper .crypto_account .crypto_right .crypto_btn img {
        width: 25px
    }

    .hero_wrapper .crypto_account .crypto_right .crypto_btn.monthly {
        max-width: 140px
    }

    .hero_wrapper .crypto_account .crypto_right .crypto_btn.personal,
    .hero_wrapper .crypto_account .crypto_right .crypto_btn.wallet {
        max-width: 180px
    }

    .hero_wrapper .crypto_account .crypto_right {
        max-width: 340px;
        gap: 15px
    }

    .card-custom {
        box-shadow: 0 5px 10px rgba(90, 116, 148, .3);
        background-color: #5f6265;
        border-radius: 8px;
        font-size: 13px;
        font-weight: 500;
        letter-spacing: .3px;
        margin: -20vh auto auto;
        width: 40vw;
        height: 80%;
        color: #475f7b;
        overflow: hidden
    }

    .chart,
    .chart.chart-image {
        width: 100%
    }

    .hero_wrapper .wallet_balance .wallet_main {
        padding-top: 20px;
        padding-bottom: 30px;
        margin-bottom: 50px
    }

    .wallet_balance .wallet_main .wallet_header .wallet_title img {
        width: 20px
    }

    .footer_wrapper .footer_inner .social_icons,
    .wallet_balance .wallet_main .wallet_header .wallet_title {
        gap: 10px
    }

    .hero_wrapper .wallet_balance .wallet_main .wallet_header .wallet_title h3 {
        font-size: 32px;
        line-height: 26px
    }

    .hero_wrapper .crypto_account .crypto_right .crypto_btn {
        height: 45px
    }

    .hero_wrapper .crypto_account {
        padding: 30px 15px
    }

    .wallet_balance .wallet_main .wallet_content .accordion_head img {
        width: 70px
    }

    .wallet_balance .wallet_main .wallet_content .accordion_head {
        max-width: 800px
    }

    .wallet_balance .wallet_main .wallet_content {
        padding-top: 15px;
        max-width: 900px
    }

    .nav_wrapper .nav_inner .nav_right .dropdown .dropdown-menu {
        left: 0 !important;
        top: 34px !important;
        width: 380px
    }

    .nav_wrapper .nav_inner .nav_right .dropdown .notify_arrow {
        top: -25px
    }

    .nav_wrapper .nav_inner .nav_right .dropdown .dropdown-menu li .wallet_detail .wallet_btn {
        max-width: 150px;
        font-size: 20px;
        line-height: 20px
    }

    .nav_wrapper .nav_inner .nav_right .btn.create_btn {
        width: 170px;
        height: 50px
    }

    .nav_wrapper .nav_inner .nav_right .btn.sign_btn {
        width: 100px;
        height: 50px
    }

    .nav_wrapper .nav_inner .nav_right .btn {
        font-weight: 400;
        font-size: 20px;
        line-height: 18px
    }

    .hero_wrapper .hero_inner {
        padding-left: 20px;
        padding-top: 50px;
        padding-bottom: 0;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        gap: 30px;
        -webkit-box-orient: vertical;
        -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
        flex-direction: column-reverse;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center
    }

    .hero_wrapper .hero_inner .hero_left .hero_title {
        font-weight: 700;
        line-height: 40px
    }

    .hero_wrapper .hero_inner .hero_left .hero_detail {
        line-height: 20px;
        margin-bottom: 10px
    }

    .hero_wrapper .hero_inner .hero_left .input_wrap input {
        font-size: 18px;
        line-height: 24px;
        padding-left: 20px
    }

    .hero_wrapper .hero_inner .hero_left .input_wrap .img_wrap {
        right: 20px
    }

    .hero_wrapper .hero_inner .hero_left .navtab_wrap {
        margin-bottom: 20px;
        margin-top: 40px
    }

    .hero_wrapper .hero_inner .hero_left .create_btn {
        height: 50px;
        font-size: 20px;
        line-height: 20px
    }

    .hero_wrapper .hero_inner .hero_left .already_login {
        font-size: 22px;
        line-height: 26px
    }

    .hero_wrapper {
        margin-bottom: -1px;
        min-height: calc(100vh - 275px)
    }

    .contractor_wrap .contractor_inner,
    .footer_wrapper {
        padding: 25px 0
    }

    .footer_wrapper .footer_inner ul li {
        font-size: 20px;
        line-height: 20px
    }

    .footer_wrapper .footer_inner ul {
        max-width: 780px
    }

    .footer_wrapper .footer_inner .social_icons img {
        max-width: 35px;
        width: 100%
    }

    .hero_wrapper .hero_inner .hero_left {
        max-width: 600px
    }

    .bg_body.bg_multipleAccount {
        padding-top: 30px
    }

    .hero_wrapper .multiple_inner {
        padding: 20px 20px 100px;
        max-width: 900px;
        width: 100%;
        margin: 0 auto
    }

    .hero_wrapper .multiple_inner h2 {
        font-size: 36px;
        line-height: 35px;
        margin-bottom: 10px
    }

    .hero_wrapper .multiple_inner .your_account h3 {
        font-size: 33px;
        line-height: 33px
    }

    .hero_wrapper .multiple_inner .your_account p {
        font-size: 28px;
        line-height: 36px;
        margin-bottom: 15px
    }

    .hero_wrapper .multiple_inner .your_account .input_wrap input {
        font-size: 28px;
        line-height: 40px
    }

    .hero_wrapper .multiple_inner .account_main .account_inner h3 {
        font-size: 28px;
        line-height: 28px;
        margin-bottom: 20px
    }

    .hero_wrapper .multiple_inner .your_account,
    .sales_body .client_says .client_box .box_detail {
        padding-bottom: 40px
    }

    .nav_wrapper .nav_inner .nav_right .right_inner {
        width: 450px
    }

    .nav_wrapper .nav_inner .nav_right .right_inner .nav_icons {
        gap: 15px
    }

    .nav_wrapper .nav_inner .nav_right .right_inner .nav_icons .img_wrap img {
        max-width: 45px;
        width: 100%
    }

    .defi_img .container .person_img img {
        max-width: 260px
    }

    .defi_img .container .person_img {
        right: 30px
    }

    .contractor_wrap .contractor_inner .contractor_content .contractor_title {
        font-size: 35px;
        line-height: 35px;
        margin-bottom: 15px
    }

    .contractor_wrap .contractor_inner .contractor_content .contractor_title a {
        padding-left: 7px
    }

    .contractor_wrap .contractor_wallet .nav-tabs {
        gap: 25px
    }

    .contractor_wrap .contractor_wallet .nav-tabs .nav-item {
        max-width: 380px;
        height: 65px
    }

    .contractor_wrap .contractor_wallet .tab-content .table_contractor_account table {
        max-width: 920px
    }

    .contractor_wrap .contractor_wallet .tab-content .table_contractor_account table tr.border_bottom::after {
        bottom: 25px;
        width: calc(100% + 81px);
        left: -41px
    }

    .contractor_wrap .contractor_wallet .tab-content .table_contractor_account table tr.border_bottom {
        height: 80px
    }

    .contractor_wrap .contractor_wallet .tab-content .table_contractor_account.table_contractor_account2 table tr.border_bottom::after {
        content: "";
        width: calc(100% + 174px);
        left: -87px
    }

    .contractor_wrap .contractor_wallet .tab-content .table_contractor_account.table_contractor_account2 table img {
        width: 40px
    }

    .sales_body .hero_wrap .hero_inner {
        padding-top: 70px
    }

    .sales_body .hero_wrap .hero_title::after,
    .sales_body .hero_wrap .hero_title::before {
        width: 200px
    }

    .sales_body .hero_wrap .hero_title {
        max-width: 700px
    }

    .sales_body .hero_wrap .hero_detail h1 {
        font-size: 55px;
        line-height: 85px
    }

    .sales_body .sale_detail.why_wrap {
        padding-top: 90px
    }

    .sales_body .sale_detail .why_us .crypto_img {
        max-width: 65px
    }

    .sales_body .sale_detail .why_us .why_inner p {
        font-size: 35px
    }

    .sales_body .sale_detail .account_procedure .num {
        width: 70px;
        height: 70px
    }

    .sales_body .client_says .client_box {
        padding: 35px;
        max-width: 260px
    }

    .sales_body .client_says .right_img {
        max-width: 45px;
        right: 5px
    }

    .sales_body .client_says .left_img {
        max-width: 45px;
        left: 5px
    }

    .sales_body .client_says .client_box .quote_img {
        max-width: 70px
    }
}

span.red-error,
span.red-error.chnagPass,
span.red-error.contact {
    font-weight: 400 !important;
    font-size: 20px
}

@media (max-width:991.98px) {
    .section-faqs .main-faqs .main-content .intro-content .flat-question .accordion .accordion-toggle .toggle-content {
        padding: 0 0 0 44px
    }

    .total_payment .payment_inner {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap
    }

    .dewq_left img {
        max-width: 100px !important
    }

    .defi_img.defi_dewq .img_wrap img,
    .manage_wallet .active_days .active_inner {
        max-width: 110px
    }

    .dewq_btnz {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        gap: 10px;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center
    }

    .dewq_btnz .btn.logout_btn {
        width: 125px !important
    }

    .ul_open {
        width: 188px;
        padding: 5px
    }

    .ul_open li {
        margin: 0 5px
    }

    .ul_open .popover__menu {
        min-width: 65px;
        top: 35px;
        right: 13px
    }

    .ul_open .popover__menu.whale_poper {
        right: -18px
    }

    .ul_open .popover__menu:after {
        border-width: 8px
    }

    .ul_open img {
        width: 20px !important
    }

    .ul_open .popover__menu-item button {
        padding: 5px;
        font-size: 12px;
        line-height: 14px
    }

    .nav_wrapper .nav_inner.nav_inner {
        height: 75px
    }

    .modal_personal_wallet .modal-dialog .modal-content .modal-body .wallet_inner .input_wrap input {
        font-size: 22px
    }

    .nav_wrapper .nav_inner .nav_right .right_inner .nav_icons .img_wrap {
        height: auto
    }

    .manage_wallet .wallet_title {
        margin-bottom: 20px
    }

    .manage_wallet .active_heading {
        font-size: 25px;
        line-height: 25px
    }

    .manage_wallet .active_days {
        max-width: 434px;
        gap: 15px;
        margin-bottom: 15px
    }

    .manage_wallet .table_wrap.transaction_tbl tr {
        height: 0
    }

    .manage_wallet .table_wrap.transaction_tbl table {
        border-spacing: 0 10px
    }

    .manage_wallet .active_days .active_btnz .active_btn {
        font-size: 20px;
        line-height: 18px;
        height: 40px
    }

    .manage_wallet .wallet_title.wallet__title {
        max-width: 434px;
        margin-bottom: 20px
    }

    .modal_Checkout_inbox .modal-dialog,
    .modal_personal_wallet.modal_manage_wallet .modal-dialog {
        min-width: 670px !important
    }

    .manage_wallet .table_wrap tr {
        height: 60px;
        vertical-align: middle
    }

    .manage_wallet .table_wrap .edit_delete_btnz {
        top: 0
    }

    .manage_wallet .table_wrap .edit_delete_btnz .edit_delete {
        width: 30px;
        height: 30px
    }

    .manage_wallet .table_wrap .edit_delete_btnz .edit_delete img {
        width: 18px
    }

    .manage_wallet .table_wrap .edit_delete_btnz .edit_delete.bg_clr {
        margin-top: 2px
    }

    .manage_wallet .table_wrap .edit_tr {
        max-width: 25px;
        height: calc(100% - 1px)
    }

    .manage_wallet .table_wrap.transaction_tbl .edit_tr {
        max-width: 25px;
        height: 100%
    }

    .manage_wallet .table_wrap .edit_tr span {
        font-size: 15px;
        line-height: 15px
    }

    .modal_order_365 .modal-dialog .modal-content .modal-body .order_btn.payment_btn {
        max-width: 320px;
        width: 100%
    }

    .manage_wallet .wallet_label .input_wrap,
    .manage_wallet .wallet_label .wallet_dropdown .dropdown,
    .nav_wrapper .nav_inner .nav_right {
        height: 45px
    }

    .nav_wrapper .nav_inner .nav_right .btn.logout_btn.dash_btn {
        margin-left: 10px
    }

    .hero_wrapper .need_review .review_head h5,
    .manage_wallet .wallet_title h1,
    .total_payment .payment_inner h1 {
        font-size: 40px;
        line-height: 40px
    }

    .manage_wallet .table_wrap th img {
        width: 17px
    }

    .manage_wallet .table_wrap td {
        font-size: 20px;
        line-height: 20px;
        padding: 15px 0
    }

    .manage_wallet .wallet_label .add_wallet {
        max-width: 160px;
        height: 40px
    }

    .manage_wallet .wallet_label .add_wallet span {
        font-weight: 400;
        font-size: 20px;
        line-height: 19px
    }

    .manage_wallet .wallet_label .wallet_dropdown .dropdown a {
        padding: 0 10px;
        font-size: 21px;
        line-height: 20px
    }

    .manage_wallet .wallet_label .wallet_dropdown .dropdown a img {
        width: 20px
    }

    .manage_wallet .wallet_label .input_wrap input {
        padding: 0 0 0 35px;
        font-size: 22px;
        line-height: 20px
    }

    .manage_wallet .wallet_label .input_wrap .img_wrap {
        left: 10px;
        width: 22px
    }

    .manage_wallet .wallet_label {
        margin-bottom: 30px;
        padding-left: 10px
    }

    .manage_wallet {
        height: 80vh
    }

    .manage_wallet h3 {
        font-size: 35px;
        line-height: 35px;
        margin-bottom: 20px;
        padding-left: 10px
    }

    .manage_wallet .table_wrap th {
        font-size: 24px;
        line-height: 24px
    }

    .index_hero {
        height: 100vh;
        padding: 50px 0
    }

    .service_wrap .service_inner .service_box {
        max-width: 190px;
        width: 100%
    }

    .price_wrap .price_inner .price_box .account_btn {
        max-width: 200px;
        font-size: 25px;
        line-height: 24px
    }

    .contractor_wrap .contractor_wallet .tab-content .table_contractor_account table,
    .hero_wrapper .wallet_balance .wallet_main .dash_content,
    .price_wrap .price_inner {
        max-width: 700px
    }

    .contact_wrap,
    .price_wrap,
    .service_wrap,
    .team_wrap {
        padding: 60px 0
    }

    .price_wrap .price_inner .price_box .buynow_btn {
        max-width: 150px;
        height: 40px;
        line-height: 24px
    }

    .team_wrap .team_inner .team_box .img_wrap img {
        margin-bottom: 20px;
        max-width: 210px
    }

    .contact_wrap .live_wrap .live_chat,
    .sales_body .live_wrap .live_chat {
        max-width: 200px;
        height: 50px
    }

    .hero_wrapper .hero_inner .hero_right,
    .hero_wrapper .wallet_balance .dash_main .dash_content .accordion_head,
    .index_hero .hero_img {
        max-width: 600px
    }

    .service_wrap .service_inner .service_box .img_wrap img {
        max-width: 80px
    }

    .index_hero .hero__main {
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between
    }

    .index_nav .index_tabs li a {
        font-size: 21px;
        line-height: 20px;
        padding: 0
    }

    .index_hero .hero__main .hero_content .hero_title {
        font-size: 50px;
        line-height: 50px
    }

    .index_hero .hero__main .hero_content {
        max-width: 375px
    }

    .hero_wrapper .crypto_account .payment2_inner h3,
    .hero_wrapper .crypto_account .payment2_inner p,
    .index_hero .hero__main .hero_content .hero_detail {
        font-size: 25px;
        line-height: 25px
    }

    .modal_order_365 .modal-dialog .modal-content .modal-body {
        padding-bottom: 50px
    }

    .modal_order_365 .modal-dialog .modal-content .modal-body .order_btn,
    .modal_order_detail .modal-dialog .modal-content .modal-body .order_btn {
        max-width: 460px;
        gap: 25px
    }

    .modal_order_365 .modal-dialog,
    .modal_order_detail .modal-dialog,
    .modal_payment_sent .modal-dialog {
        min-width: 690px !important
    }

    .hero_wrapper .wallet_balance .wallet_main .expired_sec .expired_content.expired__content,
    .modal_Checkout_inbox .modal-dialog .modal-content .modal-body {
        padding-bottom: 30px
    }

    .hero_wrapper .crypto_account .crypto_left {
        padding-left: 15px
    }

    .hero_wrapper .crypto_account .crypto_left .active_sub .add_time {
        margin-bottom: 10px
    }

    .hero_wrapper .crypto_account {
        padding: 25px 0
    }

    .hero_wrapper .need_review .review_head {
        max-width: 520px;
        padding-left: 10px
    }

    .table_wrap th,
    .total_payment .payment_inner .payment_left h3 {
        font-size: 35px;
        line-height: 35px
    }

    .total_payment .payment_inner .payment_left .drop_wrap .dropdown a {
        padding-left: 15px;
        padding-right: 10px;
        font-size: 34px;
        line-height: 32px;
        height: 45px
    }

    .total_payment .payment_inner .send_payment {
        max-width: 290px;
        height: 45px;
        font-size: 34px;
        line-height: 30px;
        margin-top: 20px
    }

    .total_payment {
        padding: 90px 0
    }

    .total_payment .payment_inner .payment_left .drop_wrap .dropdown {
        width: 250px;
        -webkit-transition: unset;
        transition: unset
    }

    .hero_wrapper .need_review .review_content.sub_wrap {
        max-width: 465px;
        padding-left: 26px
    }

    .hero_wrapper .need_review {
        padding: 20px 20px 100px
    }

    .hero_wrapper .need_review .review_head h6 {
        font-size: 40px;
        line-height: 40px;
        max-width: 200px
    }

    .hero_wrapper .crypto_account .payment2_inner .btnz_wrap .payment_order {
        max-width: 320px;
        margin: 0 auto
    }

    .hero_wrapper .crypto_account .payment2_inner .btnz_wrap {
        gap: 0;
        padding-bottom: 50px
    }

    .hero_wrapper .crypto_account .payment2_inner h1,
    .hero_wrapper .multiple_inner .your_account h3,
    .sales_body .hero_wrap .hero_title h2 {
        font-size: 30px;
        line-height: 30px
    }

    .hero_wrapper .crypto_account .payment2_inner p {
        font-size: 34px;
        line-height: 30px
    }

    .hero_wrapper .wallet_balance .wallet_main .expired_sec {
        padding-top: 50px
    }

    .wallet_balance .wallet_main .expired_sec .img_wrap img {
        max-width: 400px;
        width: 100%
    }

    .hero_wrapper .wallet_balance .wallet_main .expired_sec .expired_content h3 {
        font-size: 40px;
        line-height: 40px;
        padding-bottom: 10px
    }

    .hero_wrapper .wallet_balance .wallet_main .expired_sec .expired_content,
    .sales_body .client_says .client_box .box_detail {
        padding-bottom: 20px
    }

    .hero_wrapper .wallet_balance .wallet_main .expired_sec .expired_content p {
        font-size: 32px;
        line-height: 30px
    }

    .modal_Checkout_inbox .modal-dialog .modal-content .modal-header .img_wrap img {
        padding-top: 10px;
        padding-left: 10px
    }

    .hero_wrapper .crypto_account .crypto_right .crypto_btn img {
        width: 22px
    }

    .hero_wrapper .crypto_account .crypto_left h1 {
        font-size: 35px;
        line-height: 45px
    }

    .wallet_balance .wallet_main .wallet_content .accordion_head img {
        width: 60px
    }

    .wallet_balance .wallet_main .wallet_content .accordion_head {
        max-width: 590px
    }

    .modal_monthly_report .modal-dialog,
    .modal_personal_wallet .modal-dialog {
        min-width: 700px !important
    }

    .modal_monthly_report .modal-dialog .modal-content .modal-header .img_wrap img,
    .modal_personal_wallet .modal-dialog .modal-content .modal-header .img_wrap img {
        max-width: 200px;
        width: 100%;
        padding-top: 10px;
        padding-left: 10px
    }

    .modal_monthly_report .modal-dialog .modal-content .modal-body h2 {
        font-size: 40px;
        line-height: 50px;
        padding-bottom: 30px
    }

    .modal_monthly_report .modal-dialog .modal-content .modal-body .report_inner {
        max-width: 400px
    }

    .modal_monthly_report .modal-dialog .modal-content .modal-body .confirm_btn {
        max-width: 190px;
        height: 45px;
        font-weight: 300;
        font-size: 22px;
        line-height: 16px;
        margin-top: 25px
    }

    .modal_personal_wallet .modal-dialog .modal-content .modal-body .confirm_btn {
        max-width: 190px;
        height: 45px;
        font-weight: 300;
        font-size: 22px;
        line-height: 16px;
        margin-top: 55px
    }

    .modal_monthly_report .modal-dialog .modal-content .modal-body {
        padding-bottom: 25px
    }

    .modal_personal_wallet .modal-dialog .modal-content .modal-body h2 {
        font-weight: 700;
        font-size: 38px;
        line-height: 50px;
        padding-top: 10px
    }

    .modal_personal_wallet .modal-dialog .modal-content .modal-body h6 {
        font-size: 30px;
        line-height: 40px;
        padding-bottom: 25px
    }

    .hero_wrapper .multiple_inner .account_main .account_inner,
    .modal_personal_wallet .modal-dialog .modal-content .modal-body .wallet_inner .input_wrap {
        max-width: 300px
    }

    .modal_personal_wallet .modal-dialog .modal-content .modal-body .text_area,
    .modal_personal_wallet .modal-dialog .modal-content .modal-body .wallet_inner {
        max-width: 650px
    }

    .hero_wrapper .crypto_account .crypto_right .crypto_btn.monthly {
        max-width: 130px
    }

    .hero_wrapper .crypto_account .crypto_right .crypto_btn.personal,
    .hero_wrapper .crypto_account .crypto_right .crypto_btn.wallet {
        max-width: 160px
    }

    .bg_multipleAccount .hero_wrapper {
        min-height: calc(100vh - 210px)
    }

    .alert_danger,
    .alert_success {
        padding-top: 5px !important;
        left: 15px !important;
        font-size: 18px
    }

    .modal_view_user .modal-dialog {
        max-width: 700px !important
    }

    .nav_wrapper .nav_inner .nav_right .nav-tabs {
        width: 350px
    }

    .nav_wrapper .nav_inner .nav_right .nav-tabs .nav-link {
        width: 100px;
        font-size: 16px
    }

    .popover,
    .popover .popover-arrow {
        left: initial !important
    }

    .popover .popover-arrow::after {
        border-left-color: #77cad2;
        border-top-color: transparent
    }

    .popover .popover-arrow::before {
        border-top-color: transparent
    }

    .nav_wrapper .nav_inner .nav_right .btn.create_btn {
        width: 140px;
        height: 45px
    }

    .nav_wrapper .nav_inner .nav_right .btn.sign_btn {
        width: 85px;
        height: 45px
    }

    .nav_wrapper .nav_inner .nav_left img {
        max-width: 240px
    }

    .nav_wrapper .nav_inner .nav_right .btn {
        font-weight: 400;
        font-size: 18px;
        line-height: 16px
    }

    .hero_wrapper .hero_inner {
        padding-left: 0;
        padding-top: 30px;
        margin-bottom: 40px
    }

    .hero_wrapper .hero_inner .hero_left .create_btn {
        height: 45px;
        font-size: 18px;
        line-height: 18px
    }

    .defi_img .container img {
        max-width: 200px
    }

    .footer_wrapper .footer_inner ul li {
        font-size: 16px;
        line-height: 16px
    }

    .footer_wrapper .footer_inner ul {
        max-width: 625px
    }

    .footer_wrapper .footer_inner .social_icons {
        gap: 5px
    }

    .footer_wrapper {
        padding: 15px 0
    }

    .defi_img .container {
        border-bottom: 1px solid #808080d4;
        padding-bottom: 10px
    }

    .hero_wrapper .multiple_inner {
        max-width: 700px;
        margin-bottom: 50px
    }

    .nav_wrapper .nav_inner .nav_right .btn.logout_btn {
        width: 110px;
        height: 40px
    }

    .hero_wrapper .multiple_inner .your_account .input_wrap input {
        font-size: 26px;
        line-height: 36px
    }

    .hero_wrapper .multiple_inner .account_main .account_inner h3 {
        font-size: 25px;
        line-height: 25px;
        margin-bottom: 20px
    }

    .hero_wrapper .multiple_inner .account_main {
        padding-bottom: 45px
    }

    .defi_img .container .person_img img {
        max-width: 175px
    }

    .contractor_wrap .contractor_wallet .nav-tabs .nav-item {
        max-width: 290px;
        height: 60px
    }

    .contractor_wrap .contractor_wallet .tab-content .add_btn {
        max-width: 130px;
        height: 45px;
        font-size: 25px;
        margin-bottom: 30px
    }

    .contractor_wrap .contractor_wallet .tab-content .table_contractor_account.table_contractor_account2 table {
        max-width: 630px
    }

    .contractor_wrap .contractor_wallet .tab-content .table_contractor_account.table_contractor_account2 table tr.border_bottom::after {
        content: "";
        width: calc(100% + 113px);
        left: -56px
    }

    .contractor_wrap .contractor_wallet {
        padding-top: 40px;
        padding-bottom: 60px;
        margin-bottom: 80px
    }

    .nav_wrapper .nav_inner .nav_right .right_inner .nav_icons .img_wrap img {
        max-width: 30px
    }

    .contractor_wrap .contractor_wallet .tab-content .table_contractor_account.table_contractor_account2 table img {
        width: 30px
    }

    .contractor_wrap .contractor_wallet .tab-content .table_contractor_account table tr.border_bottom::after {
        width: calc(100% + 43px);
        left: -21px
    }

    .chart_content .chart_title {
        margin-bottom: 15px;
        font-size: 30px;
        line-height: 30px
    }

    .graph_chat {
        padding: 25px;
        margin-top: 40px;
        margin-bottom: 60px
    }

    .sales_body .hero_wrap {
        padding-top: 25px
    }

    .sales_body .create_btn {
        max-width: 170px
    }

    .sales_body .hero_wrap .hero_inner {
        padding-top: 40px
    }

    .sales_body .hero_wrap .hero_title {
        max-width: 540px
    }

    .sales_body .hero_wrap .hero_title::after,
    .sales_body .hero_wrap .hero_title::before {
        width: 150px
    }

    .sales_body .hero_wrap .hero_detail h1 {
        font-size: 40px;
        line-height: 55px
    }

    .sales_body .hero_wrap .hero_detail {
        padding-bottom: 0
    }

    .sales_body .create_btn.learn_btn {
        height: 40px
    }

    .sales_body .client_says .client_inner,
    .sales_body .crypto_wrap {
        gap: 15px
    }

    .sales_body .crypto_wrap .crypto_btn {
        max-width: 160px;
        height: 50px;
        gap: 7px
    }

    .sales_body .crypto_wrap .crypto_img {
        max-width: 40px
    }

    .sales_body .crypto_wrap .crypto_btn span {
        font-size: 16px
    }

    .sales_body .sale_detail.why_wrap {
        padding-top: 70px
    }

    .sales_body .sale_detail .sale_title {
        font-size: 30px;
        line-height: 40px;
        margin-bottom: 10px
    }

    .sales_body .sale_detail .sale_img {
        max-width: 325px
    }

    .sales_body .sale_detail .why_us .why_inner p {
        font-size: 26px
    }

    .sales_body .sale_detail .why_us .crypto_img {
        max-width: 50px;
        margin-right: 7px
    }

    .sales_body .sale_detail .account_procedure {
        padding-top: 40px;
        gap: 20px
    }

    .sales_body .sale_detail .account_procedure .num {
        width: 50px;
        height: 50px;
        font-size: 24px;
        line-height: 24px
    }

    .sales_body .sale_detail .account_procedure .procedure_inner {
        gap: 10px
    }

    .sales_body .client_says .client_box {
        padding: 15px;
        max-width: 200px
    }

    .sales_body .client_says .right_img {
        max-width: 40px;
        right: 0
    }

    .sales_body .client_says .left_img {
        max-width: 40px;
        left: 0
    }

    .sales_body .client_says .client_box .box_footer img {
        max-width: 35px;
        margin-bottom: 5px
    }

    .sales_body .contact_us {
        padding-top: 0;
        padding-bottom: 80px
    }

    .sales_body .contact_box {
        max-width: 430px;
        padding: 30px
    }

    .sales_body .contact_box h2 {
        font-size: 28px;
        line-height: 28px;
        margin-bottom: 20px
    }

    .sales_body .contact_box .input_wrap label {
        margin-bottom: 0
    }

    .sales_body .contact_box .send_message {
        max-width: 200px;
        height: 50px;
        font-size: 24px;
        line-height: 24px
    }
}

@media (max-width:575.98px) {
    .svgBellIcon {
        height: 17px;
        width: 17px
    }

    .manage_wallet,
    .nav_wrapper .nav_inner.nav__inner .nav_right {
        height: auto
    }

    .total_payment .payment_inner .payment_middle_wrap label {
        font-size: 25px;
        line-height: 35px
    }

    .dewq_nav .nav_inner {
        height: 100% !important;
        padding: 5px 0 !important
    }

    .dewq_nav .nav_inner .dewq_btnz {
        margin: 0;
        gap: 5px
    }

    .hero_wrapper .crypto_account .payment2_inner .btnz_wrap .payment_order.bewq_order {
        height: 30px;
        font-size: 18px;
        max-width: 150px;
        width: 100%
    }

    .dewq_nav .nav_inner .nav_right .right_inner {
        gap: 20px
    }

    .dewq_btnz .btn.logout_btn {
        width: 85px !important
    }

    .dewq_left img {
        max-width: 80px !important
    }

    .ul_open .popover__menu.whale_poper {
        right: -23px
    }

    .ul_open .popover__menu {
        top: 33px;
        right: 7px
    }

    .ul_open {
        width: 170px
    }

    .ul_open img {
        width: 17px !important
    }

    .nav_wrapper .nav_inner.nav__inner {
        height: 70px;
        gap: 2px
    }

    .nav_wrapper .nav_inner.nav__inner .nav_right .right_inner {
        width: 100%
    }

    .nav_wrapper .nav_inner.nav__inner .nav_right .right_inner .nav_icons {
        margin-right: 15px;
        gap: 10px
    }

    .manage_wallet .active_heading,
    .manage_wallet .wallet_label .add_wallet span {
        font-size: 18px;
        line-height: 17px
    }

    .manage_wallet .active_days .active_inner {
        max-width: 85px;
        gap: 3px
    }

    .manage_wallet .wallet_title.wallet__title {
        margin-bottom: 10px;
        gap: 5px
    }

    .manage_wallet .wallet_title.wallet__title img {
        width: 18px !important
    }

    .manage_wallet .table_wrap.transaction_tbl table {
        border-spacing: 0 5px;
        margin-bottom: 10px
    }

    .manage_wallet .table_wrap.transaction_tbl td {
        line-height: 18px;
        padding: 10px 0
    }

    .manage_wallet .active_days {
        gap: 10px;
        margin-bottom: 10px
    }

    .manage_wallet .active_days .active_btnz .active_btn {
        font-size: 14px;
        line-height: 14px;
        height: 25px
    }

    .manage_wallet .active_days .active_btnz {
        gap: 8px
    }

    .modal_personal_wallet.modal_manage_wallet .modal-dialog {
        min-width: 315px !important
    }

    .modal_manage_wallet .modal-dialog .modal-content .modal-body .wallet_inner .input_wrap {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap
    }

    .modal_manage_wallet .modal-dialog .modal-content .modal-body .wallet_inner .input_wrap input {
        width: 290px !important
    }

    .modal_manage_wallet .modal-dialog .modal-content .modal-body .wallet_inner .input_wrap .dropdown .btn {
        max-width: 290px !important;
        width: 290px !important;
        height: 40px
    }

    .contractor_wrap .contractor_wallet .tab-content .table_contractor_account.table_contractor_account2 table img,
    .hero_wrapper .crypto_account .crypto_left .active_sub .add_time .time_btn img,
    .modal_manage_wallet .modal-dialog .modal-content .modal-body .wallet_inner .input_wrap .dropdown .btn img,
    .modal_monthly_report .modal-dialog .modal-content .modal-body .drop_wrap .dropdown a,
    .modal_order_365 .modal-dialog .modal-content .modal-body .report_inner .input_wrap .input_calender img,
    .modal_order_detail .modal-dialog .modal-content .modal-body .drop_wrap .dropdown a img,
    .modal_order_detail .modal-dialog .modal-content .modal-body .report_inner .input_wrap .input_calender img,
    .modal_setting .modal-dialog .modal-content .modal-body .input_wrap .right_arrow {
        width: 20px
    }

    .modal_manage_wallet .modal-dialog .modal-content .modal-body .text_area,
    .modal_personal_wallet .modal-dialog .modal-content .modal-body .wallet_inner {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center
    }

    .modal_manage_wallet .modal-dialog .modal-content .modal-body .wallet_inner .input_wrap {
        -webkit-box-pack: center !important;
        -ms-flex-pack: center !important;
        justify-content: center !important
    }

    .modal_manage_wallet .modal-dialog .modal-content .modal-body .text_area textarea {
        max-width: 290px !important
    }

    .modal_manage_wallet .modal-dialog .modal-content .modal-body label {
        width: 100%;
        padding-left: 20px !important;
        margin-bottom: 5px;
        padding-bottom: 0 !important
    }

    .modal_manage_wallet .modal-dialog .modal-content .modal-header {
        padding: 10px !important
    }

    .modal_manage_wallet .modal-dialog .modal-content .modal-body .wallet_inner {
        gap: 5px
    }

    .modal_manage_wallet .modal-dialog .modal-content .modal-body .confirm_btn {
        margin-top: 15px
    }

    .modal_view_user .modal-dialog .modal-content .modal-body .user_main .user_inner .user_name .img_wrap img {
        max-width: 22px;
        top: -2px
    }

    .manage_wallet .table_wrap tr,
    .modal_personal_wallet .modal-dialog .modal-content .modal-body .wallet_inner .input_wrap input {
        height: 40px
    }

    .manage_wallet .table_wrap .edit_tr {
        max-width: 18px !important;
        height: 100%
    }

    .manage_wallet .table_wrap .edit_tr span {
        font-size: 12px;
        line-height: 11px
    }

    .manage_wallet .table_wrap .edit_delete_btnz .edit_delete {
        width: 22px;
        height: 22px
    }

    .manage_wallet .table_wrap .edit_delete_btnz .edit_delete img {
        width: 12px
    }

    .modal_order_365 .modal-dialog .modal-content .modal-body .order_btn.payment_btn {
        max-width: 200px;
        width: 100%
    }

    .hero_wrapper .crypto_account .payment2_inner p,
    .hero_wrapper .wallet_balance .wallet_main .expired_sec .expired_content p,
    .modal_order_365 .modal-dialog .modal-content .modal-body .payment2_titl,
    .sales_body .hero_wrap .hero_title h2,
    .sales_body .sale_detail .why_us .why_inner p,
    .update_name .modal-dialog .modal-content .modal-body .input_wrap label {
        font-size: 20px;
        line-height: 20px
    }

    .contact_wrap .contact_inner .contact_box h2,
    .manage_wallet .wallet_title h1,
    .manage_wallet h3,
    .modal_order_365 .modal-dialog .modal-content .modal-body .report_inner .input_wrap label,
    .modal_order_detail .modal-dialog .modal-content .modal-body .report_inner .input_wrap label,
    .modal_payment_sent .modal-dialog .modal-content .modal-body h6 {
        font-size: 25px;
        line-height: 25px
    }

    .footer_wrapper .footer_inner .social_icons img,
    .hero_wrapper .wallet_balance .wallet_main .wallet_content .table_wrap .crypto_img img,
    .manage_wallet .wallet_title img {
        max-width: 30px
    }

    .manage_wallet .wallet_title {
        margin-bottom: 10px
    }

    .manage_wallet .table_wrap th {
        font-size: 18px;
        line-height: 18px
    }

    .hero_wrapper .wallet_balance .dash_main .wallet_content .accordion-button::after,
    .manage_wallet .table_wrap th img {
        width: 10px
    }

    .manage_wallet .table_wrap td {
        font-size: 16px;
        line-height: 14px;
        vertical-align: middle
    }

    .manage_wallet .table_wrap .table,
    .modal_add_user2 .modal-dialog .modal-content .modal-body .input_wrap,
    .payment_report .payment_content .payment_value,
    .service_wrap h1 {
        margin-bottom: 20px
    }

    .manage_wallet .table_wrap th .tbl_title {
        width: 90px;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        gap: 5px
    }

    .manage_wallet .table_wrap .tbl_title2 {
        width: 80px;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        gap: 5px
    }

    .manage_wallet h3 {
        margin-bottom: 10px;
        padding-top: 10px
    }

    .index_nav .index_tabs {
        margin-top: 10px
    }

    .price_wrap h2,
    .service_wrap h1,
    .team_wrap h2 {
        font-size: 32px;
        line-height: 32px
    }

    .defi_img.defi_manage {
        padding-top: 20px
    }

    .contact_wrap .live_wrap,
    .graph_chat {
        margin-top: 30px
    }

    .manage_wallet .wallet_label {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        gap: 10px
    }

    .index_hero {
        padding: 30px 0
    }

    .index_hero .hero__main .hero_content .hero_title {
        font-size: 35px;
        line-height: 35px;
        margin-bottom: 10px
    }

    .index_hero .hero__main .hero_content .hero_detail {
        font-size: 22px;
        line-height: 20px;
        margin-bottom: 25px
    }

    .index_hero .hero__main .hero_content .register_btn {
        max-width: 140px;
        height: 40px;
        font-size: 25px;
        line-height: 25px
    }

    .why_choose {
        padding-top: 50px;
        padding-bottom: 50px
    }

    .why_choose .why_choose_main {
        gap: 35px
    }

    .why_choose .why_choose_main .why_choose_content h1 {
        font-size: 30px;
        line-height: 30px;
        margin-bottom: 20px;
        text-align: center
    }

    .why_choose .why_choose_main .why_choose_content p {
        font-size: 22px;
        line-height: 24px;
        text-align: center
    }

    .contact_wrap,
    .price_wrap,
    .service_wrap,
    .team_wrap {
        padding: 45px 0
    }

    .service_wrap .service_inner {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        gap: 30px
    }

    .price_wrap p {
        font-size: 22px;
        line-height: 23px;
        margin-bottom: 25px
    }

    .price_wrap .price_inner,
    .team_wrap .team_inner {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        gap: 30px
    }

    .price_wrap .price_inner .price_box,
    .price_wrap .price_inner .price_box .price__box {
        margin: 0 !important
    }

    .cst_price__box {
        padding: 15px !important
    }

    .contact_wrap .contact_inner .contact_box .input_wrap label {
        font-size: 20px;
        line-height: 20px;
        margin-bottom: 0
    }

    .contact_wrap .contact_inner .contact_box {
        padding: 25px
    }

    .contact_wrap .contact_inner .contact_box .send_message {
        max-width: 170px;
        height: 45px;
        font-size: 23px;
        line-height: 20px
    }

    .contact_wrap .contact_inner .contact_box .input_wrap textarea {
        height: 120px;
        margin-bottom: 20px
    }

    .contact_wrap .live_wrap .live_chat {
        max-width: 170px;
        height: 45px
    }

    .contact_wrap .live_wrap .live_chat img,
    .modal_add_user .modal-dialog .modal-content .modal-header img,
    .modal_add_user2 .modal-dialog .modal-content .modal-header img,
    .modal_delete .modal-dialog .modal-content .modal-header img,
    .modal_setting .modal-dialog .modal-content .modal-header img,
    .modal_view_user .modal-dialog .modal-content .modal-header img,
    .payment_report .payment_tbl table td img,
    .update_name .modal-dialog .modal-content .modal-header img {
        width: 15px
    }

    .contact_wrap .live_wrap .live_chat span {
        font-size: 23px;
        line-height: 20px
    }

    .price_wrap .price_inner .price_box h1 {
        font-size: 45px;
        line-height: 45px;
        margin: 15px 0
    }

    .contractor_wrap .table_wrap th,
    .price_wrap .price_inner .price_box h5,
    .total_payment .payment_inner .payment_left h3 {
        font-size: 30px;
        line-height: 30px
    }

    .heading-for-finance-card {
        font-size: 30px !important;
        line-height: 30px !important
    }

    .price_wrap .price_inner .price_box .account_btn {
        max-width: 165px;
        font-size: 23px;
        line-height: 20px;
        height: 40px
    }

    .price_wrap .price_inner .price_box .buynow_btn {
        max-width: 115px;
        height: 35px;
        line-height: 20px;
        font-size: 20px
    }

    .hero_wrapper .multiple_inner .your_account .input_wrap,
    .index_hero .hero__main .hero_content {
        max-width: 260px
    }

    .modal_order_365 .modal-dialog,
    .modal_order_detail .modal-dialog,
    .modal_payment_sent .modal-dialog {
        min-width: 350px !important;
        max-width: 350px !important;
        margin: 0 auto !important
    }

    .modal_order_365 .modal-dialog .modal-content .modal-body h2,
    .modal_order_detail .modal-dialog .modal-content .modal-body h2 {
        font-weight: 700;
        font-size: 35px;
        line-height: 35px;
        margin-bottom: 10px
    }

    .custom-form-margin-left36,
    .modal_order_365 .modal-dialog .modal-content .modal-body .report_inner .input_wrap,
    .modal_order_detail .modal-dialog .modal-content .modal-body .report_inner .input_wrap {
        margin-left: 10px
    }

    .modal_order_365 .modal-dialog .modal-content .modal-body .confirm_btn,
    .modal_order_detail .modal-dialog .modal-content .modal-body .confirm_btn {
        max-width: 200px;
        height: 45px;
        font-weight: 300;
        font-size: 20px
    }

    .modal_order_365 .modal-dialog .modal-content .modal-body .order_btn,
    .modal_order_detail .modal-dialog .modal-content .modal-body .order_btn {
        gap: 10px;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        margin: 15px auto
    }

    .modal_order_365 .modal-dialog .modal-content .modal-body .report_inner .input_wrap .input_calender p,
    .modal_order_detail .modal-dialog .modal-content .modal-body .report_inner .input_wrap .input_calender p {
        font-size: 25px;
        line-height: 24px
    }

    .modal_order_365 .modal-dialog .modal-content .modal-body .report_inner .input_wrap .input_calender,
    .modal_order_detail .modal-dialog .modal-content .modal-body .report_inner .input_wrap .input_calender {
        height: 40px;
        width: 300px
    }

    .modal_order_365 .modal-dialog .modal-content .modal-body .order_detail.order__detail,
    .modal_order_detail .modal-dialog .modal-content .modal-body .order_detail.order__detail {
        font-size: 20px;
        line-height: 16px;
        margin-top: 30px;
        margin-bottom: 0
    }

    .modal_order_365 .modal-dialog .modal-content .modal-header .img_wrap img,
    .modal_order_detail .modal-dialog .modal-content .modal-header .img_wrap img,
    .modal_payment_sent .modal-dialog .modal-content .modal-header.payment_head .img_wrap img {
        max-width: 150px;
        width: 100%;
        padding-top: 0;
        padding-left: 0
    }

    .modal_monthly_report .modal-dialog .modal-content .modal-body .drop_wrap,
    .modal_order_detail .modal-dialog .modal-content .modal-body .drop_wrap {
        max-width: 300px;
        margin: 16px auto
    }

    .modal_monthly_report .modal-dialog .modal-content .modal-body .drop_wrap label,
    .modal_order_detail .modal-dialog .modal-content .modal-body .drop_wrap label {
        font-size: 25px;
        line-height: 25px;
        margin-bottom: 0
    }

    .modal_monthly_report .modal-dialog .modal-content .modal-body .drop_wrap .dropdown a,
    .modal_order_detail .modal-dialog .modal-content .modal-body .drop_wrap .dropdown a {
        padding-left: 10px;
        padding-right: 10px;
        font-size: 25px;
        line-height: 25px;
        height: 40px
    }

    .modal_monthly_report .modal-dialog .modal-content .modal-body .drop_wrap h4,
    .modal_order_detail .modal-dialog .modal-content .modal-body .drop_wrap h4 {
        font-size: 25px;
        line-height: 25px;
        margin: 20px 0
    }

    .hero_wrapper .wallet_balance .wallet_main .expired_sec .expired_content.expired__content {
        padding-bottom: 0
    }

    .hero_wrapper .wallet_balance .dash_main .wallet_content .accordion-button {
        padding-left: 10px;
        padding-right: 10px
    }

    .contractor_wrap .table_wrap td,
    .hero_wrapper .wallet_balance .dash_main .wallet_content .accordion_head h3,
    .hero_wrapper .wallet_balance .dash_main .wallet_content .accordion_head p,
    .nav_wrapper .nav_inner .nav_right .dropdown .dropdown-menu li .dropdown-item.header_sec h4 {
        font-size: 20px;
        line-height: 18px
    }

    .hero_wrapper .wallet_balance .dash_main .wallet_content .accordion_head {
        margin: unset;
        max-width: 300px
    }

    .hero_wrapper .wallet_balance .dash_main .wallet_content .accordion-item {
        margin-left: 5px;
        margin-right: 5px
    }

    .hero_wrapper .wallet_balance .dash_main {
        padding-top: 25px;
        padding-bottom: 20px;
        margin-bottom: 50px
    }

    .hero_wrapper .wallet_balance .dash_main .wallet_header .wallet_title {
        gap: 6px
    }

    .hero_wrapper .crypto_account .crypto_left .active_sub h4,
    .modal_Checkout_inbox .modal-dialog .modal-content .modal-body h6 {
        font-size: 22px;
        line-height: 18px
    }

    .hero_wrapper .crypto_account .crypto_left .active_sub h4 span,
    .sales_body .contact_box .input_wrap label {
        font-size: 20px
    }

    .hero_wrapper .crypto_account .crypto_left .active_sub .add_time .time_btn {
        max-width: 90px;
        height: 28px;
        font-size: 15px;
        line-height: 15px
    }

    .hero_wrapper .crypto_account .crypto_left .active_sub .add_time p {
        font-weight: 300;
        font-size: 20px;
        line-height: 20px
    }

    .modal_payment_sent .modal-dialog .modal-content .modal-body .img_wrap img {
        max-width: 130px;
        width: 100%
    }

    .modal_payment_sent .modal-dialog .modal-content .modal-body .img_wrap {
        padding-top: 20px;
        padding-bottom: 20px
    }

    .modal_payment_sent .modal-dialog .modal-content .modal-body .go_dash {
        max-width: 250px;
        height: 45px;
        font-size: 25px;
        line-height: 25px;
        margin-top: 20px
    }

    .hero_wrapper .need_review .accordion .accordion-button::after,
    .hero_wrapper .need_review .form-group label::before,
    .modal_monthly_report .modal-dialog .modal-content .modal-body .report_inner .checkbox_wrap .form-group label:before {
        height: 20px;
        width: 20px
    }

    .modal_add_user2 .modal-dialog .modal-content .modal-body,
    .modal_order_detail .modal-dialog .modal-content .modal-body,
    .modal_payment_sent .modal-dialog .modal-content .modal-body,
    .modal_view_user .modal-dialog .modal-content .modal-body {
        padding-bottom: 30px
    }

    .modal_order_detail .modal-dialog .modal-content .modal-body .order_detail {
        font-size: 25px;
        line-height: 25px;
        max-width: 240px;
        margin-bottom: 25px
    }

    .hero_wrapper .crypto_account .payment2_inner h3,
    .hero_wrapper .wallet_balance .wallet_main .wallet_header .wallet_title h3 {
        font-size: 18px;
        line-height: 16px
    }

    .hero_wrapper .need_review {
        padding: 10px 5px 70px
    }

    .hero_wrapper .need_review .review_content h4,
    .hero_wrapper .need_review .review_head h5 {
        font-size: 22px;
        line-height: 20px;
        width: auto
    }

    .hero_wrapper .need_review .review_head h6 {
        font-size: 22px;
        line-height: 20px;
        max-width: 40px;
        padding-left: 30px
    }

    .hero_wrapper .need_review .review_content .btn {
        font-size: 15px;
        line-height: 15px;
        max-width: 110px;
        border-radius: 20px;
        height: 35px
    }

    .hero_wrapper .need_review .review_head {
        max-width: 210px
    }

    .hero_wrapper .need_review .review_content.sub_wrap {
        max-width: 230px;
        padding-left: 38px
    }

    .hero_wrapper .need_review .form-group input:checked+label:after {
        top: 0;
        left: 0;
        width: 20px;
        height: 20px
    }

    .contractor_wrap .contractor_inner .contractor_content .contractor_title span,
    .contractor_wrap .contractor_wallet .nav-tabs .nav-item .nav-link,
    .hero_wrapper .multiple_inner .your_account h3,
    .hero_wrapper .multiple_inner h2,
    .hero_wrapper .need_review .form-group label {
        font-size: 22px;
        line-height: 20px
    }

    .hero_wrapper .need_review .checkbox_wrapper .checkbox_inner .form-group p {
        font-size: 20px;
        line-height: 22px;
        margin-left: 5px
    }

    .hero_wrapper .need_review .checkbox_wrapper {
        max-width: 180px
    }

    .total_payment {
        padding: 50px 0
    }

    .total_payment .payment_inner {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        gap: 20px;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center
    }

    .total_payment .payment_inner h1 {
        font-size: 30px;
        line-height: 30px;
        text-align: center
    }

    .total_payment .payment_inner .send_payment {
        max-width: 250px;
        margin: 0 auto;
        height: 40px;
        font-size: 28px
    }

    .total_payment .payment_inner .payment_left .drop_wrap .dropdown a {
        padding-left: 10px;
        padding-right: 10px;
        font-size: 28px;
        line-height: 27px;
        height: 40px
    }

    .modal_add_user .modal-dialog .modal-content .modal-body .input_wrap .right_arrow,
    .modal_add_user .modal-dialog .modal-content .modal-body .input_wrap .user_img,
    .modal_add_user2 .modal-dialog .modal-content .modal-body .input_wrap .dropdown img,
    .total_payment .payment_inner .payment_left .drop_wrap .dropdown a img {
        width: 25px
    }

    .hero_wrapper .need_review .accordion .accordion-button {
        width: 40px
    }

    .hero_wrapper .crypto_account .payment2_inner .btnz_wrap .payment_order {
        max-width: 200px;
        margin: 0 auto;
        height: 45px;
        font-size: 22px;
        line-height: 20px
    }

    .hero_wrapper .crypto_account .payment2_inner h1 {
        padding-top: 20px !important;
        font-size: 22px;
        line-height: 20px
    }

    .hero_wrapper .wallet_balance .wallet_main .expired_sec .expired_content h3,
    .payment_report .payment_content h3 {
        font-size: 25px;
        line-height: 20px
    }

    .wallet_balance .wallet_main .expired_sec .expired_content {
        padding-bottom: 0;
        max-width: 310px
    }

    .payment_report .payment_content,
    .wallet_balance .wallet_main .expired_sec .img_wrap img {
        max-width: 250px
    }

    .modal_Checkout_inbox .modal-dialog {
        min-width: 350px !important
    }

    .modal_Checkout_inbox .modal-dialog .modal-content .modal-header .img_wrap img {
        padding-top: 0;
        padding-left: 0;
        max-width: 90px
    }

    .modal_Checkout_inbox .modal-dialog .modal-content .modal-body h2 {
        font-size: 24px;
        line-height: 16px;
        margin-bottom: 10px
    }

    .modal_Checkout_inbox .modal-dialog .modal-content .modal-body .img_wrap img {
        max-width: 170px;
        width: 100%
    }

    .hero_wrapper .crypto_account {
        padding: 15px;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        margin-bottom: 15px;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center
    }

    .hero_wrapper .crypto_account .crypto_left {
        text-align: center
    }

    .hero_wrapper .crypto_account .crypto_left h1 {
        font-size: 30px;
        line-height: 40px;
        padding-bottom: 10px
    }

    .hero_wrapper .crypto_account .crypto_right .crypto_btn img {
        width: 17px;
        height: 17px
    }

    .hero_wrapper .crypto_account .crypto_right .crypto_btn a {
        font-size: 15px
    }

    .hero_wrapper .crypto_account .crypto_right .crypto_btn {
        height: 35px
    }

    .hero_wrapper .crypto_account .crypto_right .crypto_btn.monthly {
        max-width: 120px
    }

    .wallet_balance .wallet_main .wallet_header .wallet_title img {
        width: 14px
    }

    .wallet_balance .wallet_main .wallet_content .accordion_head img {
        width: 35px;
        padding: 5px 0
    }

    .hero_wrapper .wallet_balance .wallet_main .wallet_content .table_wrap h3 {
        font-weight: 700;
        font-size: 16px;
        line-height: 16px
    }

    .hero_wrapper .wallet_balance .wallet_main .wallet_content .table_wrap p,
    .sales_body .sale_detail .sale_text {
        font-size: 15px;
        line-height: 15px
    }

    .modal_monthly_report .modal-dialog,
    .modal_personal_wallet .modal-dialog {
        min-width: 330px !important;
        max-width: 340px;
        width: 100%;
        margin: 0 auto
    }

    .modal_monthly_report .modal-dialog .modal-content .modal-header .img_wrap img,
    .modal_personal_wallet .modal-dialog .modal-content .modal-header .img_wrap img {
        max-width: 130px;
        padding-top: 0;
        padding-left: 0
    }

    .modal_monthly_report .modal-dialog .modal-content .modal-body h2,
    .modal_personal_wallet .modal-dialog .modal-content .modal-body h2 {
        font-size: 25px;
        line-height: 24px;
        padding-bottom: 15px;
        padding-top: 10px
    }

    .modal_monthly_report .modal-dialog .modal-content .modal-body .report_inner .input_wrap .dropdown .btn {
        max-width: 300px;
        width: 100%;
        margin: 0 auto;
        height: 45px;
        padding-left: 10px;
        padding-right: 10px;
        font-weight: 500;
        font-size: 20px;
        line-height: 16px
    }

    .modal_monthly_report .modal-dialog .modal-content .modal-body,
    .modal_personal_wallet .modal-dialog .modal-content .modal-body {
        padding-bottom: 25px;
        padding-left: 10px;
        padding-right: 10px
    }

    .modal_monthly_report .modal-dialog .modal-content .modal-body .report_inner .input_wrap label,
    .modal_personal_wallet .modal-dialog .modal-content .modal-body label {
        font-size: 20px;
        line-height: 20px;
        padding-left: 10px
    }

    .chart_content .dropdown ul,
    .hero_wrapper .hero_inner .hero_right,
    .modal_monthly_report .modal-dialog .modal-content .modal-body .report_inner .input_wrap,
    .modal_personal_wallet .modal-dialog .modal-content .modal-body .text_area,
    .update_name .modal-dialog .modal-content .modal-body .input_wrap {
        max-width: 300px
    }

    .modal_add_user .modal-dialog .modal-content .modal-body .input_wrap input,
    .modal_monthly_report .modal-dialog .modal-content .modal-body .report_inner .input_wrap .input_calender,
    .payment_report .payment_tbl table .border_bottom {
        height: 45px
    }

    .modal_monthly_report .modal-dialog .modal-content .modal-body .report_inner .input_wrap .input_calender p {
        font-weight: 500;
        font-size: 20px;
        line-height: 18px
    }

    .modal_personal_wallet .modal-dialog .modal-content .modal-body h6 {
        font-size: 20px;
        line-height: 18px;
        padding-bottom: 15px
    }

    .modal_personal_wallet .modal-dialog .modal-content .modal-body .wallet_inner .input_wrap input::-webkit-input-placeholder {
        font-size: 18px;
        line-height: 18px
    }

    .modal_personal_wallet .modal-dialog .modal-content .modal-body .wallet_inner .input_wrap input:-ms-input-placeholder {
        font-size: 18px;
        line-height: 18px
    }

    .modal_personal_wallet .modal-dialog .modal-content .modal-body .wallet_inner .input_wrap input::-ms-input-placeholder {
        font-size: 18px;
        line-height: 18px
    }

    .modal_personal_wallet .modal-dialog .modal-content .modal-body .wallet_inner .input_wrap input::placeholder {
        font-size: 18px;
        line-height: 18px
    }

    .modal_personal_wallet .modal-dialog .modal-content .modal-body .confirm_btn {
        max-width: 110px;
        height: 35px;
        font-size: 17px;
        line-height: 16px
    }

    .modal_monthly_report .modal-dialog .modal-content .modal-body .report_inner .input_wrap img {
        width: 22px
    }

    .modal_monthly_report .modal-dialog .modal-content .modal-body .report_inner {
        gap: 10px 0
    }

    .modal_monthly_report .modal-dialog .modal-content .modal-body .report_inner .checkbox_wrap .form-group label {
        font-size: 24px;
        line-height: 16px;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center
    }

    .modal_monthly_report .modal-dialog .modal-content .modal-body .report_inner .checkbox_wrap .form-group input:checked+label:after {
        background-size: contain;
        top: 2px;
        left: 2px;
        width: 17px;
        height: 15px
    }

    .hero_wrapper .wallet_balance .wallet_main .wallet_content .table_wrap .crypto_img {
        gap: 5px;
        padding-top: 0
    }

    .modal_monthly_report .modal-dialog .modal-content .modal-body .confirm_btn {
        max-width: 110px;
        height: 35px;
        font-size: 17px;
        line-height: 16px;
        margin-top: 25px
    }

    .wallet_balance .wallet_main .wallet_content .table_wrap .available_balance {
        max-width: 50px;
        width: 100%;
        margin: 0 auto;
        text-align: center
    }

    .wallet_balance .wallet_main .wallet_content .accordion_head h3,
    .wallet_balance .wallet_main .wallet_content .accordion_head p {
        font-size: 16px;
        line-height: 15px
    }

    .wallet_balance .wallet_main .wallet_content {
        max-width: 335px
    }

    .wallet_balance .wallet_main .wallet_content .accordion-button {
        padding-right: 5px;
        border-radius: 5px
    }

    .wallet_balance .wallet_main .wallet_content .accordion-body {
        padding: 5px
    }

    .modal_add_user .modal-dialog .modal-content .modal-body .input_wrap,
    .wallet_balance .wallet_main .wallet_content .accordion_head {
        max-width: 280px
    }

    .modal_add_user2 .modal-dialog .modal-content .modal-body h2 {
        font-size: 26px;
        line-height: 26px;
        margin-bottom: 20px
    }

    .modal_add_user2 .modal-dialog .modal-content .modal-body .input_wrap label {
        font-size: 18px;
        line-height: 18px
    }

    .modal_add_user2 .modal-dialog,
    .modal_view_user .modal-dialog {
        min-width: 320px !important
    }

    .modal_add_user2 .modal-dialog .modal-content .modal-body .input_wrap input {
        height: 40px;
        font-size: 16px;
        line-height: 16px
    }

    .modal_add_user2 .modal-dialog .modal-content .modal-body .input_wrap .dropdown .btn {
        height: 45px;
        padding-left: 10px;
        padding-right: 10px;
        font-size: 17px;
        line-height: 16px
    }

    .modal_add_user2 .modal-dialog .modal-content .modal-body .input_wrap .dropdown ul li a {
        font-size: 16px;
        line-height: 15px;
        padding-top: 5px
    }

    .modal_add_user2 .modal-dialog .modal-content .modal-body .confirm_btn {
        max-width: 115px;
        height: 45px;
        font-size: 18px;
        line-height: 16px;
        margin-top: 25px
    }

    .update_name .modal-dialog .modal-content .modal-body .input_wrap input {
        height: 45px;
        font-size: 17px;
        line-height: 17px
    }

    .update_name .modal-dialog .modal-content .modal-body .confirm_btn {
        max-width: 115px;
        height: 45px;
        font-size: 17px;
        line-height: 17px;
        margin-top: 15px
    }

    .alert_danger,
    .alert_success {
        left: 6px !important;
        font-size: 16px
    }

    .modal_add_user .modal-dialog {
        min-width: 350px !important;
        margin-left: 12px !important;
        margin-right: 20px !important
    }

    .modal_view_user .modal-dialog .modal-content .modal-body .user_main {
        gap: 30px 0;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        max-width: 310px;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap
    }

    .modal_view_user .modal-dialog .modal-content .modal-body h2 {
        font-size: 26px;
        line-height: 26px
    }

    .modal_view_user .modal-dialog .modal-content .modal-header {
        padding-bottom: 10px
    }

    .modal_delete .modal-dialog {
        min-width: 340px !important
    }

    .modal_delete .modal-dialog .modal-content .modal-body h2 {
        font-size: 26px;
        line-height: 26px;
        padding-bottom: 10px
    }

    .modal_delete .modal-dialog .modal-content .modal-body p {
        font-size: 16px;
        line-height: 20px;
        max-width: 330px;
        padding-bottom: 20px
    }

    .modal_delete .modal-dialog .modal-content .modal-body .btn_wrap .btn {
        font-size: 17px;
        line-height: 17px;
        max-width: 115px;
        height: 40px
    }

    .nav_wrapper .nav_inner .nav_right .dropdown .dropdown-menu li .wallet_detail p {
        font-size: 18px;
        line-height: 20px
    }

    .nav_wrapper .nav_inner .nav_right .dropdown .notify_arrow {
        top: -16px;
        right: 123px
    }

    .nav_wrapper .nav_inner .nav_right .dropdown .dropdown-menu {
        width: 270px;
        left: 50% !important;
        -webkit-transform: translate(-50%, 0) !important;
        transform: translate(-50%, 0) !important;
        top: 35px !important
    }

    .modal_setting .modal-dialog,
    .update_name .modal-dialog {
        min-width: 330px !important
    }

    .nav_wrapper .nav_inner .nav_right .dropdown .dropdown-menu li .wallet_detail .wallet_btn {
        max-width: 110px;
        font-size: 14px;
        line-height: 14px;
        height: 35px
    }

    .modal_setting .modal-dialog .modal-content .modal-body h2 {
        font-size: 26px;
        line-height: 26px;
        padding-bottom: 15px
    }

    .modal_setting .modal-dialog .modal-content .modal-body .input_wrap .user_img {
        width: 16px;
        left: 8px
    }

    .modal_setting .modal-dialog .modal-content .modal-body .input_wrap label {
        font-size: 16px;
        line-height: 16px;
        left: 30px
    }

    .modal_setting .modal-dialog .modal-content .modal-body .input_wrap input {
        height: 50px
    }

    .nav_wrapper .nav_inner .nav_right .dropdown .dropdown-menu li .dropdown-item.header_sec {
        padding: 15px 0
    }

    .modal_setting .modal-dialog .modal-content .modal-body .input_wrap {
        max-width: 250px;
        margin: unset;
        margin-right: auto !important;
        margin-bottom: 10px;
        margin-left: auto
    }

    .modal_add_user .modal-dialog .modal-content .modal-body .input_wrap label,
    .modal_setting .modal-dialog .modal-content .modal-body .input_wrap .coming_soon p,
    .modal_view_user .modal-dialog .modal-content .modal-body .user_main .user_inner .user_name h5 {
        font-size: 16px;
        line-height: 16px
    }

    .modal_setting .modal-dialog .modal-content .modal-body .input_wrap .coming_soon {
        right: 0;
        left: 0;
        top: 70px
    }

    .modal_setting .modal-dialog .modal-content .modal-body {
        padding-bottom: 30px;
        padding-left: 20px
    }

    .update_name .modal-dialog .modal-content .modal-body h2 {
        font-size: 26px;
        line-height: 26px;
        margin-bottom: 15px
    }

    .nav_wrapper .nav_inner .nav_right .dropdown .dropdown-menu li .wallet_detail {
        padding-top: 10px;
        padding-bottom: 10px
    }

    .modal_delete .modal-dialog .modal-content .modal-body {
        padding-bottom: 25px
    }

    .modal_view_user .modal-dialog .modal-content .modal-body .user_main .user_inner h4 {
        font-size: 17px
    }

    .modal_view_user .modal-dialog .modal-content .modal-body .user_main .user_inner .user_name .img_wrap {
        width: 23px
    }

    .modal_view_user .modal-dialog .modal-content .modal-body .user_main .user_inner,
    .nav_wrapper .nav_inner .nav_right.login_btn,
    .sales_body .create_btn {
        max-width: 125px
    }

    .modal_add_user .modal-dialog .modal-content .modal-body h2 {
        font-size: 22px;
        line-height: 20px;
        padding-bottom: 15px
    }

    .modal_add_user .modal-dialog .modal-content .modal-header {
        -webkit-box-pack: end !important;
        -ms-flex-pack: end !important;
        justify-content: end !important
    }

    .modal_add_user .modal-dialog .modal-content .modal-body {
        padding-bottom: 40px
    }

    .hero_wrapper .hero_inner .hero_left .input_wrap {
        max-width: 320px;
        width: 100%
    }

    .nav_wrapper .nav_inner .nav_right .btn.create_btn {
        width: 110px;
        height: 30px
    }

    .nav_wrapper .nav_inner .nav_right .btn.sign_btn {
        width: 60px;
        height: 30px
    }

    .nav_wrapper .nav_inner .nav_left img {
        max-width: 150px
    }

    .nav_wrapper .nav_inner {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        gap: 10px;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        height: 100px
    }

    .nav_wrapper.index_nav .nav_inner {
        height: 126px
    }

    .nav_wrapper .nav_inner .nav_right .nav-tabs .nav-link {
        height: 30px;
        font-size: 15px
    }

    .nav_wrapper .nav_inner .nav_right .btn {
        font-size: 13px
    }

    .hero_wrapper .hero_inner .hero_left .hero_title {
        line-height: 28px;
        font-size: 30px
    }

    .hero_wrapper .hero_inner .hero_left .hero_detail {
        line-height: 16px;
        font-size: 16px
    }

    .hero_wrapper .hero_inner .hero_left .input_wrap input {
        font-size: 16px;
        line-height: 17px;
        padding-left: 10px;
        margin-bottom: 0
    }

    .hero_wrapper .hero_inner .hero_left .input_wrap .img_wrap {
        right: 10px;
        width: 25px
    }

    .hero_wrapper .hero_inner .hero_left .navtab_wrap .nav-tabs .nav-link,
    .sales_body .create_btn.learn_btn {
        height: 30px
    }

    .hero_wrapper .hero_inner .hero_left .create_btn {
        height: 40px;
        font-size: 16px;
        line-height: 16px;
        max-width: 320px;
        width: 100%;
        margin: 0 auto 10px
    }

    .hero_wrapper .hero_inner .hero_left .navtab_wrap .nav-tabs {
        margin: 0 auto
    }

    .hero_wrapper .hero_inner .hero_left .already_login {
        font-size: 18px;
        line-height: 24px
    }

    .hero_wrapper .hero_inner {
        padding-top: 20px;
        margin-bottom: 30px
    }

    .defi_img .container .person_img img,
    .defi_img .container img {
        max-width: 130px
    }

    .footer_wrapper .footer_inner {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        gap: 12px
    }

    .footer_wrapper .footer_inner ul li {
        font-size: 14px;
        line-height: 14px
    }

    .footer_wrapper .footer_inner .social_icons {
        width: 100%;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center
    }

    .nav_wrapper .nav_inner .nav_right .btn.logout_btn {
        width: 65px;
        height: 30px
    }

    .nav_wrapper .nav_inner .nav_right .right_inner {
        width: auto
    }

    .nav_wrapper .nav_inner .nav_right .right_inner .nav_icons .img_wrap img {
        max-width: 25px
    }

    .nav_wrapper .nav_inner .nav_right .right_inner .nav_icons {
        gap: 5px;
        margin-right: 10px
    }

    .bg_body.bg_multipleAccount,
    .sales_body .hero_wrap,
    .sales_body .sale_detail.why_wrap .why_us {
        padding-top: 10px
    }

    .hero_wrapper .multiple_inner {
        padding: 10px 10px 50px
    }

    .hero_wrapper .multiple_inner .your_account p {
        font-size: 16px;
        line-height: 28px;
        margin-bottom: 10px
    }

    .hero_wrapper .multiple_inner .your_account .input_wrap input {
        font-size: 18px;
        line-height: 28px;
        padding-left: 35px
    }

    .hero_wrapper .multiple_inner .your_account .input_wrap .img_wrap {
        left: 10px;
        width: 20px
    }

    .hero_wrapper .multiple_inner .account_main {
        padding-bottom: 30px;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center
    }

    .hero_wrapper .multiple_inner .account_main .account_inner h3 {
        font-size: 20px;
        line-height: 18px;
        margin-bottom: 15px
    }

    .hero_wrapper .multiple_inner .account_main .account_inner,
    .sales_body .client_says .client_box .box_detail {
        padding-bottom: 15px
    }

    .hero_wrapper .multiple_inner .account_main .account_inner .input_wrap label {
        left: 15px;
        font-size: 18px;
        line-height: 18px
    }

    .hero_wrapper .multiple_inner .account_main .account_inner .input_wrap .img_wrap {
        right: 15px;
        width: 4px
    }

    .defi_img .container .person_img {
        right: 10px
    }

    .contractor_wrap .contractor_inner .contractor_content .contractor_title {
        font-size: 25px;
        line-height: 20px;
        margin-bottom: 15px
    }

    .contractor_wrap .contractor_inner .contractor_content .contractor_title a {
        font-weight: 400;
        font-size: 18px;
        line-height: 18px;
        padding-left: 5px
    }

    .contractor_wrap .contractor_inner {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        padding: 15px 0
    }

    .contractor_wrap .contractor_wallet .nav-tabs .nav-item {
        max-width: 160px;
        height: 50px
    }

    .contractor_wrap .contractor_wallet .nav-tabs {
        gap: 15px
    }

    .contractor_wrap .contractor_wallet .tab-content .add_btn {
        max-width: 100px;
        height: 40px;
        font-size: 21px;
        margin-bottom: 20px
    }

    .contractor_wrap .contractor_wallet .tab-content .table_contractor_account.table_contractor_account2 table td h6 {
        font-size: 18px;
        line-height: 18px;
        margin-bottom: 3px
    }

    .contractor_wrap .table_wrap tr {
        height: 60px
    }

    .contractor_wrap .contractor_wallet .tab-content .table_contractor_account table,
    .contractor_wrap .contractor_wallet .tab-content .table_contractor_account.table_contractor_account2 table {
        margin-left: 5px
    }

    .contractor_wrap .contractor_wallet .tab-content .table_contractor_account table tr.border_bottom::after {
        width: 100%;
        left: 0
    }

    .payment_report .payment_tbl table td {
        padding: 10px 3px;
        vertical-align: middle
    }

    .payment_report .payment_content .report_arrow .payment_arrow {
        max-width: 65px
    }

    .payment_report .payment_content .payment_value p {
        font-size: 22px;
        line-height: 28px
    }

    .payment_report .payment_content .report_arrow {
        margin: 20px 0
    }

    .payment_report .payment_tbl table .border_bottom::after {
        width: 100%;
        left: 0;
        bottom: 8px
    }

    .payment_report .table_wrap th {
        font-size: 22px;
        line-height: 26px
    }

    .payment_report .payment_tbl table {
        margin: 0 5px
    }

    .chart_content .dropdown .btn {
        max-width: 300px;
        font-size: 22px;
        line-height: 21px
    }

    .graph_chat {
        padding: 10px;
        margin-bottom: 40px
    }

    .sales_body .hero_wrap .hero_inner {
        padding-top: 30px
    }

    .sales_body .hero_wrap .hero_title::after,
    .sales_body .hero_wrap .hero_title::before {
        width: 75px
    }

    .sales_body .hero_wrap .hero_title {
        max-width: 290px
    }

    .sales_body .hero_wrap .hero_detail h1 {
        font-size: 25px;
        line-height: 40px
    }

    .sales_body .crypto_wrap {
        gap: 15px;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-transform: translate(0, 20px);
        transform: translate(0, 20px)
    }

    .sales_body .sale_detail .sale_title {
        font-size: 22px;
        line-height: 25px
    }

    .sales_body .sale_detail .why_us .crypto_btn {
        max-width: 270px;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: start;
        margin: 0 auto
    }

    .sales_body .sale_detail .why_us .crypto_img {
        max-width: 50px;
        margin-right: 5px
    }

    .sales_body .sale_detail .sale_img {
        margin-right: auto
    }

    .sales_body .sale_detail.why_wrap {
        padding-bottom: 50px
    }

    .sales_body .sale_detail .account_procedure .num {
        width: 40px;
        height: 40px;
        font-size: 18px;
        line-height: 16px
    }

    .sales_body .sale_detail .account_procedure p {
        font-size: 15px;
        line-height: 16px
    }

    .sales_body .sale_detail .account_procedure {
        padding-top: 30px;
        gap: 15px;
        padding-bottom: 20px
    }

    .sales_body .client_says {
        padding-top: 0
    }

    .sales_body .client_says .client_inner {
        padding-top: 20px;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap
    }

    .sales_body .client_says .left_img,
    .sales_body .client_says .right_img {
        display: none
    }

    .sales_body .client_says .client_box .quote_img {
        max-width: 50px;
        margin-bottom: 10px
    }

    .sales_body .client_says .client_box {
        max-width: 270px
    }

    .sales_body .contact_box h2,
    .sales_body .live_wrap .live_chat span {
        font-size: 24px;
        line-height: 24px
    }
}

.pageloader,
.pageloader-mainpage {
    top: calc(50% - 4em);
    left: calc(50% - 4em);
    width: 6em;
    height: 6em;
    animation: 1.1s linear infinite load8;
    transition: opacity .3s
}

.modal .fade .addusermodel .show,
.modal.faded.show {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1060;
    width: 100%;
    height: 100%;
    outline: 0
}

span.red-error {
    color: #fff;
    margin-right: 84px
}

span.red-error.contact {
    color: red
}

.custom-margin-left-zero {
    margin-left: 0 !important
}

.custom-cursor-none {
    cursor: context-menu !important
}

.modal.faded.show {
    overflow-y: auto;
    padding-right: 17px;
    display: block
}

.modal .fade .addusermodel .show {
    display: none;
    overflow: hidden
}

span.red-error.update-email {
    float: left
}

span.pagination-text {
    padding: 10px;
    color: #fff
}

.custom-pagi-class {
    float: right;
    margin-top: -50px !important
}

.modal.fade.show.deletuser,
.modal.fade.show.sendemail {
    display: block
}

.cstm-css-for-rotate {
    font-weight: 500;
    font-size: 20px;
    line-height: 20px;
    text-transform: uppercase;
    color: #000;
    vertical-align: middle;
    min-height: 10px;
    display: flex;
    justify-content: center;
    transform-origin: 0 0
}

.notification {
    color: #fff !important;
    position: relative;
    display: inline-block;
    border-radius: 2px
}

.notification .badge {
    position: absolute;
    top: -10px;
    right: -10px;
    padding: 5px 10px;
    border-radius: 50%;
    background-color: red;
    color: #fff
}

span.red-error.chnagPass {
    color: #fff;
    float: left;
    margin-bottom: 9px
}

.pageloader-mainpage {
    position: fixed;
    border-radius: 50%
}

.cstm-height-mm {
    max-height: 90px !important
}

.inner-container ::-webkit-scrollbar {
    width: 0 !important;
    display: none
}

option {
    text-align: initial
}

.cstkanda {
    width: 8% !important;
    text-align: center;
    padding: 10px 13px !important;
    background-color: #fff !important;
    color: #000 !important;
    border-radius: 10px !important;
    border: none
}

.defi_manage_crypto {
    background-color: #5f6265
}